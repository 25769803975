import React, { useEffect, useState } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import PlacesAutocomplete, {
  Suggestion,
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useAppSelector } from "app/hooks";
import { selectShowHeader } from "features/dossierSimuSlice";
import { useIsSmallScreen } from "hooks/isSmallScreen";

type Address = {
  address: string;
  latitude: number;
  longitude: number;
};

type Props = {
  onAddressSelect: (selectedAddressInfo: any) => void;
  address: Address;
  setAddress: (address: Address) => void;
  isTabOrPhone?: boolean;
  showRisk: boolean;
};

const MapContainer: React.FC<Props> = ({
  onAddressSelect,
  address,
  setAddress,
  isTabOrPhone = false,
  showRisk
}) => {
  const [lat, setLat] = useState<number | null>(
    address ? address?.latitude : 44.837789
  );
  const [lng, setLng] = useState<number | null>(
    address ? address?.longitude : -0.57918
  );

  // const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
  const showHeader = useAppSelector(selectShowHeader);
  const isSmallScreen = useIsSmallScreen(993);

  const mapContainerStyle = {
    width: !isSmallScreen ? (showRisk ? "45vw" : "30vw") : "60vw",
    height: !isSmallScreen ? (showRisk ? "35vh" : "60vh") : "35vh",
    borderRadius: "15px"
  };

  const inputStyle = {
    position: "absolute",
    top: "10px",
    left: "50%",
    transform: "translateX(-50%)",
    border: "1px solid #86A1A3",
    width: !isSmallScreen ? (showRisk ? "36vw" : "26vw") : (showRisk ? "40vw" : "46vw"),
    height: "40px",
    padding: "0 12px",
    borderRadius: "3px",
    fontSize: "14px",
    outline: "none",
    textOverflow: "ellipsis",
  };

  const center = {
    lat: lat || 44.837789,
    lng: lng || -0.57918,
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GMAPS_API_KEY || "",
    libraries: ["places"],
  });



  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const {
            coords: { latitude, longitude },
          } = position;

          const currentAddressInfo = {
            address: null,
            latitude: latitude,
            longitude: longitude,
          };

          setLat(latitude);
          setLng(longitude);

          onAddressSelect(currentAddressInfo);
        },
        (error) => {
          console.error(
            "Geolocation n'est pas supporté par le navigateur.",
            error
          );
        }
      );
    } else {
      console.error("Geolocation n'est pas supporté par le navigateur.");
    }
  }, []);

  const handleSelect = async (selectedAddress: any) => {
    setAddress({ ...address, address: selectedAddress });
    const results = await geocodeByAddress(selectedAddress);
    const latLng = await getLatLng(results[0]);

    setLat(latLng.lat);
    setLng(latLng.lng);

    const selectedAddressInfo = {
      address: selectedAddress,
      latitude: latLng.lat,
      longitude: latLng.lng,
      codePostal: results[0].address_components[6].long_name
    };

    onAddressSelect(selectedAddressInfo);
  };

  if (loadError) {
    return (
      <div
        style={{ width: "48vw", height: "30vw", textAlign: "center" }}
        className="d-flex center flex-center"
      >
        <p className="font-20 text-dark">
          Erreur lors du chargement de la carte
        </p>
      </div>
    );
  }

  if (!isLoaded)
    return (
      <div
        style={{ width: "48vw", height: "30vw", textAlign: "center" }}
        className="d-flex center flex-center"
      >
        <p className="font-20 text-dark">Chargement...</p>
      </div>
    );

  return (
    <div
      className={`d-flex ${isTabOrPhone ? "flex-center" : "flex-hor-end"
        } w-100`}
    >
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={18}
        center={center}
      >
        <Marker position={center} />
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 999,
          }}
        >
           <PlacesAutocomplete
            value={address?.address}
            onChange={(newAddress: string) =>
              setAddress({ ...address, address: newAddress })
            }
            onSelect={handleSelect}
            searchOptions={{
              types: ["geocode"],
              componentRestrictions: {
                country: "fr",
              },
            }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }: any) => (
              <div>
                <input
                  {...getInputProps({ placeholder: "Entrez une adresse" })}
                  style={inputStyle}
                />
                <div
                  style={{
                    width: !isSmallScreen ? (showRisk ? "38vw" : "29vw") : (showRisk ? "42vw" : "49vw"),
                    marginTop: "50px",
                    maxHeight: "200px",
                    overflowY: "auto",
                    padding: "0 12px",
                  }}
                >
                  {loading ? <div>Chargement...</div> : null}
                  {suggestions.map((suggestion: Suggestion) => (
                    <div
                      {...getSuggestionItemProps(suggestion)}
                      className="pad-8 text-dark-light pad-b5"
                      style={{
                        backgroundColor: suggestion.active ? "#f0f4f4" : "#fff",
                        padding: "8px",
                        cursor: "pointer",
                        borderBottom: "1px solid #f0f4f4",
                      }}
                    >
                      <div className="d-flex text-hor-between w-100">
                        {/* <img
                          src={MarkerIcon}
                          alt="icone gps"
                          className="pad-r5"
                        /> */}
                        <p className="font-16">{suggestion.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>
      </GoogleMap>
    </div>
  );
};

export default MapContainer;
