import profile from "../../../assets/image/stepTwo/profile.svg";
import { ReactComponent as PrevArrow } from "assets/image/startStep/prevArrow.svg";
import { useState, useEffect } from "react";
import { ContantsPrime } from "pages/mainStep/type";
import SelectComponent from "components/Select";

const ContratEdit = ({ close, data, onChangeData }: any) => {
  const [valueFranchise, setValueFranchise] = useState(0);
  const [valuePlafond, setValuePlafond] = useState(0);
  const [minFranchise, setMinFranchise] = useState(0);
  const [maxFranchise, setMaxFranchise] = useState(0);
  const [minPlafond, setMinPlafond] = useState(0);
  const [maxPlafond, setMaxPlafond] = useState(0);
  const [dataEdit, setDataEdit] = useState<ContantsPrime>();

  const handleSetValueFranchise = (valueFranchise: number) => {
    setValueFranchise(valueFranchise);
    setDataEdit({...dataEdit,
      montantPrimeAssurance: data?.data?.montantPrimeAssurance,
      montantPrimeAssuranceAnnuelle: data?.data?.montantPrimeAssuranceAnnuelle,
      nombreMoisFranchise: valueFranchise,
    });

    const input = document.querySelector('input[type="range"]') as HTMLInputElement;
    input.style.backgroundSize = Math.ceil(((valueFranchise - minFranchise) * 100) / (maxFranchise - minFranchise)) + "% 100%";
  };

  const handleSetValuePlafond = (valuePlafond: number) => {
    setValuePlafond(valuePlafond);
    
    setDataEdit({...dataEdit,
      montantPrimeAssurance: data?.data?.montantPrimeAssurance,
      montantPrimeAssuranceAnnuelle: data?.data?.montantPrimeAssuranceAnnuelle,
      plafondActiver: valuePlafond,
    });
    
  };

  useEffect(() => {
    if (data) {
      const minFranchise = 0;
      const maxFranchise = 0;
      const valueFranchise = data?.data?.nombreMoisFranchise || 0;

      const minPlafond = 0;
      const maxPlafond = 0;
      const valuePlafond = data?.data?.plafondActiver || 0;

      setValueFranchise(valueFranchise);
      setMinFranchise(data?.data?.franchiseMin);
      setMaxFranchise(data?.data?.franchiseMax);

      setValuePlafond(valuePlafond);
      setMinPlafond(data?.data?.plafondMin);
      setMaxPlafond(data?.data?.plafondMax);

      setDataEdit({
        montantPrimeAssurance: data?.data?.montantPrimeAssurance,
        montantPrimeAssuranceAnnuelle: data?.data?.montantPrimeAssuranceAnnuelle,
        nombreMoisFranchise: data?.data?.nombreMoisFranchise,
        plafondActiver: data?.data?.plafondActiver
      });

      const input = document.querySelector(
        'input[type="range"]'
      ) as HTMLInputElement;

      input.style.backgroundSize = Math.ceil(((valueFranchise - minFranchise) * 100) / (maxFranchise - minFranchise)) + "% 100%";
    }
  }, [data]);

  const handleChangeValueFranchise = (direction: string) => {
    let newValueFranchise: any;
    const oldValueFranchise = Number(valueFranchise);

    if (direction === "up") {
      if (oldValueFranchise <= maxFranchise) {
        newValueFranchise = oldValueFranchise + 1;
      }
    } else {
      if (oldValueFranchise >= minFranchise) {
        newValueFranchise = oldValueFranchise - 1;
      }
    }
    if (newValueFranchise < minFranchise) {
      handleSetValueFranchise(minFranchise);
    } else if (newValueFranchise > maxFranchise) {
      handleSetValueFranchise(maxFranchise);
    } else {
      handleSetValueFranchise(newValueFranchise);
    }
  };

  const handleSubmit = () => {
    const newData = {
      ...data,
      data: dataEdit,
    };

    onChangeData(newData);
    close();
  };

  return (
    <div id="contratEdit">
      <PrevArrow className="cursor prevArrow" onClick={close}></PrevArrow>
      <img src={profile} alt="loading" className="csf-image"></img>
      <h2 className="font-16 lh-19 mg-b18 main-color csf-title">
        Votre franchise
      </h2>
      <p className="font-24 lh-19 mg-b26 csf-valueInput">
        {valueFranchise} mois <span className="font-16 lh-19">/ franchise</span>
      </p>

      <div className="csf-inputRange">
        <div
          className="actionInputRange"
          onClick={() => handleChangeValueFranchise("down")}
        >
          <span>-</span>
        </div>
        <input
          type="range"
          min={minFranchise}
          max={maxFranchise}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleSetValueFranchise(Number(e.target.value));
          }}
          value={valueFranchise}
        />
        <div
          className="actionInputRange"
          onClick={() => handleChangeValueFranchise("up")}
        >
          <span>+</span>
        </div>
      </div>

      {(minPlafond > 0 && maxPlafond > 0) && (
        <h2 className="font-16 lh-19 mg-b18 main-color csf-title">
          Votre plafond
        </h2>
      )}
      
      {(minPlafond > 0 && maxPlafond > 0) && (
        <SelectComponent 
          onChange={(e: string) => {
            setValuePlafond(Number.parseInt(e));
            handleSetValuePlafond(Number.parseInt(e));
          }}

          options={[{label: minPlafond, value: minPlafond}, {label: maxPlafond, value: maxPlafond}]}
          placeholder="Sélectionner un plafond"
          value={valuePlafond}
        ></SelectComponent>
      )}

      {(minPlafond > 0 && maxPlafond > 0) && (<br />)}

      <div className="w-100 d-flex buttonGroup">
        <button className="submitButton" onClick={close}>
          Annuler
        </button>
        <button className="submitButton" onClick={handleSubmit}>
          Valider
        </button>
      </div>
    </div>
  );
};

export default ContratEdit;
