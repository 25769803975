import React from "react";
import info from "assets/image/common/big-warning-circle.svg";

type Props = {
  text: string;
};

const InfoBlock: React.FC<Props> = ({ text }) => {
  return (
    <div className="infoblock text-center d-flex flex-hor-center mg-t30 ">
      <div className="container">
          <img src={info} alt="info" className="cccinfo-block" />
        <p className="cccinfo-block">{text}</p>
      </div>
    </div>
  );
};

export default InfoBlock;
