export const MONBIEN1 = "/gli/esr";
export const MONBIEN2 = "/gli/defbien";

export const LOCATAIRESSTEP = "/gli/step2";
export const LOCATAIRESSTEP_PAGE1_NAME = "1";
export const LOCATAIRESSTEP_PAGE2_NAME = "2";
export const LOCATAIRESSTEP_PAGE3_NAME = "3";
export const LOCATAIRESSTEP_PAGE4_NAME = "4";
export const LOCATAIRESSTEP_PAGE3_STEP1_NAME = "1";
export const LOCATAIRESSTEP_PAGE3_STEP2_NAME = "2";
export const LOCATAIRESSTEP_PAGE3_STEP3_NAME = "3";
export const LOCATAIRESSTEP_PAGE3_STEP4_NAME = "4";
export const LOCATAIRESSTEP_PAGE3_STEP5_NAME = "5";
export const LOCATAIRESSTEP_PAGE3_STEP6_NAME = "6";
export const LOCATAIRES1 = `${LOCATAIRESSTEP}/${LOCATAIRESSTEP_PAGE1_NAME}`;
export const LOCATAIRES2 = `${LOCATAIRESSTEP}/${LOCATAIRESSTEP_PAGE2_NAME}`;
export const LOCATAIRES3 = `${LOCATAIRESSTEP}/${LOCATAIRESSTEP_PAGE3_NAME}`;
export const LOCATAIRES4 = `${LOCATAIRESSTEP}/${LOCATAIRESSTEP_PAGE4_NAME}`;
export const LOCATAIRES3STEP1 = `${LOCATAIRES3}/${LOCATAIRESSTEP_PAGE3_STEP1_NAME}`;
export const LOCATAIRES3STEP2 = `${LOCATAIRES3}/${LOCATAIRESSTEP_PAGE3_STEP2_NAME}`;
export const LOCATAIRES3STEP3 = `${LOCATAIRES3}/${LOCATAIRESSTEP_PAGE3_STEP3_NAME}`;
export const LOCATAIRES3STEP4 = `${LOCATAIRES3}/${LOCATAIRESSTEP_PAGE3_STEP4_NAME}`;
export const LOCATAIRES3STEP5 = `${LOCATAIRES3}/${LOCATAIRESSTEP_PAGE3_STEP5_NAME}`;
export const LOCATAIRES3STEP6 = `${LOCATAIRES3}/${LOCATAIRESSTEP_PAGE3_STEP6_NAME}`;

export const CONNEXION = "/gli/connexion";
export const CONNEXION_LOGIN_NAME = "login";
export const CONNEXION_INFO_NAME = "info";
export const CONNEXION_LOGIN = `${CONNEXION}/${CONNEXION_LOGIN_NAME}`;
export const CONNEXION_INFO = `${CONNEXION}/${CONNEXION_INFO_NAME}`;

export const CONFIGASSUSTEP = "/gli/step3";
export const CONFIGASSUSTEP_PAGE1_NAME = "1";
export const CONFIGASSUSTEP_PAGE2_NAME = "2";
export const CONFIGASSU1 = `${CONFIGASSUSTEP}/${CONFIGASSUSTEP_PAGE1_NAME}`;
export const CONFIGASSU2 = `${CONFIGASSUSTEP}/${CONFIGASSUSTEP_PAGE2_NAME}`;

export const UPDOC = "/gli/step4";

export const CONFIRMATIONSTEP = "/gli/step5";
export const CONFIRMATIONSTEP_PAGE1_NAME = "1";
export const CONFIRMATIONSTEP_PAGE2_NAME = "2";
export const CONFIRMATIONSTEP_PAGE3_NAME = "3";
export const CONFIRMATION1 = `${CONFIRMATIONSTEP}/${CONFIRMATIONSTEP_PAGE1_NAME}`;
export const CONFIRMATION2 = `${CONFIRMATIONSTEP}/${CONFIRMATIONSTEP_PAGE2_NAME}`;
export const CONFIRMATION3 = `${CONFIRMATIONSTEP}/${CONFIRMATIONSTEP_PAGE3_NAME}`;

export const MONBIEN_LABEL = "Mon bien";
export const LOCATAIRES_LABEL = "Locataires";
export const CONNEXION_LABEL = "Connexion";
export const CONFIGASSU_LABEL = "Garanties";
export const UPDOC_LABEL = "Pieces";
export const CONFIRMATION_LABEL = "Confirmation";

export const routeList = [
  {
    value: 0,
    label: MONBIEN_LABEL,
    list: [MONBIEN1, MONBIEN2],
  },
  {
    value: 1,
    label: LOCATAIRES_LABEL,
    code: "DOSSLOC",
    list: [
      LOCATAIRES1,
      LOCATAIRES2,
      LOCATAIRES3STEP1,
      LOCATAIRES3STEP2,
      LOCATAIRES3STEP3,
      LOCATAIRES3STEP4,
      LOCATAIRES3STEP5,
      LOCATAIRES3STEP6,
      LOCATAIRES4,
    ],
  },
  {
    value: 2,
    label: CONNEXION_LABEL,
    code: "CONNEXION",
    list: [CONNEXION_INFO, CONNEXION_LOGIN],
  },
  {
    value: 3,
    label: CONFIGASSU_LABEL,
    code: "CONFIGASSU",
    list: [
      CONFIGASSU1
    ],
  },
  {
    value: 4,
    label: UPDOC_LABEL,
    code: "UPDOC",
    list: [UPDOC],
  },
  {
    value: 5,
    label: CONFIRMATION_LABEL,
    code: "CONFIRM",
    list: [CONFIRMATION1, CONFIRMATION2, CONFIRMATION3],
  },
];
