// A mock function to mimic making an async request for data
import { API_SERVER } from "constants/configs";
import publicAPIInstance from "pages/connexion/publicProxy";
// A mock function to mimic making an async request for data
export function fetchDocuments(data: any) {
  return new Promise((resolve, reject) => {
    try {
      const res = publicAPIInstance.get(
        `api/simulations/gli/${data?.simulationId}/configurer/documents`,
        { params: data?.params }
      );
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export function getDetailLocataires(data: {
  simulationId: string;
  locataireId: string;
}) {
  return new Promise((resolve, reject) => {
    try {
      const res: any = publicAPIInstance.get(
        `api/simulations/gli/${data?.simulationId}/locataires/${data?.locataireId}`
      );
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export function uploadDocument(data: {
  params: { simulationId: string; documentId: string };
  dataForm: any;
}) {
  return new Promise((resolve, reject) => {
    try {
      const res: any = publicAPIInstance.post(
        `api/contrat-configurator/${data?.params?.simulationId}/configurer/documents?documentId=${data?.params?.documentId}`,
        data?.dataForm
      );
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}
