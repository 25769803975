import React from "react";

type ContractTabs = {
  icon?: any;
  label: string;
  numbers?: number;
  value: number;
  haveNumbers: boolean;
};

type Props = {
  tabs: ContractTabs[];
  active: number;
  setActive: (value: number) => void;
  tiny?: boolean;
  children?: React.ReactNode;
  customStyle?:string
};

const Tabs: React.FC<Props> = ({ tabs, active, setActive, tiny, children, customStyle }) => {
  return (
    <ul className={`d-flex block-menu ${customStyle}`}>
      <div
        className="w-100"
        style={!tiny ? { display: "flex" } : { display: "flex" }}
      >
        {tabs?.map((x: ContractTabs, index: number) => (
          <li
            key={index}
            className={`cursor font-14 text-dark-light ${tiny ? "tiny" : ""} ${
              active === x?.value ? "active" : ""
            }`}
            onClick={() => setActive(x?.value)}
          >
            <div className="tabs">
              {x?.icon}
              <span
                className={`font-14 font-w700 text-dark mg-l10 ${
                  tiny ? "mg-r10" : ""
                }`}
              >
                {x?.label}
              </span>
            </div>
            {x?.haveNumbers ? (
              <div className="badge transparent">
                <p className="font-15 font-w700 text-elements-tab">
                  {x?.numbers}
                </p>
              </div>
            ) : null}
          </li>
        ))}

        {children ? (
          <div className="w-100 d-flex flex-hor-end">{children}</div>
        ) : null}
      </div>
    </ul>
  );
};

export default Tabs;
