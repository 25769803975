import edit from "../../../assets/image/infoStepFour/edit.svg";
import arrow from "../../../assets/image/startStep/arrow.svg";
import { Form } from "antd";

import { useEffect, useState, useMemo } from "react";
import SelectComponent from "components/Select";
import {
  getSimulationData,
  setFamilyAction,
  setRenderStepTwoPageFour,
} from "pages/mainStep/mainSlice";
import { addLocatairesAction, editLocatairesAction } from "../locatairesSlice";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import trash from "../../../assets/image/infoStepFour/remove.svg";
import {
  ListFamily,
  Situation,
  TypeList,
  Civilites,
} from "pages/mainStep/type";
import { ReactComponent as Trash } from "assets/image/common/trash-circle.svg";

interface FormType {
  email: string;
  name: string;
  phone: string;
  subName: string;
}

const ItemFour = ({
  handleNextFamily,
  setShow,
  setValueList,
  valueList,
}: any) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const { listFamily, indexFamily, simulationId, dossierLocationId, simulation } =
    useAppSelector((state: any) => state?.mainSlice);

  const { situationsList, civilites, typesList } = useAppSelector(
    (state: any) => state?.locatairesSlice
  );

  const [showIdentite, setShowIdentite] = useState(true);
  const [showSituation, setShowSituation] = useState(true);
  const [showSituationDuGarant, setShowSituationDuGarant] = useState(true);
  const [gender, setGender] = useState<Civilites>();
  const [situationValue, setSituationValue] = useState<Situation>();
  const [situationChildrenValue, setSituationChildrenValue] =
    useState<Situation | null>();
  const [situationChildrenList, setSituationChildrenList] = useState<
    Situation[]
  >([]);

  const [situationGarantValue, setSituationGarantValue] = useState<Situation>();
  const [situationChildrenGarantValue, setSituationChildrenGarantValue] =
    useState<Situation | null>();
  const [situationChildrenGarantList, setSituationChildrenGarantList] =
    useState<Situation[]>([]);

  const [dataStep3, setDataStep3] = useState<any>();
  const [dataStep3Garant, setDataStep3Garant] = useState<any>();
  const [kindValueList, setKindValueList]: any = useState();

  useEffect(() => {
    if (kindValueList) {
      if (kindValueList === 1) {
        setDataStep3(valueList);
      } else {
        setDataStep3Garant(valueList);
      }
    }
  }, [valueList]);

  useEffect(() => {
    dispatch(setRenderStepTwoPageFour(false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSimulationData(simulationId));
  }, [dispatch, simulationId]);

  useEffect(() => {
    const data = listFamily[indexFamily];
    form.setFieldsValue(data?.step1);
    setGender(data?.step1?.civilite);

    setSituationValue(data?.step2?.parent);
    if (data?.step2?.children?.id) {
      setSituationChildrenValue(data?.step2?.children);
    }
    if (data?.step2?.parent?.enfants?.length > 0) {
      setSituationChildrenList(
        data?.step2?.parent?.enfants?.map((x: Situation) => ({
          ...x,
          label: x?.displayName,
          value: x?.id,
        }))
      );
    }

    setSituationGarantValue(data?.situationGarant?.parent);
    if (data?.situationGarant?.children?.id) {
      setSituationChildrenGarantValue(data?.situationGarant?.children);
    }
    if (data?.situationGarant?.parent?.enfants?.length > 0) {
      setSituationChildrenGarantList(
        data?.situationGarant?.parent?.enfants?.map((x: Situation) => ({
          ...x,
          label: x?.displayName,
          value: x?.id,
        }))
      );
    }

    if (data?.step3?.length > 0) {
      setDataStep3(data?.step3);
    }
    if (data?.step3Garant?.length > 0) {
      setDataStep3Garant(data?.step3Garant);
    }
  }, [form, indexFamily, listFamily, situationsList]);

  const handleSubmit = () => {
    const data = listFamily[indexFamily];
    const isGarant =
      listFamily?.[indexFamily]?.step2?.children?.peutEtreAttribueGarant ||
      listFamily?.[indexFamily]?.step2?.parent?.peutEtreAttribueGarant;

    const dataSubmit: any = {
      dossierLocationId,
      situationProfessionnelleId: data?.step2?.parent?.id,
      risqueAssureId: simulation.bienImmobilierId,
      ressources: data?.step3?.map((x: Situation) => ({
        natureRessourceId: x?.id,
        montant: Number(x?.value),
      })),
    };

    if (isGarant) {
      dataSubmit.garant = {
        civiliteId: data.step1.civilite?.id,
        nom: data.step1.name,
        prenom: data.step1.subName,
        dateNaissance: new Date().toISOString(),
        telephoneMobile: data.step1.phone,
        adresseEmail: data.step1.email,
        situationProfessionnelleId: data?.situationGarant?.parent?.id,
        ressources: data?.step3Garant?.map((x: Situation) => ({
          natureRessourceId: x?.id,
          montant: Number(x?.value),
        })),
      };
    }
    if (!data?.id) {
      dataSubmit.personnePhysiqueDto = {
        civiliteId: data.step1.civilite?.id,
        dateNaissance: new Date().toISOString(),
        nom: data.step1.name,
        prenom: data.step1.subName,
        adresse: {
          ligne1: simulation.bienImmobilier?.adresse?.ligne1,
          ligne2: simulation.bienImmobilier?.adresse?.ligne2,
          ligne3: simulation.bienImmobilier?.adresse?.ligne3,
          codePostal: simulation.bienImmobilier?.adresse?.codePostal,
          ville: simulation.bienImmobilier?.adresse?.ville,
          pays: simulation.bienImmobilier?.adresse?.pays,
          adresseFormatee: simulation.bienImmobilier?.adresse?.adresseFormatee,
          latitude: simulation.bienImmobilier?.adresse?.geolocalisation?.latitude,
          longitude: simulation.bienImmobilier?.adresse?.geolocalisation?.longitude,
          googlePlaceId: simulation.bienImmobilier?.adresse?.geolocalisation?.googlePlaceId
        },
        telephone: data.step1.phone,
        email: data.step1.email
      };

      dispatch(
        addLocatairesAction({ id: simulationId, data: dataSubmit })
      ).then((data) => {
        const newListFamily = listFamily?.map((x: ListFamily) => {
          if (x?.value === indexFamily) {
            return {
              ...x,
              allDone: true,
              id: data?.payload?.id,
              isEligible: data?.payload?.isEligible,
            };
          } else {
            return x;
          }
        });
        dispatch(setFamilyAction(newListFamily));
        handleNextFamily();
      });
    } else {
      dataSubmit.personnePhysique = {
        civiliteId: data.step1.civilite?.id,
        dateNaissance: new Date().toISOString(),
        nom: data.step1.name,
        prenom: data.step1.subName,
        adresse: {
          ligne1: simulation.bienImmobilier?.adresse?.ligne1,
          ligne2: simulation.bienImmobilier?.adresse?.ligne2,
          ligne3: simulation.bienImmobilier?.adresse?.ligne3,
          codePostal: simulation.bienImmobilier?.adresse?.codePostal,
          ville: simulation.bienImmobilier?.adresse?.ville,
          pays: simulation.bienImmobilier?.adresse?.pays,
          adresseFormatee: simulation.bienImmobilier?.adresse?.adresseFormatee,
          latitude: simulation.bienImmobilier?.adresse?.geolocalisation?.latitude,
          longitude: simulation.bienImmobilier?.adresse?.geolocalisation?.longitude,
          googlePlaceId: simulation.bienImmobilier?.adresse?.geolocalisation?.googlePlaceId
        },
        telephone: data.step1.phone,
        email: data.step1.email
      };

      let situation;
      if (data?.step2?.parent) {
        const parent = situationsList?.find(
          (x: Situation) => x?.id === data?.step2?.parent?.id
        );
        situation = parent;
        if (data?.step2?.children) {
          const children = situation?.enfants?.find(
            (x: Situation) => x?.id === data?.step2?.children?.id
          );
          situation = children;
        }
      }
      dataSubmit.locataireId = data?.id;

      dispatch(
        editLocatairesAction({
          id: simulationId,
          data: dataSubmit,
        })
      ).then((dataRes) => {
        handleNextFamily();
        const newListFamily = listFamily?.map((x: ListFamily) => {
          if (x?.value === indexFamily) {
            return {
              ...x,
              allDone: true,
              id: data?.id,
              isEligible: dataRes?.payload?.isEligible,
            };
          } else {
            return x;
          }
        });
        dispatch(setFamilyAction(newListFamily));
      });
    }
  };

  const handleChangeData = (form: FormType) => {
    const newListFamily = listFamily?.map((x: ListFamily) => {
      if (x?.value === indexFamily) {
        return {
          ...x,
          step1: {
            name: form?.name,
            subName: form?.subName,
            phone: form?.phone,
            email: form?.email,
            civilite: gender,
          },
        };
      } else {
        return x;
      }
    });
    dispatch(setFamilyAction(newListFamily));
    setShowIdentite(true);
  };

  const handleChangeSituation = () => {
    const newListFamily = listFamily?.map((x: any) => {
      if (x?.value === indexFamily) {
        if (
          situationChildrenValue?.peutEtreAttribueGarant ||
          situationValue?.peutEtreAttribueGarant
        ) {
          return {
            ...x,
            step2: {
              children: situationChildrenValue,
              parent: situationValue,
            },
            step3: dataStep3,
            step3Garant: [
              {
                code: "Salaire",
                displayName: "Salaire",
                id: "feb10c3b-62ef-4d7c-acda-8eaea93ef8e8",
                value: 0,
              },
            ],
          };
        } else {
          return {
            ...x,
            step2: {
              children: situationChildrenValue,
              parent: situationValue,
            },
            step3: dataStep3,
          };
        }
      } else {
        return x;
      }
    });
    if (situationValue?.code !== "Etudiant") {
      setValueList([]);
    }
    dispatch(setFamilyAction(newListFamily));
    setShowSituation(true);
  };

  const handleChangeSituationDuGarant = () => {
    const newListFamily = listFamily?.map((x: any) => {
      if (x?.value === indexFamily) {
        return {
          ...x,
          situationGarant: {
            children: situationChildrenGarantValue,
            parent: situationGarantValue,
          },
          step3Garant: dataStep3Garant,
        };
      } else {
        return x;
      }
    });
    if (situationValue?.code !== "Etudiant") {
      setValueList([]);
    }
    dispatch(setFamilyAction(newListFamily));
    setShowSituationDuGarant(true);
  };

  const step1 = useMemo(
    () => listFamily?.[indexFamily].step1,
    [indexFamily, listFamily]
  );

  const optionsSituationList = useMemo(
    () =>
      situationsList?.map((x: Situation) => {
        if (x?.code === "Etudiant") {
          return {
            ...x,
            label: x?.displayName,
            value: x?.id,
            enfants: [
              {
                code: "withGarant",
                displayName: "Avec garant",
                id: "withGarant",
                peutEtreAttribueGarant: true,
              },
              {
                code: "scholarship",
                displayName: "Boursier",
                id: "scholarship",
              },
              { code: "other", displayName: "Avec revenus", id: "other" },
            ],
          };
        } else {
          return {
            ...x,
            label: x?.displayName,
            value: x?.id,
          };
        }
      }) || [],
    [situationsList]
  );

  const handleChangeSituationValue = (value: string | number) => {
    const selectValue = optionsSituationList?.find(
      (x: Situation) => x?.value === value
    );
    setSituationValue(selectValue);
    if (selectValue?.enfants?.length > 0) {
      setSituationChildrenList(
        selectValue?.enfants?.map((x: Situation) => ({
          ...x,
          label: x?.displayName,
          value: x?.id,
        }))
      );
      setSituationChildrenValue(null);
    } else {
      setSituationChildrenList([]);
      setSituationChildrenValue(null);
    }
  };

  const handleChangeSituationGarantValue = (value: string | number) => {
    const selectValue = optionsSituationList?.find(
      (x: Situation) => x?.value === value
    );
    setSituationGarantValue(selectValue);
    if (selectValue?.enfants?.length > 0) {
      setSituationChildrenGarantList(
        selectValue?.enfants?.map((x: Situation) => ({
          ...x,
          label: x?.displayName,
          value: x?.id,
        }))
      );
      setSituationChildrenGarantValue(null);
    } else {
      setSituationChildrenGarantList([]);
      setSituationChildrenGarantValue(null);
    }
  };

  const handleChangeSituationChildrenValue = (value: string | number) => {
    const selectValue = situationChildrenList?.find(
      (x: Situation) => x?.value === value
    );
    setSituationChildrenValue(selectValue);
  };

  const handleChangeSituationChildrenGarantValue = (value: string | number) => {
    const selectValue = situationChildrenList?.find(
      (x: Situation) => x?.value === value
    );
    setSituationChildrenGarantValue(selectValue);
  };

  const handleShowSituation = () => {
    const data = listFamily[indexFamily];
    setShowSituation(!showSituation);
    setSituationValue(data?.step2?.parent);
    setDataStep3(listFamily[indexFamily]?.step3 || []);
    if (data?.step2?.children?.id) {
      setSituationChildrenValue(data?.step2?.children);
    }
    if (data?.step2?.parent?.enfants?.length > 0) {
      setSituationChildrenList(
        data?.step2?.parent?.enfants?.map((x: Situation) => ({
          ...x,
          label: x?.displayName,
          value: x?.id,
        }))
      );
    }
  };

  const handleShowIdentite = () => {
    setShowIdentite(!showIdentite);
    const data = listFamily[indexFamily];
    form.setFieldsValue(data?.step1);
    setGender(data?.step1?.civilite);
  };

  const handleShowSituationDuGarant = () => {
    const data = listFamily[indexFamily];
    setSituationGarantValue(data?.situationGarant?.parent);
    setDataStep3Garant(listFamily[indexFamily]?.step3Garant || []);
    if (data?.situationGarant?.children?.id) {
      setSituationChildrenGarantValue(data?.situationGarant?.children);
    }
    if (data?.situationGarant?.parent?.enfants?.length > 0) {
      setSituationChildrenGarantList(
        data?.situationGarant?.parent?.enfants?.map((x: Situation) => ({
          ...x,
          label: x?.displayName,
          value: x?.id,
        }))
      );
    }
    setShowSituationDuGarant(!showSituationDuGarant);
  };

  const handleRemoveField = (index: number) => {
    setDataStep3(
      dataStep3?.filter(
        (x: TypeList, indexFilter: number) => indexFilter !== index
      )
    );
  };

  const handleChangeNumber = (value: number, index: number) => {
    setDataStep3(
      dataStep3?.map((x: TypeList, indexMap: number) => {
        if (indexMap === index) {
          return { ...x, value };
        } else {
          return x;
        }
      })
    );
  };

  const handleChangeNumberGarant = (value: number, index: number) => {
    setDataStep3Garant(
      dataStep3Garant?.map((x: TypeList, indexMap: number) => {
        if (indexMap === index) {
          return { ...x, value };
        } else {
          return x;
        }
      })
    );
  };

  const handleShowResource = (value: number) => {
    if (value === 1) {
      setValueList(dataStep3);
    } else {
      setValueList(dataStep3Garant);
    }
    setKindValueList(value);
    setShow(true);
  };

  const handleRemoveFieldGarant = (index: number) => {
    setDataStep3Garant(
      dataStep3Garant?.filter(
        (x: TypeList, indexFilter: number) => indexFilter !== index
      )
    );
  };

  return (
    <div id="itemFour">
      <h2 className="main-color">Récapitulatif du locataire</h2>
      <div className="listValue">
        <div className="mg-b25 listValueItem">
          <div className="titleValue">
            <p className="font-w600 font-16 lh-19">Identité</p>
            <div
              className="actionValueGroup"
              onClick={() => handleShowIdentite()}
            >
              <img src={edit} alt="loading"></img>
            </div>
          </div>
          {showIdentite ? (
            <div className="w-100 d-flex flex-direction-col showValue">
              <div className="w-100 f-flex flex-direction-col flex-ver-top mg-b25 showValueItem">
                <p className="font-14 lh-17 mg-b14">Civilité</p>
                <h6 className="font-16 lh-19">{step1?.civilite?.libelle}</h6>
              </div>
              <div className="w-100 f-flex flex-direction-col flex-ver-top mg-b25 showValueItem">
                <p className="font-14 lh-17 mg-b14">
                  Nom de famille du locataire
                </p>
                <h6 className="font-16 lh-19">{step1?.name}</h6>
              </div>
              <div className="w-100 f-flex flex-direction-col flex-ver-top mg-b25 showValueItem">
                <p className="font-14 lh-17 mg-b14">Prénom</p>
                <h6 className="font-16 lh-19">{step1?.subName}</h6>
              </div>
              <div className="w-100 f-flex flex-direction-col flex-ver-top mg-b25 showValueItem">
                <p className="font-14 lh-17 mg-b14">Numéro de teléphone</p>
                <h6 className="font-16 lh-19">{step1?.phone}</h6>
              </div>
              <div className="w-100 f-flex flex-direction-col flex-ver-top mg-b25 showValueItem">
                <p className="font-14 lh-17 mg-b14">Adresse email</p>
                <h6 className="font-16 lh-19">{step1?.email}</h6>
              </div>
            </div>
          ) : (
            <Form form={form} className="showForm" onFinish={handleChangeData}>
              <div className="w-100 inputGroup">
                <div className="mg-b10 formItem">
                  <p>Civilité</p>
                  <ul className="w-100 d-flex mg-t10 radioGroup">
                    <li
                      className={`toggleActive ${
                        gender?.id === civilites?.[0]?.id ? "" : "move"
                      }`}
                    ></li>
                    {civilites?.map((x: Civilites, index: number) => (
                      <li
                        key={index}
                        className={`w-50 radius-4 cursor mg-r10 ${
                          gender?.id === x?.id ? "activeLi" : ""
                        }`}
                        onClick={() => setGender(x)}
                      >
                        <span className="font-w600 font-14 lh-17">
                          {x?.libelle}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mg-b10 formItem">
                  <p>Nom de famille du locataire</p>
                  <Form.Item
                    name="name"
                    rules={[
                      { required: true, message: "Please input your name!" },
                    ]}
                  >
                    <input
                      placeholder="Nom du locataire"
                      className="input"
                    ></input>
                  </Form.Item>
                </div>
                <div className="mg-b10 formItem">
                  <p>Prénom</p>
                  <Form.Item
                    name="subName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your sub name!",
                      },
                    ]}
                  >
                    <input placeholder="Son prenom" className="input"></input>
                  </Form.Item>
                </div>
                <div className="mg-b10 formItem">
                  <p>Numéro de téléphone</p>
                  <Form.Item
                    name="phone"
                    rules={[
                      { required: true, message: "Please input your phone!" },
                    ]}
                  >
                    <input
                      placeholder="Son numéro de téléphone mobile"
                      className="input"
                      type="input"
                      onKeyDown={(e: any) =>
                        (e.keyCode === 69 || e.keyCode === 190) &&
                        e.preventDefault()
                      }
                    ></input>
                  </Form.Item>
                </div>
                <div className="mg-b10 formItem">
                  <p>Adresse email</p>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                    ]}
                  >
                    <input
                      placeholder="Son adresse email"
                      className="input"
                    ></input>
                  </Form.Item>
                </div>
              </div>
              <div className="w-100 d-flex flex-hor-between buttonGroup">
                <button
                  type="button"
                  className="submitButton"
                  onClick={() => handleShowIdentite()}
                >
                  Annuler
                </button>
                <button type="submit" className="submitButton">
                  Enregistrer
                </button>
              </div>
            </Form>
          )}
        </div>

        <div className="mg-b25 listValueItem">
          <div className="titleValue">
            <p className="font-w600 font-16 lh-19">Situation du locataire</p>
            <div
              className="actionValueGroup"
              onClick={() => handleShowSituation()}
            >
              <img src={edit} alt="loading"></img>
            </div>
          </div>
          {showSituation ? (
            <div className="w-100 d-flex flex-direction-col showValue">
              <div className="w-100 f-flex flex-direction-col flex-ver-top mg-b25 showValueItem">
                <p className="font-14 lh-17 mg-b14">
                  Situation professionnelle
                </p>
                <h6 className="font-16 lh-19">{situationValue?.displayName}</h6>
              </div>
              {situationChildrenValue?.displayName && (
                <div className="w-100 f-flex flex-direction-col flex-ver-top mg-b25 showValueItem">
                  <p className="font-14 lh-17 mg-b14">Source de revenux</p>
                  <h6 className="font-16 lh-19">
                    {situationChildrenValue?.displayName}
                  </h6>
                </div>
              )}
              {dataStep3?.map((x: any) => (
                <div className="w-100 f-flex flex-direction-col flex-ver-top mg-b25 showValueItem">
                  <p className="font-14 lh-17 mg-b14"> {x?.displayName}</p>
                  <h6 className="font-16 lh-19">{x?.value} €</h6>
                </div>
              ))}
            </div>
          ) : (
            <div className="w-100 showForm">
              <div className="w-100 mg-b20 inputGroup">
                <div className="mg-b15 formItem">
                  <p>Situation professionnelle</p>
                  <div className="w-100 mg-t10">
                    <SelectComponent
                      placeholder="Select"
                      options={optionsSituationList}
                      value={situationValue?.id}
                      onChange={(e: string | number) =>
                        handleChangeSituationValue(e)
                      }
                    ></SelectComponent>
                  </div>
                </div>
                {situationChildrenList?.length > 0 && (
                  <div className="mg-b10 formItem">
                    <p>Source de revenux</p>
                    <div className="w-100 mg-t10">
                      <SelectComponent
                        placeholder="Select"
                        options={situationChildrenList}
                        value={situationChildrenValue?.id}
                        onChange={(e: string | number) =>
                          handleChangeSituationChildrenValue(e)
                        }
                      ></SelectComponent>
                    </div>
                  </div>
                )}

                {dataStep3?.map((x: any, index: number) => (
                  <div className="mg-b15 noBackground valueItem" key={index}>
                    <div>
                      <p>
                        {index === 0
                          ? "Salaire mensuel du garant"
                          : x?.displayName}
                      </p>
                    </div>
                    <div className="w-100 d-flex pad-b15 inputField">
                      <input
                        value={x?.value || ""}
                        className="input"
                        type="number"
                        min={0}
                        placeholder="ex: 200"
                        onKeyDown={(e) =>
                          (e.keyCode === 69 ||
                            e.keyCode === 190 ||
                            e.keyCode === 189) &&
                          e.preventDefault()
                        }
                        onChange={(e) =>
                          handleChangeNumber(Number(e?.target?.value), index)
                        }
                      ></input>
                      {index !== 0 && (
                        <img
                          src={trash}
                          alt="trash"
                          onClick={() => handleRemoveField(index)}
                        ></img>
                      )}
                    </div>
                  </div>
                ))}
                <div className="d-flex flex-hor-start flex-ver-center mg-b30 addField">
                  <div
                    className="d-flex flex-hor-center flex-ver-center radius-circle mg-r15 cursor"
                    onClick={() => handleShowResource(1)}
                  >
                    <p>+</p>
                  </div>
                  <p className="font-14 lh-17">
                    Ajouter une ressource complémentaire
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex flex-hor-between buttonGroup">
                <button
                  type="button"
                  className="submitButton"
                  onClick={() => handleShowSituation()}
                >
                  Annuler
                </button>
                <button
                  className="submitButton"
                  onClick={handleChangeSituation}
                  disabled={
                    !situationValue ||
                    (situationValue &&
                      situationValue?.enfants?.length > 0 &&
                      !situationChildrenValue)
                  }
                >
                  Enregistrer
                </button>
              </div>
            </div>
          )}
        </div>

        {listFamily[indexFamily]?.step2?.parent?.peutEtreAttribueGarant ||
          (listFamily[indexFamily]?.step2?.children?.peutEtreAttribueGarant && (
            <div className="listValueItem">
              <div className="titleValue">
                <p className="font-w600 font-16 lh-19">Situation du garant</p>
                <div
                  className="actionValueGroup"
                  onClick={() => handleShowSituationDuGarant()}
                >
                  <img src={edit} alt="loading"></img>
                </div>
              </div>
              {showSituationDuGarant ? (
                <div className="w-100 d-flex flex-direction-col showValue">
                  <div className="w-100 f-flex flex-direction-col flex-ver-top mg-b25 showValueItem">
                    <p className="font-14 lh-17 mg-b14">
                      Situation professionnelle
                    </p>
                    <h6 className="font-16 lh-19">
                      {situationGarantValue?.displayName}
                    </h6>
                  </div>
                  {situationChildrenGarantValue?.displayName && (
                    <div className="w-100 f-flex flex-direction-col flex-ver-top mg-b25 showValueItem">
                      <p className="font-14 lh-17 mg-b14">Source de revenux</p>
                      <h6 className="font-16 lh-19">
                        {situationChildrenGarantValue?.displayName}
                      </h6>
                    </div>
                  )}
                  {listFamily[indexFamily]?.step3Garant?.map(
                    (x: any, index: number) => (
                      <div
                        key={index}
                        className="w-100 f-flex flex-direction-col flex-ver-top mg-b25 showValueItem"
                      >
                        <p className="font-14 lh-17 mg-b14">
                          {" "}
                          {index === 0
                            ? "Salaire mensuel du garant"
                            : x?.displayName}
                        </p>
                        <h6 className="font-16 lh-19">{x?.value || 0}€</h6>
                      </div>
                    )
                  )}
                </div>
              ) : (
                <div className="w-100 showForm">
                  <div className="w-100 inputGroup">
                    <div className="mg-b15 formItem">
                      <p>Situation professionnelle</p>
                      <div className="w-100 mg-t10">
                        <SelectComponent
                          placeholder="Select"
                          options={optionsSituationList}
                          value={situationGarantValue?.id}
                          onChange={(e: string | number) =>
                            handleChangeSituationGarantValue(e)
                          }
                        ></SelectComponent>
                      </div>
                    </div>
                    {situationChildrenGarantList?.length > 0 && (
                      <div className="mg-b10 formItem">
                        <p>Source de revenux</p>
                        <div className="w-100 mg-t10">
                          <SelectComponent
                            placeholder="Select"
                            options={situationChildrenGarantList}
                            value={situationChildrenGarantValue?.id}
                            onChange={(e: string | number) =>
                              handleChangeSituationChildrenGarantValue(e)
                            }
                          ></SelectComponent>
                        </div>
                      </div>
                    )}
                    {dataStep3Garant?.map((x: any, index: number) => (
                      <div
                        className="mg-b15 noBackground valueItem"
                        key={index}
                      >
                        <div>
                          <p>
                            {index === 0
                              ? "Salaire mensuel du garant"
                              : x?.displayName}
                          </p>
                        </div>
                        <div className="w-100 d-flex pad-b15 inputField">
                          <input
                            value={x?.value || ""}
                            className="input"
                            type="number"
                            min={0}
                            placeholder="ex: 200"
                            onKeyDown={(e) =>
                              (e.keyCode === 69 ||
                                e.keyCode === 190 ||
                                e.keyCode === 189) &&
                              e.preventDefault()
                            }
                            onChange={(e) =>
                              handleChangeNumberGarant(
                                Number(e?.target?.value),
                                index
                              )
                            }
                          ></input>
                          {index !== 0 && (
                            <img
                              src={trash}
                              alt="trash"
                              onClick={() => handleRemoveFieldGarant(index)}
                            ></img>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="d-flex flex-hor-start flex-ver-center mg-b30 addField">
                    <div
                      className="d-flex flex-hor-center flex-ver-center radius-circle mg-r15 cursor"
                      onClick={() => handleShowResource(2)}
                    >
                      <p>+</p>
                    </div>
                    <p className="font-14 lh-17">
                      Ajouter une ressource complémentaire
                    </p>
                  </div>
                  <div className="w-100 d-flex flex-hor-between buttonGroup">
                    <button
                      type="button"
                      className="submitButton"
                      onClick={() =>
                        setShowSituationDuGarant(!showSituationDuGarant)
                      }
                    >
                      Annuler
                    </button>
                    <button
                      className="submitButton"
                      onClick={handleChangeSituationDuGarant}
                      disabled={
                        !situationGarantValue ||
                        (situationGarantValue &&
                          situationGarantValue?.enfants?.length > 0 &&
                          !situationChildrenGarantValue)
                      }
                    >
                      Enregistrer
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
      <div className="buttonContainer">
        <button onClick={handleSubmit} className="submitButton">
          Locataire suivant
          <img src={arrow} alt="loading"></img>
        </button>
        <div className="footerButton">
          <Trash />
          <p>Supprimer le locataire</p>
        </div>
      </div>
    </div>
  );
};

export default ItemFour;
