import { cbnoListQuestions } from "features/CBNO/cbnoListQuestion";
import { cbnoSteps, emprunteurSteps, gliSteps, mriSteps } from "features/components/stepper/simuStepperList";


export function getSimulationStepsByType(type:string) {
    switch (type) {
        case 'cbno':
            return cbnoSteps;
        case 'gli':
            return gliSteps;
        case 'mri':
            return mriSteps;
        case 'emprunteur':
            return emprunteurSteps;
        default:
            return cbnoSteps;
    }
}

export function getCbnoCurrentQuestions(activeIntermediateStep: number) {
    if (activeIntermediateStep > 3)
    activeIntermediateStep--;

    return cbnoListQuestions[activeIntermediateStep];
}