import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import PiscineIcon from "assets/image/mri/piscine.svg";
import GardienIcon from "assets/image/mri/gardien.svg";
import TennisIcon from "assets/image/mri/terrain_de_tennis.svg";
import PoubelleIcon from "assets/image/mri/poubelle.svg";
import SurveillanceIcon from "assets/image/mri/societe_surveillance.svg";
import MurIcon from "assets/image/mri/mur.svg";
import nextArrow from "assets/image/startStep/arrow.svg";
import { handleNext, selectDossierChoices } from "features/dossierSimuSlice";
import CheckedCard from "features/components/checkedCard/checkedCard";


type Props = {
  handleOnClick: (
    fieldName: string,
    value: boolean | string | any
  ) => void;
  isSide?: boolean
};

const CoOwnerShipSix: React.FC<Props> = ({ handleOnClick, isSide = false }) => {
  const mri = useAppSelector(selectDossierChoices);
  const dispatch = useAppDispatch();
  const [checkedItems, setCheckedItems] = useState<
    { label: string; value: boolean; icon: any }[]
  >(
    mri && mri.nextElements
      ? mri.nextElements
      : [
        { label: "Piscine", value: false, icon: PiscineIcon },
        { label: "Local Poubelle", value: false, icon: PoubelleIcon },
        { label: "Terrain de tennis", value: false, icon: TennisIcon },
        {
          label: "Gardien ou société de gardiennage",
          value: false,
          icon: GardienIcon,
        },
        {
          label: "Société de surveillance",
          value: false,
          icon: SurveillanceIcon,
        }
      ]
  );

  useEffect(() => {
    if (mri && mri?.occupation === 'Moins de 50%') {
      const alreadyExist = mri?.nextElements?.find((el: any) => el?.label === "Mur ou obstruction efficace contre l'intrusion")?.value || false
      if (!alreadyExist) {
        setCheckedItems((prevCheckedItems) => [
          ...prevCheckedItems,
          {
            label: "Mur ou obstruction efficace contre l'intrusion",
            value: false,
            icon: MurIcon,
          },
        ]);
      }
    }
  }, [mri?.occupation]);

  const handleCardClick = (index: number) => {
    const updatedCheckedItems = checkedItems.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          value: !item.value,
        };
      }
      return item;
    });

    setCheckedItems(updatedCheckedItems);
    handleOnClick("nextElements", updatedCheckedItems);
  };

  const isOneOrMoreChecked = checkedItems.some((items) => items.value === true);

  const skipStep = () => {
    handleOnClick( "nextElements", checkedItems);
    dispatch(handleNext());
  };

  return (
    <div className={`${!isSide ? "max-w1300 mg-0-auto ccc-body-step-2" : ""}`}>
      <div className="w-100 text-center border-box radius-15 cccbs2r1">
          {
          <div className="mg-b20">
              <>
                <div>
                  <p className="font-18 lh-22 text-dark font-w700 cccbs2r1r1">
                    La copropriété comprend-t-elle les éléments suivants ?
                  </p>
                </div>
              </>
          </div>
          }
        <div
          className={`${!isSide ? "d-flex flex-hor-center cccbs2r1r2" : "d-flex flex-hor-start"}`}
          style={{ flexWrap: "wrap" }}
        >
          {checkedItems.map((item, index) => (
            <CheckedCard
              key={index}
              label={item.label}
              length={checkedItems.length}
              icon={item.icon}
              checked={item.value}
              onClick={() => handleCardClick(index)}
              isSide={false}
            />
          ))}
        </div>
        {
            <div className="cursor d-flex flex-hor-end mg-r20 mg-t10" onClick={skipStep}>
              <p className=" font-14 text-dark-light font-w700 mg-r10">
                Passer cette étape
              </p>
              <img src={nextArrow} alt="next" />
            </div>
        }
      </div>
      {
            <div className="d-flex flex-hor-end mg-t10">
              <button
                className="submitButton w-30"
                disabled={!isOneOrMoreChecked}
                onClick={() => dispatch(handleNext())}
              >
                Étape suivante
              </button>
            </div>
        }
    </div>
  );
};

export default CoOwnerShipSix;
