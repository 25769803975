import { useAppDispatch, useAppSelector } from "app/hooks";
import Loading from "components/Loading/loading";
import ChoiceGarantie from "features/components/choiceGarantie/choiceGarantie";
import { getGarantieByTunnel } from "features/components/choiceGarantie/garantie";
import ModalSimu from "features/components/modal/modal";
import { handleNext, setShowHeader } from "features/dossierSimuSlice";
import { configureGarantiesSimulation, downloadDocumentByStoredId, getPrixClientByGaranties, getSimulationInfo } from "pages/mainStep/mainStepAPI";
import { IGarantie } from "pages/mainStep/type";
import { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ChoiceGarantieEmp from "./choiceGarantieEmp";


const TarificateurEmp: React.FC = () => {

    const [isModal, setIsModal] = useState<boolean>(false);
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
    const [content, setContent] = useState<ReactNode>();
    const [garanties, setGaranties] = useState<IGarantie[]>([]);
    const [garantiesRetenues, setGarantiesRetenues] = useState<string[]>([]);
    const [listImage, setListImage] = useState<any>();
    const [tunnelId, setTunnelId] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { simulationId } = useAppSelector(
        (state: any) => state.mainSlice
      );
    const location= useLocation();

    //Permet de gerer l'affichage du stepper en cas de resize
    useEffect(() => {
        setIsSmallScreen(window.innerWidth < 567);
        const handleWindowResize = () => {
          setIsSmallScreen(window.innerWidth < 567);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
          setIsModal(false);
          dispatch(setShowHeader(true));
      };
      }, [])

      //Permet de fetch les images des garanties
      const fetchAllImg = async (garanties: IGarantie[]) => {
        const updateRep = [];
        for (let i = 0; i < garanties.length; i++) {

            let parentImg;
            if (garanties[i] && garanties[i].iconeId) {
                const blob = await downloadDocumentByStoredId(garanties[i].iconeId);
                parentImg = URL.createObjectURL(blob);
            }
            else {
                parentImg = "";
            }
            const enfantsUrl = [];

            for (let j = 0; j < garanties[i]?.enfants?.length; j++) {
                const enfant = garanties[i]?.enfants[j];

                if (enfant && enfant.iconeId) {
                    const enfantBlob = await downloadDocumentByStoredId(enfant.iconeId);
                    const enfantImg = URL.createObjectURL(enfantBlob)

                    enfantsUrl.push(enfantImg);
                }
            }
            updateRep.push({ garantie: parentImg, enfants: enfantsUrl });
        }
        setListImage(updateRep);
    }

    //Fetch des datas au load de la page
    useEffect(() => {
      fetchGarantiesDisponibles();
    }, [])

      const fetchGarantiesDisponibles = async () => {
          setLoading(true);
          const res = await getSimulationInfo(simulationId, location.pathname.split("/")[1].toUpperCase());
          const garanties = await getGarantieByTunnel(res.tunnelId).then(async (garanties) => {
            setTunnelId(res.tunnelId);
            await fetchAllImg(garanties);
            setGaranties(garanties);
            setGarantiesRetenues(garanties.filter((garantie: any) => garantie?.isRequired === true).map((garantie: any) => garantie?.id))
            setLoading(false);
          }).catch();
      } 


    //Permet de changer les garanties retenues
    const handleSelect = (x:any) => {

      //TO-DO Changer pour l'emprunteur
        if (isRetenue(x.id))
            setGarantiesRetenues((prev) => prev.filter((garantie: any) => garantie != x.id))
        else
            setGarantiesRetenues((prev) => [...prev, x.id]);
    }

    //Permet de savoir si une garantie est deja retenues
    //TO-DO Changer pour l'emprunteur
    const isRetenue = (id:string) => {
        const garantie = garantiesRetenues?.find((garantie:any) => garantie == id)
        return (garantie ? true : false);
    }

    //Permet la gestion des plafonds et des franchises notamment
    const handleGaranties = async (newGarantie: IGarantie) => {
        setGaranties((prevGaranties) => {
            return prevGaranties.map((garantie) =>
              garantie.id === newGarantie.id ? newGarantie : garantie
            );
          });
    }

    const submitGaranties = async () => {
      //Eventuellement ajouter ici la creation du devis
      dispatch(handleNext());
    }

    return (
        <>
        <div className={`container`}>
          {
            isModal === true && 
            <ModalSimu isSmallScreen={isSmallScreen} setShowModal={setIsModal} children={content}/>
          }
          {
            !isModal && 
            <ChoiceGarantieEmp 
            setModal={setIsModal}
            garanties={garanties}
            garantiesRetenues={garantiesRetenues}
            handleGaranties={handleGaranties}
            listImage={listImage}
            handleSelect={handleSelect}
            setContent={setContent}
            submit={submitGaranties}
            />
          }
          </div>
          <Loading loading={loading} isFullPage/>
        </>
    )
}

export default TarificateurEmp;