import React, { useEffect, useState } from "react";
import arrow from "assets/image/startStep/arrow.svg";
import { setMoneyAction } from "pages/mainStep/mainSlice";
import house from "assets/image/startStep/house.svg";
import { ReactComponent as House } from "assets/image/startStep/house.svg";
import { getMinMaxAction, getCalculateInsurance } from "../monBienSlice";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { handleJustify } from "helpers/handleJustify";
import { useLocation } from "react-router-dom";

const MonBienStepOne = ({ handleNextStep }: any) => {
  const [value, setValue] = useState(0);

  const dispatch = useAppDispatch();
  const location = useLocation();

  const { money, simulationId, accessToken } = useAppSelector((state) => state.mainSlice);
  const { minMax, insurance, loading } = useAppSelector(
    (state: any) => state.monBienSlice
  );

  useEffect(() => {
    setTimeout(() => {
      const newValue =
        Number(money) === 0 ? minMax?.montantLoyerMinimal || 0 : Number(money);
      setValue(newValue);
      const input = document.querySelector(
        'input[type="range"]'
      ) as HTMLInputElement;
      input.style.backgroundSize = !simulationId
        ? "0% 100%"
        : ((newValue - minMax?.montantLoyerMinimal) * 100) /
            (minMax?.montantLoyerMaximal - minMax?.montantLoyerMinimal) +
          "% 100%";
    }, 100);
  }, [money, minMax, simulationId, location.pathname]);

  useEffect(() => {
    if (simulationId) {
      dispatch(getMinMaxAction(simulationId));
    }
  }, [dispatch, simulationId]);

  const handleSetValue = (value: number) => {
    setValue(value);
    const input = document.querySelector(
      'input[type="range"]'
    ) as HTMLInputElement;
    input.style.backgroundSize =
      ((value - minMax?.montantLoyerMinimal) * 100) /
        (minMax?.montantLoyerMaximal - minMax?.montantLoyerMinimal) +
      "% 100%";
  };

  let time: any;

  useEffect(() => {
    if (simulationId && value) {
      clearTimeout(time);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      time = setTimeout(() => {
        dispatch(
          getCalculateInsurance({
            id: simulationId,
            data: { 
              montantLoyerMensuelle: value
            },
          })
        );
      }, 300);
    }

    return () => clearTimeout(time);
  }, [value]);

  const handleToNextStep = () => {
    handleNextStep();
    dispatch(setMoneyAction(value));
  };

  const handleChangeValue = (direction: string) => {
    if (simulationId && minMax) {
      let newValue: any;
      const oldValue = Number(value);

      if (direction === "up") {
        newValue = oldValue + 1;
      } else {
        newValue = oldValue - 1;
      }

      if (newValue < 0) {
        handleSetValue(oldValue);
      }else{
        handleSetValue(newValue);
      }
    }
  };

  useEffect(() => {
    handleJustify("monBienStepOne");
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => handleJustify("monBienStepOne"));

    return () =>
      window.removeEventListener("resize", () =>
        handleJustify("monBienStepOne")
      );
  }, []);

  return (
    <div id="monBienStepOne">
      <House className="houseImage"></House>

      <h2 className="main-color">Quel est votre loyer ?</h2>
      <h6>Indiquez-nous votre loyer hors-charges</h6>
      <div className="showValue">
        <p className="sub-color">
          {value}€<span> / mois hors charges</span>
        </p>
      </div>
      <div className="inputRangeWrap">
        <div
          className="actionInputRange"
          onClick={() => handleChangeValue("down")}
        >
          <span>-</span>
        </div>
        <input
          className="bg-input-range"
          disabled={!simulationId && !minMax}
          type="range"
          min={minMax?.montantLoyerMinimal || 0}
          max="3000"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleSetValue(Number(e.target.value));
          }}
          value={value || 0}
        />
        <div
          className="actionInputRange"
          onClick={() => handleChangeValue("up")}
        >
          <span>+</span>
        </div>
      </div>

      <p className="feeTitle">Votre assurance GLI à partir de</p>
      <div className="bg-border showFee">
        <p className="main-color">{insurance?.cotisationMensuelle || 0} €</p>
        <span className="main-color">
          par mois, soit {insurance?.cotisationAnnuelle || 0} € / an
        </span>
      </div>

      <button
        className="submitButton"
        onClick={handleToNextStep}
        disabled={value === 0 || loading}
      >
        J'assure mon bien
        <img src={arrow} alt="loading" className="nextArrow"></img>
      </button>
      <h5>
        Vous souhaitez assurer plusieurs biens dans un même contrat ?{" "}
        <a>Contactez nos experts</a>
      </h5>
    </div>
  );
};

export default MonBienStepOne;
