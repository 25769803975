
import { useAppDispatch } from "app/hooks";
import paperplane from "assets/image/common/paper-plane.svg"
import { resetEmprunteurStore } from "features/Emprunteur/emprunteurSlice";
import { resetAssureStore } from "features/assure/assureSlice";
import { resetConfirmationStore } from "features/confirmation/confirmationSlice";
import { resetContratStore } from "features/contrat/contratSlice";
import { resetDossierSimu, resetDossierStore } from "features/dossierSimuSlice";
import { resetLocataireSlice } from "features/locataires/locatairesSlice";
import { resetPiecesStore } from "features/pieces/piecesSlice";
import { resetMainStore } from "pages/mainStep/mainSlice";

const SimulationTerminee: React.FC = () => {
    const dispatch = useAppDispatch()

    const resetSimu = () => {
        dispatch(resetDossierSimu());
        dispatch(resetMainStore());
        dispatch(resetDossierStore());
        dispatch(resetAssureStore());
        dispatch(resetConfirmationStore());
        dispatch(resetContratStore());
        dispatch(resetEmprunteurStore());
        dispatch(resetLocataireSlice());
        dispatch(resetPiecesStore());
      }

    return (
        <>
        <div id={`monBienForm`}>
            <div className="d-flex flex-direction-col flex-hor-center flex-ver-center mg-t30">
                <img src={paperplane} alt="loading" className="houseImage mg-t20"></img>
                <h2 className="mg-b30 mg-t20">La simulation est terminée</h2>
            </div>
            <div className="container mg-b40">
                <p className="w-80 text-dark-light">Vous serez averti automatiquement par email lorsque la souscription sera définitivement validée, cela peut prendre jusqu'a 48h.</p>
            </div>
            <button className="submitButton w-50" onClick={() => resetSimu()}>
                    Retour a mon espace personnel
            </button>
        </div>
        </>
    )
}

export default SimulationTerminee