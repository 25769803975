import { PageTransition } from "@steveeeie/react-page-transition";
import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import ContratStepOne from "features/contrat/contratStepOne";
import ContratStepTwo from "features/contrat/contratStepTwo";
import { useLocation } from "react-router-dom";
import { CONFIGASSU_LABEL } from "pages/mainStep/routeList";
import { RouteList } from "pages/mainStep/type";

const Contrat = ({ setShowHeader }: any) => {
  const [key, setKey] = useState("");
  const [oldKey, setOldKey] = useState(0);

  const [direction, setDirection] = useState("moveToLeftFromRight");

  const location = useLocation();

  const { renderStepThreePageThree, auth, routeList } = useSelector(
    (state: any) => state.mainSlice
  );

  const routeListContrat = useMemo(() => {
    const targetFilter: RouteList[] | any = routeList?.filter(
      (x: RouteList) => x?.label === CONFIGASSU_LABEL
    );

    const target = targetFilter?.find((x: any) =>
      x?.list?.includes(location.pathname)
    );
    return target?.list?.map((x: string, index: number) => ({
      key: index + 1,
      link: x,
    }));
  }, [routeList]);

  useEffect(() => {
    const target: { key: number; link: string } | undefined =
      routeListContrat?.find((x: any) => x?.link === location?.pathname);
    if (target) {
      const newDirection =
        oldKey < target?.key ? "moveToLeftFromRight" : "moveToRightFromLeft";
      setOldKey(target?.key);
      setDirection(newDirection);
      setTimeout(() => {
        setKey(`${target?.key}`);
      });
    } else {
      if (auth) {
        setTimeout(() => setKey("3"), 1000);
      } else {
        setTimeout(() => setKey("1"), 1000);
      }
    }
  }, [location?.pathname]);

  return (
    <div id="stepThree">
      {key && (
        <PageTransition
          preset={direction}
          transitionKey={renderStepThreePageThree ? "3" : key}
          enterAnimation={""}
          exitAnimation={""}
        >
          <ContratStepOne setShowHeader={setShowHeader}></ContratStepOne>
          {/* {(() => {
            switch (key) {
              case "1":
                return (
                  <ContratStepOne
                    setShowHeader={setShowHeader}
                  ></ContratStepOne>
                );
              case "2":
                return <ContratStepTwo />;
            }
          })()} */}
        </PageTransition>
      )}
    </div>
  );
};

export default Contrat;
