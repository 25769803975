import "./App.css";
import "./assets/css/main.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import { styleDefault } from "style-config";
import { createGlobalStyle } from "styled-components";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { connectSimulationAppAction, getCourtierBySubDomainAction } from "pages/mainStep/mainSlice";
import PageOne from "features/news/pageOne";
import PageTwo from "features/news/pageThree";
import PageThree from "features/news/pageTwo";
import PageFour from "features/news/pageFour";
import PageFive from "features/news/pageFive";
import PageSix from "features/news/pageSix";
import PageSeven from "features/news/pageSeven";
import PageEight from "features/news/pageEight";
import GliIndex from "pages/index";
import SimuLayout from "features/layout/simuLayout";
import CBNOSimu from "features/CBNO/cbnoIndex";
import MRISimu from "features/MRI/mriIndex";
import EmprunteurSimu from "features/Emprunteur/EmprunteurSimu";

function App() {
  const dispatch = useAppDispatch();
  const { auth, accessToken, courtierId } = useAppSelector(
    (state) => state.mainSlice
  ); 

  useEffect(() => {
    dispatch(connectSimulationAppAction());
  }, []);
  
  useEffect(() => {
    dispatch(getCourtierBySubDomainAction());
  }, [accessToken]);

  const style = styleDefault;

  const GlobalStyles = createGlobalStyle`
    html {
      --color-main: ${style?.["color-main"]};
      --color-sub: ${style?.["color-sub"]};
    }
  `;

  return (
    <div id="App">
      <GlobalStyles />
        <BrowserRouter>
          <Routes>
            <Route path="/gli/:step?/:step2?/:step3?" element={<GliIndex />}></Route>
            <Route path="/begin" element={<PageOne style={style} />}></Route>
            <Route path="/login" element={<PageTwo />}></Route>

            <Route path="/register" element={<PageThree />}></Route>

            <Route path="/accueil" element={<PageFour />}></Route>

            <Route path="/contrats" element={<PageFive />}></Route>

            <Route path="/simulateur" element={<PageSix />}></Route>

            <Route path="/sinistres" element={<PageSeven />}></Route>
            <Route path="/plus" element={<PageEight />}></Route>
            <Route path="/cbno" element={<SimuLayout><CBNOSimu /></SimuLayout>} />
            <Route path="/mri" element={<SimuLayout><MRISimu /></SimuLayout>} />
            <Route path="/emprunteur" element={<SimuLayout><EmprunteurSimu /></SimuLayout>} />
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
