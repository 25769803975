import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectAssuresEmprunts, setAssuresEmprunt } from "features/assure/assureSlice";
import Tabs from "features/components/tabs/tabs";
import { createAssureAction, updateAssureEmpAction } from "features/Emprunteur/emprunteurSlice";
import InformationForm from "./informationForm";
import SituationForm from "./situationForm";
import { useIsSmallScreen } from "hooks/isSmallScreen";

type Props = {
    onCancel: (param: void) => void;
    setAssure?: (param:any) => void;
    assure: {
        no: number,
        assure: any,
        label?: string
    },
    isCreation?: boolean
}

const AssuresForm: React.FC<Props> = ({onCancel, setAssure, assure, isCreation}) => {

    const [active, setActive] = useState<number>(1);
    const [information, setInformation] = useState<any>(assure.assure?.information || null);
    const [situation, setSituation] = useState<any>(assure.assure?.situation || null);
    const assures = useAppSelector(selectAssuresEmprunts);
    const isSmallScreen = useIsSmallScreen(567);
    const dispatch = useAppDispatch();
    const { simulationId} = useAppSelector(
        (state: any) => state.mainSlice
      );

    const handleSubmit = (formData: any) => {
        if (situation && active === 1) {
            let newAssures = [];
            if (assure.assure === null) {
                newAssures = [...assures, {situation, information: formData}];
            }
            else {
                const updatedAssures = [...assures];
                updatedAssures[assure.no] = {situation, information: formData};
                newAssures = updatedAssures;
            }
            if (assure?.assure?.id) {
                handleUpdateAssure({situation, information: formData});
            } else if (isCreation === true) {
                const payload = {
                    data: {situation, information: formData},
                    simulationId: simulationId || ""
                };
                dispatch(createAssureAction(payload));
            } else {
                dispatch(setAssuresEmprunt(newAssures));
            }
            setInformation(null);
            setSituation(null);
            onCancel()
            return ;
        }
        else if (information && active === 2) {
                let newAssures = [];
                if (assure.assure === null) {
                    newAssures = [...assures, {information, situation: formData}];
                }
                else {
                    const updatedAssures = [...assures];
                    updatedAssures[assure.no] = {information, situation: formData};
                    newAssures = updatedAssures;
                }
                if (assure?.assure?.id) {
                    handleUpdateAssure({information, situation: formData});
                } else if (isCreation === true) {
                    const payload = {
                        data: {information, situation: formData},
                        simulationId: simulationId || ""
                    };
                    dispatch(createAssureAction(payload));
                } else {
                    dispatch(setAssuresEmprunt(newAssures));
                }
                setSituation(null)
                setInformation(null)

                //Ici ajouter un update eventuel de l'assure
                onCancel()
                return ;
        }
        if (active === 1) {
            setInformation(formData);
            if (!situation)
                setActive(2);
        }
        else if (active === 2) {
            setSituation(formData);
        }
    }

    const handleUpdateAssure = (formData: any) => {
        dispatch(updateAssureEmpAction(
            {
                assure: formData, 
                assureId: assure?.assure?.id, 
                persPhysiqueId: assure?.assure?.persPhysiqueId,
                adresseId: assure?.assure?.information?.adresseId
            } ));
    }

    return (
        <div className="d-flex flex-hor-center flex-ver-center flex-direction-col w-100 h-100 overflow-hidden">
            <div className="d-flex flex-hor-center flex-ver-center flex-direction-col w-100 h-100 overflow-hidden"
                style={{padding: `${isSmallScreen ? "0px" : "0px 0px 0px 60px"}`, boxSizing: "border-box"}}
            >
                <h2 className="mg-b40 pad-l15">{assure?.label}</h2>
                <Tabs
                    tabs={[
                    {
                        label: "Informations",
                        value: 1,
                        haveNumbers: false,
                    },
                    {
                    label: "Situation",
                    value: 2,
                    haveNumbers: false,
                    },
                    ]}
                    active={active}
                    setActive={assure.assure === null ? () => {;} : setActive}
                    customStyle="w-100-imp"
                    tiny
                />
                <div className="mg-t30 w-100 h-100 d-flex flex-hor-center  overflow-auto">
            {(() => {
            switch (active) {
                case 1:
                    return <InformationForm
                        onCancel={onCancel}
                        onSubmit={handleSubmit}
                        information={information}
                        assure={assure}
                    />;
                case 2:
                    return <SituationForm 
                        onCancel={onCancel}
                        onSubmit={handleSubmit}
                        situation={situation}
                        assure={assure}
                    />
                default:
                    return <></>
                }
            })()}
        </div>
        </div>
    </div>
    )
}

export default AssuresForm