import { PageTransition } from "@steveeeie/react-page-transition";
import { useEffect, useState } from "react";

import "../../type.d.ts";

import ConfirmStepOne from "features/confirmation/confirmStepOne";
import ConfirmStepTwo from "features/confirmation/confirmStepTwo";

import { useLocation, useNavigate } from "react-router-dom";
import { CONFIRMATION1, CONFIRMATION2, CONFIRMATION3 } from "pages/mainStep/routeList";

import { createDevisAction } from "../mainStep/mainSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import SimulationTerminee from "features/commonSteps/simulationTerminee";

const Confirmation = () => {
  const { simulationId } = useAppSelector(
    (state) => state.mainSlice
  );
  
  const routeList = [
    { key: 1, link: CONFIRMATION1 },
    { key: 2, link: CONFIRMATION2 },
    { key: 3, link: CONFIRMATION3 },
  ];
  const [key, setKey] = useState("1");
  const [oldKey, setOldKey] = useState(0);

  const [direction, setDirection] = useState("moveToLeftFromRight");
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const handleNextPage = () => {
    // dispatch(createDevisAction({simulationId: simulationId, assureId: undefined, contratParentId: undefined}));
    navigate(CONFIRMATION2);
  };

  useEffect(() => {
    const target: any = routeList?.find(
      (x: any) => x?.link === location?.pathname
    );
    if (target) {
      const newDirection =
        oldKey < target?.key ? "moveToLeftFromRight" : "moveToRightFromLeft";
      setOldKey(target?.key);
      setDirection(newDirection);

      setTimeout(() => {
        setKey(`${target?.key}`);
      });
    } else {
      setTimeout(() => setKey("1"), 1000);
    }
  }, [location?.pathname]);

  return (
    <div id="stepFive">
      <PageTransition
        preset={direction}
        transitionKey={key}
        enterAnimation={""}
        exitAnimation={""}
      >
        {(() => {
          switch (key) {
            case "1":
              return <ConfirmStepOne handleNextPage={handleNextPage} />;
            case "2":
              return <ConfirmStepTwo />;
            case "3":
              return (
                <div id="confirmStepTwo">
                  <SimulationTerminee />
                </div>
              )
            default:
              return <ConfirmStepTwo />;
          }
        })()}
      </PageTransition>
    </div>
  );
};

export default Confirmation;
