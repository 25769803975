import { PageTransition } from "@steveeeie/react-page-transition";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setRenderStepStartPageTwo } from "../mainStep/mainSlice";
import { useLocation } from "react-router-dom";
import MonBienStepTwo from "features/monBien/monBienStepTwo";
import MonBienStepOne from "features/monBien/monBienStepOne";
import { useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import { handleNextStep } from "helpers/handleNextStep";
import { RouteList } from "pages/mainStep/type";
import { MONBIEN_LABEL } from "pages/mainStep/routeList";

const MonBien = () => {
  const [key, setKey] = useState("");
  const [oldKey, setOldKey] = useState(0);

  const [direction, setDirection] = useState("moveToLeftFromRight");

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { renderStepStartPageTwo, routeList } = useAppSelector(
    (state) => state.mainSlice
  );

  const newRouteList = useMemo(() => {
    const targetFilter: RouteList[] | any = routeList?.filter(
      (x: RouteList) => x?.label === MONBIEN_LABEL
    );
    const target = targetFilter?.find((x: any) =>
      x?.list?.includes(location.pathname)
    );
    return target?.list?.map((x: string, index: number) => ({
      key: target?.code?.[index] === "ESR" ? 1 : 2,
      link: x,
    }));
  }, [routeList]);

  useEffect(() => {
    const target: { key: number; link: string } = newRouteList?.find(
      (x: { key: number; link: string }) => x?.link === location?.pathname
    );
    if (target) {
      const newDirection =
        oldKey < target?.key ? "moveToLeftFromRight" : "moveToRightFromLeft";
      setOldKey(target?.key);
      setDirection(newDirection);

      setTimeout(() => {
        setKey(`${target?.key}`);

        setTimeout(() => {
          dispatch(setRenderStepStartPageTwo(false));
        });
      });
    } else {
      setTimeout(() => setKey("1"), 1000);
    }
  }, [location.pathname]);

  const handleToNextStep = () => {
    navigate(handleNextStep(routeList));
  };

  return (
    <div id="monBien">
      {key && (
        <PageTransition
          preset={direction}
          transitionKey={renderStepStartPageTwo ? "2" : key}
          enterAnimation={""}
          exitAnimation={""}
        >
          {key === "1" && (
            <MonBienStepOne handleNextStep={handleToNextStep}></MonBienStepOne>
          )}
          {key === "2" && (
            <MonBienStepTwo handleNextStep={handleToNextStep}></MonBienStepTwo>
          )}
        </PageTransition>
      )}
    </div>
  );
};

export default MonBien;
