
import maisonSimple from "assets/image/CBNO/maisonSimple.svg"
import building from "assets/image/CBNO/building.svg"
import garage from "assets/image/CBNO/garage.svg"
import calendar from "assets/image/CBNO/calendrier.svg"
import canape from "assets/image/CBNO/canape.svg"
import loupe from "assets/image/CBNO/loupe.svg"
import maisonRetraite from "assets/image/CBNO/maison-de-retraite.svg"
import maisonArbre from "assets/image/CBNO/MaisonArbre.svg"
import vacances from "assets/image/CBNO/vacances.svg"
import etudiant from "assets/image/CBNO/EtudiantContrat.svg"

export type cbnoQuestion ={
    question:string,
    options: Options[],
    fieldName: string,
    description?:string
    info?: string
}

export type Options = {
    libelle: string;
    value: string | number | boolean,
    icon?: string
}


export const cbnoListQuestions: cbnoQuestion[] = [
    {
        question: "Que souhaitez vous assurer ?",
        options: [
            {
                libelle: "Une maison",
                value: 1,
                icon: maisonSimple
            },
            {
                libelle: "Un appartement",
                value: 2,
                icon: building
            },
            {
                libelle: "Un local commercial",
                value: 3,
                icon: garage
            },
            {
                libelle: "Un garage",
                value: 4,
                icon: garage
            }
        ],
        fieldName: "TypeBien"
    },
    {
        question: "De quel type de résidence s'agit-il ?",
        options: [
            {
                libelle: "Résidence principale",
                value: "Résidence secondaire",
                icon: maisonSimple
            },
            {
                libelle: "Résidence secondaire",
                value: "Résidence secondaire",
                icon: building
            },
            {
                libelle: "Ni l'un ni l'autre",
                value: "Ni l'un ni l'autre",
                icon: loupe
            }
        ],
        fieldName: "TypeResidence"
    },
    {
        question: "Le logement est-il classé monument historique ?",
        options: [
            {
                libelle: "Oui",
                value: true
            },
            {
                libelle: "Non",
                value: false
            }
        ],
        fieldName: "IsHistorique"
    },
    {
        question: "L'usage professionnel relève t'il d'une de ces activités ?",
        options: [
            {
                libelle: "Oui",
                value: true
            },
            {
                libelle: "Non",
                value: false
            }
        ],
        fieldName: "IsUsagePro",
        description: "Activité de process et transformation ou manipulant principalement des liquides, solide ou gaz inflammables"
    },
    {
        question: "Le logement dispose t'il d'un contrat locatif en cours ?",
        options: [
            {
                libelle: "Location meublée",
                value: 1,
                icon: canape
            },
            {
                libelle: "Location non-meublée",
                value: 2,
                icon: maisonSimple
            },
            {
                libelle: "Location saisonnière",
                value: 3,
                icon: calendar
            },
            {
                libelle: "Location étudiante",
                value: 4,
                icon: etudiant
            },
            {
                libelle: "Pas de contrat en cours",
                value: 5,
                icon: loupe
            }
        ],
        fieldName: "TypeContrat"
    },
    {
        question: "Le logement est-il inoccupé ?",
        options: [
            {
                libelle: "Occupé ou inoccupé moins de 90 jours",
                value: false
            },
            {
                libelle: "Inoccupé plus de 90 jours",
                value: true
            },
        ],
        fieldName: "IsInnocupe",
        info: "Confiancia propose des produits spécifiques aux biens innocupés depuis plus de 90 jours"
    },
    {
        question: "Le bien est-il en cours de rénovation ?",
        options: [
            {
                libelle: "Oui",
                value: true
            },
            {
                libelle: "Non",
                value: false
            }
        ],
        fieldName: "IsRenovation"
    },
    {
        question: "Le bien est situé dans",
        options: [
            {
                libelle: "Une résidence de services (EHPAD, etc.)",
                value: "Une résidence de services (EHPAD, etc.)",
                icon: maisonRetraite
            },
            {
                libelle: "Une résidence de tourisme",
                value: "Une résidence de tourisme",
                icon: vacances
            },
            {
                libelle: "Ni l'un ni l'autre",
                value: "Ni l'un ni l'autre",
                icon: loupe
            }
        ],
        fieldName: "Situation"
    },
    {
        question: "Vous souhaitez etre assuré",
        options: [
            {
                libelle: "Dès que possible",
                value: 0
            },
            {
                libelle: "Dans un mois",
                value: 1
            },
            {
                libelle: "Dans deux mois",
                value: 2
            },
            {
                libelle: "Dans trois mois ou plus",
                value: 3
            }
        ],
        fieldName: "DateAssurance"
    }
]