import { useState } from "react";
import { API_SERVER } from "constants/configs";
import publicAPIInstance from "pages/connexion/publicProxy";

type RiskTypes = {
  risqueDetailList: any[]
}

type GeoRisks = {
  typesRisques: RiskTypes[]
}

// ATTENTION : latLon={longitude, latitude}
function useGeoRisksTypes() {
  const [geoRisks, setGeoRisks] = useState<GeoRisks | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(null);

  const fetchGeoRisksTypes = async (latlon: string | number, page: number, pageSize: number, rayon: number) => {
    try {
      const apiUrl = `${API_SERVER}api/backoffice/georisquesgouv/types-risques?Latlon=${latlon}&Page=${page}&PageSize=${pageSize}&Rayon=${rayon}`;
      setLoading(true);

      const response: any = await publicAPIInstance.get(apiUrl);
      const data = response?.data;

      if (response.status === 200) {
        setGeoRisks(data);
        setError(null);
      } else {
        console.error("Une erreur s'est produite lors de la récupération des données.");
      }
    } catch (error) {
      console.error("Une erreur s'est produite lors de la récupération des données.");
    } finally {
      setLoading(false);
    }
  };

  return { geoRisks, loading, error, fetchGeoRisksTypes };
}

export default useGeoRisksTypes;
