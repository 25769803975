import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectDossierIntermediateStep, setDossierChoices, setType } from "features/dossierSimuSlice";
import ProjetCredit from "./projetCredit";
import Situation from "./situation";

const ProjetIndex: React.FC = () => {
    const intermediateStep = useAppSelector(selectDossierIntermediateStep);
    const dispatch = useAppDispatch();

    const handleOnClick = (
        fieldName: string,
        value: boolean | string | number
      ) => {
        const payload = {
          dossierChoices : {
            [fieldName]: value,
          },
        };
        dispatch(setType("emprunteur"));

        dispatch(setDossierChoices(payload));
      };

    const getContent = () => {
        switch (intermediateStep) {
            case 0:
              return <ProjetCredit handleOnClick={handleOnClick}/>;
            case 1:
              return <Situation handleOnClick={handleOnClick} />;
          }
    }

    return (
        <>
          {getContent()}
        </>
    )
}

export default ProjetIndex