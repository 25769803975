import { useState, useEffect } from "react";

import { ReactComponent as PrevArrow } from "assets/image/startStep/prevArrow.svg";
import checkedItem from "../../../assets/image/stepTwo/checked.svg";
import information from "assets/image/common/information.svg";
import trashCircle from "assets/image/common/trash-circle.svg";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { message, Spin } from "antd";
import { uploadDocAction } from "../piecesSlice";
import { Document } from "pages/mainStep/type";

const PiecesStepTwo = ({ setShowEdit, showEdit, dataEdit }: any) => {
  const [activeSCI, setActiveSCI] = useState(false);
  const [activeSwitch, setActiveSwitch] = useState(1);
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [selectIndex, setSelectIndex] = useState<number | null>();

  const { simulationId } = useAppSelector((state) => state.mainSlice);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (showEdit) {
      const container = document.getElementById("piecesStepTwo");
      container?.scrollTo({ top: 0 });
      setData(dataEdit);
    }
  }, [showEdit]);

  const handleChangeFile = async (
    file: any,
    dataTarget: Document,
    index: number
  ) => {
    const dataForm: any = new FormData();

    dataForm.append("content", file?.target?.files?.[0]);

    const dataSubmit = {
      params: { simulationId, documentId: dataTarget?.id },
      dataForm,
    };
    setLoading(true);
    setSelectIndex(index);

    const res: any = await dispatch(uploadDocAction(dataSubmit));

    if (res?.error) {
      message.error(res?.error?.message);
    } else {
      setData({
        ...data,
        documents: data?.documents?.map((x: Document, indexMap: number) => {
          if (index === indexMap) {
            return { ...x, ...res?.payload };
          } else {
            return x;
          }
        }),
      });
    }
    setLoading(false);
    setSelectIndex(null);
  };

  return (
    <div id="piecesStepTwo">
      <div className="w-100 d-flex flex-hor-between flex-ver-center mg-b30 csfr1">
        <PrevArrow onClick={() => setShowEdit(false)} className="cursor" />
        <p className="font-16 lh-19">Pièces justificatives</p>
        <img
          src={information}
          alt="information"
          className="cursor houseImage"
        ></img>
      </div>
      {data?.documents?.map((x: Document, index: number) => (
        <div
          className={`w-100 mg-b28 csfr3-item ${
            x?.storedFileId && "activeItem"
          }`}
          key={index}
        >
          {x?.storedFileId && (
            <img src={checkedItem} alt="check" className="checked" />
          )}
          <div className="w-100 d-flex flex-hor-between flex-ver-center mg-b28 csfr3r1">
            <p className="font-16 lh-19">
              {x?.designation}{" "}
              <span className="font-13 lh-16">
                ({x?.isRequired ? "Optionnel" : ""})
              </span>
            </p>
            <h6 className="font-12 lh-15">
              {x?.documentStatus}/{x?.nombreDeDocuments}
            </h6>
          </div>
          <div className="w-100 d-flex flex-direction-col flex-ver-center mg-b15 uploadArea">
            {loading && selectIndex === index && <Spin></Spin>}

            {selectIndex !== index && (
              <label
                htmlFor={`upload-${index}`}
                className="mg-b13 d-flex flex-hor-center flex-ver-center cursor uploadArea-icon"
              >
                <p className="text-white">+</p>
              </label>
            )}
            <input
              type="file"
              hidden
              id={`upload-${index}`}
              onInput={(e) => handleChangeFile(e, x, index)}
            ></input>
            <p className="font-13 lh-16 mg-b4 uploadArea-des">
              Importer ou glisser votre document
            </p>
            <p className="font-12 lh-15 uploadArea-rule">
              Formats acceptés : jpg, pdf, png - max 2mo
            </p>
          </div>
          {x?.storedFileId && (
            <div className="w-100 d-flex flex-hor-between flex-ver-center mg-b10 uploadItem">
              <p className="font-14 lh-20">{x?.designation}.pdf</p>
              <img src={trashCircle} alt="trashCircle" className="cursor" />
            </div>
          )}
        </div>
      ))}

      <button
        className="submitButton button-blue"
        onClick={() => setShowEdit(false)}
      >
        Valider
      </button>
    </div>
  );
};

export default PiecesStepTwo;
