import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useDispatch } from "react-redux";
import nextArrow from "assets/image/startStep/arrow.svg";
import { handleNext, selectDossierChoices } from "features/dossierSimuSlice";
import moment from "moment";
import "moment/locale/fr";
import { parsingMRI } from "helpers/parseObjetToDto";
import { createNewCoproAction, selectAssuresCopro, selectNewCopro, setCoproId } from "features/assure/assureSlice";
import { createNewCopro, updateMRISimulation } from "features/assure/assureAPI";
import confirmationSlice from "features/confirmation/confirmationSlice";
import Loading from "components/Loading/loading";

type GarantieType = {
  nom: string;
  nombre: string;
  franchise: string;
  coutTotal: string;
};

type SinistresType = {
  debutExercice: string;
  finExercice: string;
  garanties: GarantieType[];
};

type Props = {
  handleOnClick: (
    fieldName: string,
    value: boolean | string | any
  ) => void;
  isSide?: boolean
};



const StepFourteen: React.FC<Props> = ({ handleOnClick, isSide = false }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const mri = useAppSelector(selectDossierChoices);
  const assureCoproprieteId = useAppSelector(selectNewCopro);
  const [loading, setLoading] = useState<boolean>(false);
  const { simulationId } = useAppSelector(
    (state: any) => state.mainSlice
  );

  const [nextStep, setNextStep] = useState<boolean>(false);

  const [sinistres, setSinistres] = useState<SinistresType>(() => {
    return {
      debutExercice:
        mri && mri.sinistres?.debutExercice
          ? mri.sinistres.debutExercice
          : moment().subtract(3, "y").format("YYYY-MM-DD"),
      finExercice:
        mri && mri.sinistres?.finExercice
          ? mri.sinistres.finExercice
          : moment().format("YYYY-MM-DD"),
      garanties:
        mri && mri.sinistres?.garanties
          ? mri.sinistres.garanties
          : [
            {
              nom: "Dégât des eaux",
              nombre: "",
              franchise: "",
              coutTotal: "",
            },
            {
              nom: "Incendie et risques divers",
              nombre: "",
              franchise: "",
              coutTotal: "",
            },
            {
              nom: "Dommages électriques",
              nombre: "",
              franchise: "",
              coutTotal: "",
            },
            {
              nom: "Tempête, neige, grêle",
              nombre: "",
              franchise: "",
              coutTotal: "",
            },
            {
              nom: "Cat. nat. et technologique",
              nombre: "",
              franchise: "",
              coutTotal: "",
            },
            {
              nom: "RC propriétaire immeuble",
              nombre: "",
              franchise: "",
              coutTotal: "",
            },
            {
              nom: "Choc de véhicule",
              nombre: "",
              franchise: "",
              coutTotal: "",
            },
            {
              nom: "Vol, vandalisme",
              nombre: "",
              franchise: "",
              coutTotal: "",
            },
            {
              nom: "Bris de glace",
              nombre: "",
              franchise: "",
              coutTotal: "",
            },
            {
              nom: "Bris de machine / Informatique",
              nombre: "",
              franchise: "",
              coutTotal: "",
            },
          ],
    };
  });

  const saveMRISimu = async () => {

    const updateCopro = async (coproId: string) => {
      setLoading(true);
      const savedData = parsingMRI(mri, simulationId, coproId, null);
       await updateMRISimulation(savedData).then((data) => {
      setLoading(false);
      dispatch(handleNext())
    }).catch(() => {
      setLoading(false);
    });
    }

    setLoading(true);
    const newCoproObj = {
      nom: "",
      superficie: mri?.surface,
      assureCopropriete: null,
      isAltitude: mri && mri?.address?.altitude > 1500 ? true : false,
      isConstructionMoinsDixAns: mri && mri?.constructionDate === "Moins de 10 ans" ? true : false,
      batiments: [],
      adresses: [
        {
          ligne1: mri && mri?.address?.address,
          codePostal: mri && mri?.address?.codePostal,
          ville: mri && mri?.address?.address.split(", ")[1],
          pays: "France",
          adresseFormatee: mri && mri?.address?.address,
          latitude: JSON.stringify(mri?.address.latitude),
          longitude: JSON.stringify(mri?.address.longitude)
        }
      ]
    }
    //TO-DO Ajouter une condition pour eviter de creer une copro si on l'a deja fait avant
    await createNewCopro(newCoproObj).then(async (res) => {
      setCoproId(res?.id);
      setNextStep(true);
      await updateCopro(res?.id);
    })
    setLoading(false);
  };

  // useEffect(() => {
    // if (nextStep === true && (assureCoproprieteId != null || assureCoproprieteId != undefined)) {
    //     updateCopro();
    // }
  // }, [assureCoproprieteId])

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index?: number
  ) => {
    const { name, value } = e.target;

    if (
      name.startsWith("nombre") ||
      name.startsWith("franchise") ||
      name.startsWith("coutTotal")
    ) {
      const updatedGaranties = [...sinistres.garanties];
      const field = name.split("-")[0];

      if (index !== undefined && field) {
        updatedGaranties[index] = {
          ...updatedGaranties[index],
          [field]: value,
        };

        const updatedSinistres = {
          ...sinistres,
          garanties: updatedGaranties,
        };

        setSinistres(() => {
          handleOnClick("sinistres", updatedSinistres);
          return updatedSinistres;
        });
      }
    } else {
      const updatedSinistres = {
        ...sinistres,
        [name]: value,
      };

      setSinistres(() => {
        handleOnClick("sinistres", updatedSinistres);
        return updatedSinistres;
      });
    }
  };

  const isFieldsValid = () => {
    return (
      debutExercice.trim() !== "" &&
      finExercice.trim() !== "" &&
      garanties.every((garantie) => {
        return (
          (garantie.nombre.trim() === "" || garantie.coutTotal.trim() !== "") &&
          (garantie.coutTotal.trim() === "" || garantie.nombre.trim() !== "")
        );
      })
    );
  };

  const skipStep = () => {
    saveMRISimu();
    handleOnClick("sinistres", sinistres);
  };

  const { debutExercice, finExercice, garanties } = sinistres;

  return (
    <div className="max-w1300 mg-0-auto ccc-body-step-2 h-90">
      <div className="w-100 text-center border-box radius-15 cccbs2r1 overflow-mri-form">
        <div className="mg-b50">
          <div>
            <p className="font-18 lh-22 text-dark cccbs2r1r1 font-w700">
              {!isSide ? "Veuillez renseigner le détail des garanties par sinistres" : "Détails des garanties par sinistres"}
            </p>
          </div>
        </div>
        <div className="d-flex flex-hor-center cccbs2r1r2">
          <div className="w-100 flex-hor-end d-flex mg-l20 mg-r20 flex-direction-col">
            <div
              className="d-flex flex-hor-end mg-b40"
              style={{ marginRight: "15px" }}
            >
              <div className="d-flex flex-direction-col">
                <label
                  className="text-dark-light w-100 mg-b5"
                  style={{ textAlign: "left" }}
                  htmlFor="debutExercice"
                >
                  Début d'exercice
                </label>
                <input
                  name="debutExercice"
                  value={debutExercice}
                  type="date"
                  onChange={(e) => handleChange(e)}
                  className="pr-db-input-bg"
                />
              </div>
              <div className="d-flex flex-direction-col">
                <label
                  className="text-dark-light w-100 mg-b5"
                  style={{ textAlign: "left" }}
                  htmlFor="finExercice"
                >
                  Fin d'exercice
                </label>
                <input
                  name="finExercice"
                  value={finExercice}
                  onChange={(e) => handleChange(e)}
                  className="pr-db-input-bg"
                  type="date"
                />
              </div>
            </div>
            <div className="">
              <table className="table-mri w-100">
                <thead>
                  <tr className="question-mri">
                    <th className="text-dark font-w700">Garantie intervenue</th>
                    <th className="text-dark font-w700">Nombre</th>
                    <th className="text-dark font-w700">Franchise</th>
                    <th className="text-dark font-w700">Coût total</th>
                  </tr>
                </thead>
                <tbody>
                  {garanties.map((garantie, index) => (
                    <tr className="text-blue font-w700 question-mri" key={index}>
                      <td>{garantie.nom}</td>
                      <td>
                        <input
                          name={`nombre-${index}`}
                          value={garantie.nombre}
                          className="pr-db-input-bg"
                          onChange={(e) => handleChange(e, index)}
                        />
                      </td>
                      <td>
                        <input
                          name={`franchise-${index}`}
                          value={garantie.franchise}
                          className="pr-db-input-bg"
                          onChange={(e) => handleChange(e, index)}
                        />
                      </td>
                      <td>
                        <input
                          name={`coutTotal-${index}`}
                          value={garantie.coutTotal}
                          className="pr-db-input-bg"
                          onChange={(e) => handleChange(e, index)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {
          isFieldsValid() && !isSide ? (

            <div className="cursor d-flex flex-hor-end mg-r20 mg-t20 w-100" onClick={skipStep}>
              <p className=" font-14 text-dark-light font-w700 mg-r10">
                Passer cette étape
              </p>
              <img src={nextArrow} alt="next" />
            </div>
          ) : null
        }
      </div>
        {
            <div className="d-flex flex-hor-end mg-t10">
                <button
                className="submitButton w-30"
                disabled={!isFieldsValid()}
                onClick={() => {
                    saveMRISimu()
                  }
              }
                >
                Étape suivante
                </button>
            </div>
        }
        <Loading loading={loading} isFullPage/>
    </div>
  );
};

export default StepFourteen;
