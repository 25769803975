import { useAppDispatch, useAppSelector } from "app/hooks"
import balloon from "assets/image/common/baloon.svg"
import { handleNext, selectDossierChoices } from "features/dossierSimuSlice";
import rightArrow from "assets/image/startStep/arrow.svg"
import { parseCBNO } from "helpers/parseObjetToDto";
import { getSimulationInfo } from "pages/mainStep/mainStepAPI";
import { updateCBNOSimulation } from "features/monBien/monBien";


const Eligible: React.FC = () => {

    const dispatch = useAppDispatch();
    const dossierChoices = useAppSelector(selectDossierChoices);
    const { simulationId} = useAppSelector(
        (state: any) => state.mainSlice
      );

    const goToTarificateur = async () => {
        const res = await getSimulationInfo(simulationId, "CBNO");
        const savedData = parseCBNO(dossierChoices, res.bienImmobilierId, simulationId);
        const response = await updateCBNOSimulation(savedData)
        if (response?.error?.message !== "Rejected")
            dispatch(handleNext());
    };

    return (
            <div className="eligible-container">
                <div className="text-center d-flex flex-direction-col flex-hor-center flex-ver-center">
                    <img src={balloon} className=""/>
                    <p className="paragraphe-principale font-w700 w-80 mg-t20 mg-b20">
                        Le bien est éligible à l'assurance propriétaire non-occupant
                    </p>
                    <p className="description w-80">
                        Vous allez pouvoir choisir vos garanties
                    </p>
                </div>
                <button className="button-orange" onClick={() => goToTarificateur()}>
                    <span className="font-w700">Obtenir une tarification</span>
                    <img
                        className="mg-l10"
                        alt="tarification-button"
                        src={rightArrow}
                    ></img>
                </button>
            </div>
    )
}

export default Eligible