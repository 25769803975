import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchCount } from "./contrat";
import {
  loginAPI,
  registerAPI,
  fetchGaranties,
  updateGaranties,
} from "./contrat";
import { Garantie, DataUploadGaranties } from "pages/mainStep/type";
export interface CounterState {
  value: number;
  status: "idle" | "loading" | "failed";
  loading: boolean;
  garantiesList: Garantie[];
}

const initialState: CounterState = {
  value: 0,
  status: "idle",
  loading: false,
  garantiesList: [],
};

export const incrementAsync = createAsyncThunk(
  "counter/fetchCount",
  async (amount: number) => {
    const response = await fetchCount(amount);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const loginAction: any = createAsyncThunk(
  "LOGIN_ACTION",
  async (data, { rejectWithValue }) => {
    try {
      const res: any = await loginAPI(data);
      return res?.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const registerAction: any = createAsyncThunk(
  "REGISTER_ACTION",
  async (data, { rejectWithValue }) => {
    try {
      const res: any = await registerAPI(data);
      return res?.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const fetchGarantiesAction: any = createAsyncThunk(
  "FETCH_GARANTIES",
  async (param: {id: string, franchises:[], plafonds:[]}, { rejectWithValue }) => {
    try {
      const res: any = await fetchGaranties(param.id, param.franchises, param.plafonds);
      return res?.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const updateGarantiesAction: any = createAsyncThunk(
  "UPDATE_GARANTIES",
  async (data: DataUploadGaranties, { rejectWithValue }) => {
    try {
      const res: any = await updateGaranties(data);
      return res?.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const contratSlice = createSlice({
  name: "contrat",
  initialState,
  reducers: {
    resetContratStore(state) {
      state = initialState;
    }
  },

  extraReducers: {
    [loginAction.pending]: (state) => {
      state.loading = true;
    },
    [loginAction.rejected]: (state, action) => {
      state.loading = false;
    },
    [loginAction.fulfilled]: (state, action) => {
      state.loading = false;
    },

    [registerAction.pending]: (state) => {
      state.loading = true;
    },
    [registerAction.rejected]: (state, action) => {
      state.loading = false;
    },
    [registerAction.fulfilled]: (state, action) => {
      state.loading = false;
    },

    [fetchGarantiesAction.pending]: (state) => {
      state.loading = true;
    },
    [fetchGarantiesAction.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchGarantiesAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.garantiesList = action?.payload || [];
    },
  },
});

export const {resetContratStore} = contratSlice.actions;

export default contratSlice.reducer;
