import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { garantiesRetenues, beginSimulation, simulationInfo, workflow, createDevis, connectSimulationApp, getCourtierBySubDomain } from "./mainStepAPI";
import { routeList, MONBIEN1, MONBIEN2 } from "./routeList";

const initialState = {
  startData: {},
  auth: false,
  accessToken: "",
  money: 0,
  step: "",
  infoUser: null,
  numberFamily: 0,
  listFamily: [],
  stepTwoKey: "",
  indexFamily: 0,
  familyStep: 0,
  stepThreeKey: "",
  simulation: null,
  garantiesRetenues: null,
  renderStepStartPageTwo: false,
  renderStepTwoPageFour: false,
  renderStepThreePageThree: false,
  renderStepFourPageTwo: false,
  renderInfoFour: false,
  loading: false,
  simulationId: "",
  dossierLocationId: "",
  routeList: [],
  first: true,
  devisId: null,
  courtierId: null,
  bienId: null,
};

export const connectSimulationAppAction = createAsyncThunk(
  "CONNECT_SIMULATION_APP",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const res: any = await connectSimulationApp();
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const getCourtierBySubDomainAction = createAsyncThunk(
  "GET_COURTIER_BY_SUBDOMAIN",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const subDomain = window.location.host.split(".")[0];
      const res: any = await getCourtierBySubDomain(subDomain);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const beginSimulationAction = createAsyncThunk(
  "GET_GLI_ID",
  async (data: {natureCode: string, courtierId: string}, { rejectWithValue, dispatch }) => {
    try {
      const res: any = await beginSimulation(data.natureCode, data.courtierId);
      dispatch(getWorkflow(res?.data?.tunnelId));
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const getWorkflow = createAsyncThunk(
  "GET_WORK_FLOW",
  async (data: string, { rejectWithValue, dispatch }) => {
    try {
      const res: any = await workflow(data);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const getSimulationData = createAsyncThunk(
  "GET_SIMU_DATA",
  async (data: string, { rejectWithValue, dispatch }) => {
    try {
      const res: any = await simulationInfo(data);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);


export const getGarantiesRetenues = createAsyncThunk(
  "GET_GARANTIES_DATA",
  async (data: {simulationId: string, tunnelId: string, loyerMensuel: number}, { rejectWithValue, dispatch }) => {
    try {
      const res: any = await garantiesRetenues(data);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const createDevisAction = createAsyncThunk(
  "CREATE_DEVIS",
  async (params: {simulationId: string, assureId: string | undefined ,contratParentId: string | undefined}, { rejectWithValue, dispatch }) => {
    try {
      const res: any = await createDevis(params);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

const main = createSlice({
  name: "main",
  initialState,
  reducers: {
    setMoneyAction(state, action) {
      state.money = action.payload;
    },
    setRenderStepStartPageTwo(state, action) {
      state.renderStepStartPageTwo = action.payload;
    },
    setRenderStepTwoPageFour(state, action) {
      state.renderStepTwoPageFour = action.payload;
    },
    setRenderStepThreePageThree(state, action) {
      state.renderStepThreePageThree = action.payload;
    },
    setRenderStepFourPageTwo(state, action) {
      state.renderStepFourPageTwo = action.payload;
    },
    setRenderInfoFour(state, action) {
      state.renderInfoFour = action.payload;
    },
    setIndexFamily(state, action) {
      state.indexFamily = action.payload;
    },
    setInfoUserAction(state, action) {
      state.infoUser = action.payload;
    },
    setFamilyAction(state, action) {
      state.numberFamily = action.payload?.length;
      state.listFamily = action.payload;
    },
    setAuthAction(state) {
      state.auth = true;
    },
    setInfoStepFourPageOne(state, action) {
      // state.infoStepFourPageOne = action.payload;
    },
    setInfoStepFourPageTwo(state, action) {
      // state.infoStepFourPageTwo = action.payload;
    },
    setInfoStepThreePageOne(state, action) {
      // state.infoStepThreePageOne = action.payload;
    },
    setInfoStepThreePageTwo(state, action) {
      // state.infoStepThreePageTwo = action.payload;
    },
    setInfoStepThreePageThree(state, action) {
      // state.infoStepThreePageThree = action.payload;
    },
    setFirst(state, action) {
      state.first = action.payload;
    },
    setRouteList(state, action) {
      state.routeList = action.payload;
    },
    setBienId(state, action) {
      state.bienId = action.payload
    },
    resetMainStore(state) {
      state = initialState;
    }
  },

  extraReducers(builder) {
    builder
      .addCase(getCourtierBySubDomainAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCourtierBySubDomainAction.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCourtierBySubDomainAction.fulfilled, (state, action) => {
        state.loading = false;
        state.courtierId = action.payload.id;
      })
      .addCase(connectSimulationAppAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(connectSimulationAppAction.rejected, (state) => {
        state.loading = false;
        state.auth = false;
      })
      .addCase(connectSimulationAppAction.fulfilled, (state, action) => {
        state.loading = false;
        state.accessToken = action.payload.accessToken;
      })
      .addCase(beginSimulationAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(beginSimulationAction.rejected, (state) => {
        state.loading = false;
      })
      .addCase(beginSimulationAction.fulfilled, (state, action) => {
        state.loading = false;
        state.simulationId = action.payload?.simulationId;
        state.dossierLocationId = action.payload?.dossierLocationId;
      })
      .addCase(getSimulationData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSimulationData.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getSimulationData.fulfilled, (state, action) => {
        state.loading = false;
        state.simulation = action?.payload;
      })
      .addCase(getGarantiesRetenues.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGarantiesRetenues.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getGarantiesRetenues.fulfilled, (state, action) => {
        state.loading = false;
        state.garantiesRetenues = action?.payload;
      })
      .addCase(getWorkflow.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWorkflow.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getWorkflow.fulfilled, (state, action) => {
        state.loading = false;
        const workflow = action?.payload;
        const newRouteList: any = [];

        workflow.forEach((x: any, index: number) => {
          const newRouteListCode = newRouteList?.map((x: any) => x?.code);
          if (
            x?.etapeWorkflow?.code === "ESR" ||
            x?.etapeWorkflow?.code === "DEFBIEN"
          ) {
            const newRouteListCodeInCode = newRouteList?.map(
              (x: any) => x?.code
            );

            const find = newRouteListCodeInCode?.filter((y: any) =>
              y?.includes(x?.etapeWorkflow?.code)
            );

            if (find?.length === 0) {
              newRouteList.push({
                list: [x?.etapeWorkflow?.code === "ESR" ? MONBIEN1 : MONBIEN2],
                code: [x?.etapeWorkflow?.code],
                label: routeList[0]?.label,
                value: routeList[0]?.value,
              });
            } else {
              const route = routeList?.find(
                (y: any) => y?.code === x?.etapeWorkflow?.code
              );

              newRouteList.push({
                ...route,
                code: x?.etapeWorkflow?.code,
                label: routeList[0]?.label,
                value: routeList[0]?.value,
                list: [
                  `${
                    x?.etapeWorkflow?.code === "ESR" ? MONBIEN1 : MONBIEN2
                  }/plus/${index}`,
                ],
              });
            }
          } else {
            if (!newRouteListCode?.includes(x?.etapeWorkflow?.code)) {
              newRouteList.push(
                routeList?.find((y: any) => y?.code === x?.etapeWorkflow?.code)
              );
            } else {
              const route = routeList?.find(
                (y: any) => y?.code === x?.etapeWorkflow?.code
              );

              newRouteList.push({
                ...route,
                list: route?.list?.map((x) => `${x}/plus/${index}`),
              });
            }
          }
        });

        newRouteList.push(routeList[5]);
        state.routeList = newRouteList?.map((x: any, index: number) => ({
          ...x,
          key: index + 1,
        }));
        state.first = true;
      })
      .addCase(createDevisAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(createDevisAction.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createDevisAction.fulfilled, (state, action) => {
        state.loading = false;
        state.devisId = action.payload?.id;
      });
  },
});

export const {
  setMoneyAction,
  setInfoUserAction,
  setFamilyAction,
  setIndexFamily,
  setAuthAction,
  setRenderStepTwoPageFour,
  setRenderStepThreePageThree,
  setRenderStepFourPageTwo,
  setRenderStepStartPageTwo,
  setInfoStepFourPageOne,
  setInfoStepFourPageTwo,
  setInfoStepThreePageOne,
  setInfoStepThreePageTwo,
  setInfoStepThreePageThree,
  setRenderInfoFour,
  setFirst,
  setRouteList,
  setBienId,
  resetMainStore
} = main.actions;

const { reducer: mainSlice } = main;

export default mainSlice;
