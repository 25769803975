import publicAPIInstance from "pages/connexion/publicProxy";
import { DataUploadGaranties } from "pages/mainStep/type";

// A mock function to mimic making an async request for data
export function fetchCount(amount = 1) {
  return new Promise<{ data: number }>((resolve) =>
    setTimeout(() => resolve({ data: amount }), 500)
  );
}

export function loginAPI(data: any) {
  return new Promise((resolve, reject) => {
    try {
      const res = publicAPIInstance.post(`api/account/login`, data);
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export function registerAPI(data: any) {
  return new Promise((resolve, reject) => {
    try {
      const res = publicAPIInstance.post(`api/account/register`, data);
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export function fetchGaranties(id: string, franchises:[], plafonds: []) {
  return new Promise((resolve, reject) => {
    try {
      let franchisesUrl = "";
      if (typeof franchises != 'undefined') {
        for(const item of franchises){
          franchisesUrl += `&Franchises[${item[0]}]=${item[1]}`;
        }
      }
      
      let plafondsUrl = "";
      if (typeof plafonds != 'undefined') {
        for(const item of plafonds){
          plafondsUrl += `&Plafonds[${item[0]}]=${item[1]}`;
        }
      }
      
      const res = publicAPIInstance.get(
        `api/contrat-configurator/${id}/configurer?IsContratIndividuel=true${franchisesUrl}${plafondsUrl}`
      );
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export function updateGaranties(data: DataUploadGaranties) {
  return new Promise((resolve, reject) => {
    try {
      const res = publicAPIInstance.post(
        `api/contrat-configurator/${data?.simulationId}/configurer`,
        data?.data
      );
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}
