import { useAppDispatch } from "app/hooks";
import {  setShowHeader } from "features/dossierSimuSlice";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react"
import { IGarantie } from "pages/mainStep/type";
import defaultGarantie from "assets/image/common/cementery.svg"
import edit from "assets/image/infoStepFour/edit.png"
import EditGarantie from "../modal/editGarantie";
import { Unite } from "helpers/getUnite";
import { useIsSmallScreen } from "hooks/isSmallScreen";
import { idText } from "typescript";


type Props = {
    setModal: (value:boolean) => void;
    garanties: IGarantie[];
    garantiesRetenues: string[];
    handleGaranties: (value: IGarantie) => void;
    handleSelect: (value:any) => void;
    listImage: any;
    setContent: (content: ReactNode) => void;
    submit: () => void;
}

const ChoiceGarantie: React.FC<Props> = ({setModal, garanties, garantiesRetenues, handleSelect, listImage, setContent, handleGaranties, submit}) => {

    const dispatch = useAppDispatch();
    const [isPriceFixed, setIsPriceFixer] = useState<boolean>(true);
    const myDivRef = useRef<HTMLDivElement>(null);
    const isSmallScreen = useIsSmallScreen(567);

    const total = useMemo(() => {
      const garantiesSelected = garanties.filter((current) => garantiesRetenues.includes(current.id));
      return (
        garantiesSelected?.reduce(
          (total, item) => total + item?.price,
          0
        ) || 0
      )
    }, [garantiesRetenues, garanties])

    useEffect(() => {
      //Ce use Effect permet de faire disparaitre la partie fixed affichant le prix a partir du moment ou le bandeau bleu apparait
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1, // Le pourcentage de visibilité nécessaire pour déclencher l'observation
      };
  
      const handleIntersection = (entries:any) => {
        const isIntersecting = entries[0].isIntersecting;
        setIsPriceFixer(!isIntersecting);
      };
  
      const observer = new IntersectionObserver(handleIntersection, options);
      if (myDivRef?.current)
        observer.observe(myDivRef.current);
      return () => {
        observer.disconnect();
      };
    }, []);


    const isRetenue = (id:string) => {
        const garantie = garantiesRetenues?.find((garantie:any) => garantie == id)
        return (garantie ? true : false);
    }

    const openModal = (type:string, x:IGarantie, index: number, index2?: number) => {
      if (type == "editGarantie")
      {
        setContent(<EditGarantie data={x} onCancel={setModal} handleGaranties={handleGaranties}></EditGarantie>)
      }
      else {
        setContent(
          <div className="w-100 d-flex h-100 flex-direction-col overflow-auto flex-ver-center">
            {
              index2 != null ?
              <img src={listImage[index].enfants[index2] != "" && listImage[index].enfants[index2] != null ? listImage[index].enfants[index2] : defaultGarantie} alt="icon" className="mg-r25" loading="lazy"
                style={{
                  width: "80px",
                  height: "51.5px"
                }}
              />
              :
              <img src={listImage?.length > index && listImage[index].garantie != "" && listImage[index].garantie != null ? listImage[index].garantie : defaultGarantie} alt="icon" className="mg-r25" loading="lazy"
                style={{
                  width: "80px",
                  height: "51.5px"
                }}
                />
              }
              <p className="text-dark font-16 font-w700">{x?.descriptionCourte}</p>
            <div dangerouslySetInnerHTML={{__html: x.descriptionLongue}} style={{textAlign: "left", margin: "20px"}}></div>
          </div>
        );
      }
      dispatch(setShowHeader(false));
      setModal(true);
    }

    return (
        <div className="d-flex flex-direction-col choice-garantie">
            <h2 className="mg-b30 mg-t20 mg-l10 mg-r10">Vos garanties assurances</h2>
            <p className="text-dark-light mg-b30 mg-l10 mg-r10">Construisez votre contrat d'assurance sur mesure en selectionnant les garanties dont vous avez besoin</p>
                {
                    garanties?.map((x: IGarantie, index:number) => {
                        return (
                          <>
                            <div
                            className={`mg-b25 contratItem ${isRetenue(x.id) || x.isRequired === true ? "activeItem" : ""}`}
                            key={index}
                          >
                            <div
                              className="d-flex flex-hor-center flex-ver-center cursor questionIcon"
                              onClick={() => openModal("description", x, index)}
                            >
                              <p className="font-18">?</p>
                            </div>
                            <div className="ci-body">
                              <div className="d-flex flex-ver-center flex-hor-start mg-b17 cibr1">
                                <img src={listImage?.length > index && listImage[index].garantie != "" && listImage[index].garantie != null ? listImage[index].garantie : defaultGarantie} alt="icon" className="mg-r25" loading="lazy"/>
                                <div>
                                  <p className="font-14 lh-22">Garantie {x.isRequired === true ? "Obligatoire" : "Optionnelle"}</p>
                                  <span className="font-18 lh-22">{x?.designation}</span>
                                </div>
                              </div>
                              <div className="d-flex flex-hor-between flex-ver-center cibr4">
                                <div className="cibr4c1">
                                  <p className="font-20 lh-24 main-color">
                                    {(x?.price)?.toFixed(2) || 0} € TTC par an
                                  </p>
                                  {/* <span className="font-13 lh-24">
                                    par an (soit {(x?.price / 12)?.toFixed(2)} € TTC / mois)
                                  </span> */}
                                </div>
                                <div className="d-flex flex-ver-center cibr4c2 mg-l10">
                                  <p className="mg-r15 font-12 lh-15">
                                    {isRetenue(x.id) || x.isRequired === true ? "Couvert" : "Non couvert"}
                                  </p>
                                  {
                                    x.isRequired === false && (
                                      <div
                                        className="cursor cibr4c2-switch"
                                        onClick={() => handleSelect(x)}
                                      >
                                        <span className="cibr4c2w-circle"></span>
                                      </div>
                                    )
                                  }
                                 
                                </div>
                              </div>
                            </div>

                            {(x?.franchises.length > 0  || x?.plafonds.length > 0 ) && (
                              <div className="w-100 d-flex ci-footer">
                                <div
                                  className={`pad-20 d-flex flex-ver-center flex-hor-between cifc1 ${
                                    "w-100"
                                  } ${index % 2 === 0 ? "cifc2" : "cifc1"}`}
                                >
                                  {x.franchises.length > 0 && (<div className="d-flex flex-direction-col flex-ver-top cifc1c1">
                                    <p className="font-10">FRANCHISE</p>
                                    <span className="font-15">
                                      {x.franchises[0].duree.value} {Unite[x?.franchises[0].duree.unite - 1]}
                                    </span>
                                  </div>)}
                
                                  {x?.plafonds.length > 0 && (<div className="d-flex flex-direction-col flex-ver-top cifc1c1">
                                    <p className="font-10">PLAFOND</p>
                                    <span className="font-15">
                                      {x.plafonds[0].duree.value || '-'} {Unite[x?.plafonds[0].duree.unite - 1]}
                                    </span>
                                  </div>)}
                                  
                                  <div
                                    className="d-flex flex-hor-center flex-ver-center cursor cifc2c2"
                                    onClick={() => openModal("editGarantie", x, index)}
                                  >
                                    <img src={edit} alt="edit" />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          {
                            x?.enfants?.map((enfants: any, index2: number) => {
                              return (
                                <>
                          <div
                            className={`mg-b25 contratItem ${isRetenue(enfants?.id) ? "activeItem" : ""}`}
                            key={index}
                            style={{marginLeft: "50px"}}
                          >
                            <div
                              className=" d-flex flex-hor-center flex-ver-center cursor questionIcon"
                              onClick={() => openModal("description", enfants, index, index2)}
                            >
                              <p className="font-18">?</p>
                            </div>
                            <div className="ci-body">
                              <div className="d-flex flex-ver-center flex-hor-start mg-b17 cibr1">
                                <img src={listImage[index].enfants[index2] != "" && listImage[index].enfants[index2] != null ? listImage[index].enfants[index2] : defaultGarantie} alt="icon" className="mg-r25" loading="lazy"/>
                                <div>
                                  <p className={`font-14 lh-22 ${isSmallScreen === true ? "w-80" : "w-100"}`}>Extension de garantie de {x.designation}</p> 
                                  <span className="font-18 lh-22">{enfants?.designation}</span>
                                </div>
                              </div>
                              <div className="d-flex flex-hor-between flex-ver-center cibr4">
                                <div className="cibr4c1">
                                  <p className="font-20 lh-24 main-color">
                                    {(enfants?.price)?.toFixed(2) || 0} € TTC par an
                                  </p>
                                  {/* <span className="font-13 lh-24">
                                    par an (soit {(x?.price / 12)?.toFixed(2)} € TTC / mois)
                                  </span> */}
                                </div>
                                <div className="d-flex flex-ver-center cibr4c2 mg-l10">
                                  <p className="mg-r15 font-12 lh-15">
                                    {isRetenue(enfants.id) ? "Couvert" : "Non couvert"}
                                  </p>
                                  {
                                      <div
                                        className="cursor cibr4c2-switch"
                                        onClick={() => handleSelect(enfants)}
                                      >
                                        <span className="cibr4c2w-circle"></span>
                                      </div>
                                  }
                                 
                                </div>
                              </div>
                            </div>

                            {(enfants?.franchises.length > 0  || enfants?.plafonds.length > 0 ) && (
                              <div className="w-100 d-flex ci-footer">
                                <div
                                  className={`pad-15 d-flex flex-ver-center flex-hor-between cifc1 ${
                                    "w-100"
                                  } ${index % 2 === 0 ? "cifc2" : "cifc1"}`}
                                >
                                  {enfants.franchises.length > 0 && (<div className="d-flex flex-direction-col flex-ver-top cifc1c1">
                                    <p className="font-10">FRANCHISE</p>
                                    <span className="font-15">
                                      {enfants.franchises[0].duree.value} {Unite[enfants?.franchises[0].duree.unite - 1]}
                                    </span>
                                  </div>)}
                
                                  {enfants?.plafonds.length > 0 && (<div className="d-flex flex-direction-col flex-ver-top cifc1c1">
                                    <p className="font-10">PLAFOND</p>
                                    <span className="font-15">
                                      {enfants.plafonds[0].duree.value || '-'} {Unite[enfants?.plafonds[0].duree.unite - 1]}
                                    </span>
                                  </div>)}
                                  
                                  <div
                                    className="d-flex flex-hor-center flex-ver-center cursor cifc2c2"
                                    onClick={() => openModal("editGarantie", x, index)}
                                  >
                                    <img src={edit} alt="edit" />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                                </>
                                )
                            })
                          }
                          </>
                        )
                    })
                }
                  <div className="w-100 d-flex flex-direction-col flex-ver-center contratStepOneFooter mg-t20" ref={myDivRef}>
                    <div className="d-flex flex-hor-between flex-ver-center mg-b7 cttfr1 w-90 mg-t10">
                      <p className="font-16 lh-19 text-white cttfr1c1">
                        Montant total de votre prime
                      </p>
                      <div className="d-flex flex-direction-col flex-ver-center cttfr1c2">
                        <div>
                          <p className="font-13 lh-18 text-white">Votre assurance</p>
                          <h6 className="font-20 lh-24 text-white">
                            {(total)?.toFixed(2) || 0} € TTC <span className="font-14 lh-24">/ an</span>
                          </h6>
                        </div>
                        {/* <p className="font-14 lh-24 text-white">soit {(total / 12)?.toFixed(2) || 0}€ TTC /mois</p> */}
                      </div>
                    </div>
                    <button
                      disabled={garantiesRetenues.length < 0}
                      className="submitButton cttfr2 w-90"
                      onClick={() => submit()}
                    >
                      Je valide mes garanties
                      {/* <img src={arrow} alt="loading"></img> */}
                    </button>
                    <p className="font-13 lh-19 mg-b5 cttfr3">
                      Des questions ? Appelez-nous.
                    </p>
                    <div className="d-flex flex-ver-center mg-b7 cttfr4">
                      {/* <img src={phone} alt="phone" className="mg-r8" /> */}
                      <p className="mg-r8">0900 752 857</p>
                      <h6>du lundi au vendredi de 9h à 17h30</h6>
                    </div>
                    <p className="font-9 lh-19 cttfr5">
                      *prix d’un appel local + frais éventuels de votre opérateur
                    </p>
                    <div className={`${isPriceFixed ? "d-flex flex-direction-col flex-ver-center contratStepOneFixed" : "d-none"}`}>
                      <div>
                        <p className="font-13 lh-18 text-white">Votre assurance</p>
                        <h6 className="font-20 lh-24 text-white">
                          {(total )?.toFixed(2) || 0} € TTC <span className="font-14 lh-24">/ an</span>
                        </h6>
                      </div>
                    </div>
                </div>
            </div>
    )
}

export default ChoiceGarantie