import { Form } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setFamilyAction } from "pages/mainStep/mainSlice";
import clock from "assets/image/stepTwo/clock.svg";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { Civilites } from "pages/mainStep/type";
import { LOCATAIRES3STEP2 } from "pages/mainStep/routeList";
import { handleNextStep } from "helpers/handleNextStep";
interface FormType {
  email: string;
  name: string;
  phone: string;
  subName: string;
}

const ItemOne = () => {
  const dispatch = useAppDispatch();

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { listFamily, indexFamily, routeList } = useAppSelector(
    (state: any) => state?.mainSlice
  );

  const { civilites } = useAppSelector((state) => state?.locatairesSlice) || [];

  const [gender, setGender] = useState<Civilites>();

  useEffect(() => {
    if (listFamily?.length > 0 && indexFamily >= 0) {
      form.setFieldsValue(listFamily?.[indexFamily]?.step1);
      if (!listFamily?.[indexFamily]?.step1?.civilite) {
        setGender(civilites?.[0]);
      } else {
        setGender(listFamily?.[indexFamily]?.step1?.civilite);
      }
    }
  }, [civilites, civilites.length, form, indexFamily, listFamily]);

  const handleSubmit = (form: FormType) => {
    const newListFamily = [...listFamily];
    newListFamily[indexFamily] = {
      ...newListFamily[indexFamily],
      step1: { ...form, civilite: gender },
    };
    dispatch(setFamilyAction(newListFamily));

    navigate(handleNextStep(routeList));
  };

  return (
    <div id="itemOne">
      <h2>Votre locataire</h2>
      <Form form={form} onFinish={handleSubmit}>
        <div className="inputGroup">
          <div className="formItem">
            <p>Civilité</p>
            <ul className="w-100 d-flex mg-t10 radioGroup">
              {civilites?.map((x: Civilites, index: number) => (
                <li
                  key={index}
                  className={`w-50 font-14 radius-4 text-center main-color cursor mg-r10 ${
                    gender?.id === x?.id ? "activeLi" : ""
                  }`}
                  onClick={() => setGender(x)}
                >
                  {x?.libelle}
                </li>
              ))}
            </ul>
          </div>
          <div className="formItem">
            <p>Nom de famille du locataire</p>
            <Form.Item
              name="name"
              rules={[{ required: true, message: "Please input your name!" }]}
            >
              <input placeholder="Nom du locataire" className="input"></input>
            </Form.Item>
          </div>
          <div className="formItem">
            <p>Prénom</p>
            <Form.Item
              name="subName"
              rules={[
                { required: true, message: "Please input your sub name!" },
              ]}
            >
              <input placeholder="Son prenom" className="input"></input>
            </Form.Item>
          </div>
          <div className="formItem">
            <p>Numéro de téléphone</p>
            <Form.Item
              name="phone"
              rules={[{ required: true, message: "Please input your phone!" }]}
            >
              <input
                placeholder="Son numéro de téléphone mobile"
                className="input"
                type="input"
                onKeyDown={(e: any) =>
                  (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()
                }
              ></input>
            </Form.Item>
          </div>
          <div className="formItem">
            <p>Adresse email</p>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
              ]}
            >
              <input placeholder="Son adresse email" className="input"></input>
            </Form.Item>
          </div>
        </div>
        <div className="buttonGroup">
          <button type="submit" className="submitButton">
            Étape suivant
          </button>
          <div className="footerButton">
            <img src={clock} alt="loading"></img>
            <p>Sauvegarder mon dossier et finir plus tard</p>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ItemOne;
