import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import arrow from "assets/images/icons/dashboard/arrow-left-black.svg";
import BuildingTabs from "./buildingTabs";
import { BuildingState, Building } from "./types";
import { handleNext, selectDossierChoices } from "features/dossierSimuSlice";
import CustomRangeInput from "features/components/customRange/customRange";

type Props = {
  handleOnClick: (
    fieldName: string,
    value: boolean | string | any
  ) => void;
  isSide?: boolean;
};

const generateInitialState = (
  numBuildings: number,
  mri: any
): BuildingState => {
  const initialState: BuildingState = {
    buildings: [],
  };

  for (let i = 1; i <= numBuildings; i++) {
    const existingBuilding =
      mri &&
      mri.buildings &&
      mri.buildings.buildings &&
      mri.buildings.buildings.find((building: Building) => building.id === i);

    const building: Building = {
      id: i,
      floors: existingBuilding ? existingBuilding.floors : "",
      basements: existingBuilding ? existingBuilding.basements : "",
      fields: [
        {
          fieldName: "buildingOccupancyPercent",
          title: `Quelle est le pourcentage d'occupation du bâtiment ?`,
          options: ["Plus de 50%", "Moins de 50%", "Totalement vide"],
          selectedOption: existingBuilding
            ? existingBuilding.fields[0].selectedOption
            : "",
        },
        {
          fieldName: "buildingDestiny",
          title: `Est-il destiné à la réhabilitation ou à la démolition ?`,
          options: ["Réhabilitation", "Démolition", "Aucun des deux"],
          selectedOption: existingBuilding
            ? existingBuilding.fields[1].selectedOption
            : "",
        },
      ],
    };

    if (numBuildings > 1) {
      building.fields.push({
        fieldName: "lessThan10mFromOthers",
        title: `Ce bâtiment est-il à moins de 10m des autres ?`,
        options: generateOptions(i, numBuildings),
        selectedOption:
          existingBuilding && existingBuilding.fields[2]?.selectedOption
            ? existingBuilding.fields[2].selectedOption
            : "",
      });
      building.fields.push({
        fieldName: "basementsCommunication",
        title:
          "Ce bâtiment communique-t-il par les sous-sols avec les autres ?",
        options: generateOptions(i, numBuildings),
        selectedOption:
          existingBuilding && existingBuilding.fields[3]?.selectedOption
            ? existingBuilding.fields[3].selectedOption
            : "",
      });
      building.fields.push({
        fieldName: "fireDoors",
        title: "Communique-t-il via des portes coupe-feu avec les autres ?",
        options: generateOptions(i, numBuildings),
        selectedOption:
          existingBuilding && existingBuilding.fields[4]?.selectedOption
            ? existingBuilding.fields[4].selectedOption
            : "",
      });
    }

    initialState.buildings.push(building);
  }

  return initialState;
};

const generateOptions = (
  currentBuildingId: number,
  totalBuildings: number
): string[] => {
  const options = [];

  for (let i = 1; i <= totalBuildings; i++) {
    if (i !== currentBuildingId) {
      options.push(`Oui, du bâtiment ${i}`);
    }
  }

  options.push("Non, aucun");
  return options;
};

const StepTwelve: React.FC<Props> = ({
  handleOnClick,
  isSide = false
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const mri = useAppSelector(selectDossierChoices);
  const [part, setPart] = useState<number>(1);
  const [numberBuildings, setNbBuildings] = useState<number>(
    mri && mri.numberBuildings ? mri.numberBuildings : 1
  );
  const [buildings, setBuildings] = useState<BuildingState>(
    mri && mri.buildings
      ? mri.buildings
      : generateInitialState(numberBuildings, mri)
  );

  const performGeneration = () => {
    const initialState = generateInitialState(numberBuildings, mri);
    setBuildings(initialState);
    setBuildings((prevInitial) => {
      handleOnClick("buildings", prevInitial);
      return initialState;
    });
  };

  useEffect(() => {
    performGeneration();
  }, [numberBuildings]);

  useEffect(() => {
    if (isSide)
      setPart(2)
  }, [isSide])

  const handleAnswer = (
    buildingId: number,
    fieldName: string,
    selectedOption: string
  ) => {
    setBuildings((prevBuildings) => {
      const updatedBuildings = prevBuildings.buildings.map((building) => {
        if (building.id === buildingId) {
          const updatedFields = building.fields.map((field) => {
            if (field.fieldName === fieldName) {
              return { ...field, selectedOption };
            }
            return field;
          });

          return {
            ...building,
            fields: updatedFields,
            [fieldName]: selectedOption,
          };
        }
        return building;
      });

      const newState = { ...prevBuildings, buildings: updatedBuildings };

      handleOnClick("buildings", newState);

      return newState;
    });
  };

  const handleDecrease = () => {
    if (numberBuildings > 1) {
      setNbBuildings(numberBuildings - 1);
      setNbBuildings((prevCount) => {
        handleOnClick("numberBuildings", prevCount);
        return numberBuildings - 1;
      });
    }
  };

  const handleIncrease = () => {
    setNbBuildings(numberBuildings + 1);
    setNbBuildings((prevCount) => {
      handleOnClick("numberBuildings", prevCount);
      return numberBuildings + 1;
    });
  };

  const displayCurrentPage = () => (
    <div className="max-w1300 mg-0-auto ccc-body-step-2">
      <div className="w-100 text-center border-box radius-15 cccbs2r1">
        <div className="mg-b50">
          <div className="mg-b20 mg-t20">
            <p className="font-18 lh-22 text-dark cccbs2r1r1">
              <span className="font-w700">Combien y'a-t-il de bâtiments</span>{" "}
              dans la copropriété ?
            </p>
            <p className="font-14 text-dark-light mg-t15 mg-b20">Description</p>
          </div>
        </div>
        <div
          className="d-flex flex-hor-center cccbs2r1r2 mg-30"
          style={{ margin: 30 }}
        >
          <CustomRangeInput
            value={numberBuildings}
            onDecrease={handleDecrease}
            onIncrease={handleIncrease}
            max={5}
          />
        </div>
      </div>
      {
            <div className="d-flex flex-hor-end mg-t10">
              <button
                className="submitButton w-30"
                disabled={!numberBuildings}
                onClick={() => setPart(2)}
              >
                Étape suivante
              </button>
            </div>
        }
    </div>
  );

  return (
    <>
      {(() => {
        switch (part) {
          case 1:
            return displayCurrentPage();
          case 2:
            return (
              <BuildingTabs
                handleOnClick={handleOnClick}
                setBuilding={setNbBuildings}
                handleAnswer={handleAnswer}
                buildings={buildings}
                isSide={isSide}
              />
            );
          default:
            return displayCurrentPage();
        }
      })()}
    </>
  );
};

export default StepTwelve;
