import { useLocation, useNavigate } from "react-router-dom";
import prevArrow from "assets/image/startStep/prevArrow.svg";
import check from "../../assets/image/mainStep/check.svg";
import { useDispatch, useSelector } from "react-redux";
import { setIndexFamily, setRenderInfoFour } from "pages/mainStep/mainSlice";
import { useEffect, useMemo, useState } from "react";
import logoBlur from "assets/image/common/logo-blur.svg";
import { RouteList } from "pages/mainStep/type";
import {
  LOCATAIRES1,
  LOCATAIRES2,
  LOCATAIRES3,
  LOCATAIRES4,
  LOCATAIRES3STEP1,
  LOCATAIRES3STEP4,
  LOCATAIRES_LABEL,
  LOCATAIRES3STEP5,
} from "pages/mainStep/routeList";
import { useIsSmallScreen } from "hooks/isSmallScreen";

const HeaderDesktop = ({ showHeaderDesktop, style }: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { indexFamily, routeList, listFamily } = useSelector(
    (state: any) => state.mainSlice
  );

  const [showPrev, setShowPrev] = useState(true);
  const isSmallScreen = useIsSmallScreen(567);

  const handleBackPage = () => {
    const isGarant =
      listFamily?.[indexFamily]?.step2?.children?.peutEtreAttribueGarant ||
      listFamily?.[indexFamily]?.step2?.parent?.peutEtreAttribueGarant;
    let current: any = routeList?.find((x: any) =>
      x?.list?.includes(location.pathname)
    );
    if (!isGarant) {
      const newList = current?.list?.filter(
        (x: string) =>
          !x?.includes(LOCATAIRES3STEP4) && !x?.includes(LOCATAIRES3STEP5)
      );
      current = { ...current, list: newList };
    }
    const routeListKey: any = routeList?.map((x: RouteList) => x?.key);
    const indexTarget = routeListKey?.indexOf(current?.key);
    if (current) {
      if (current?.list) {
        if (location.pathname === LOCATAIRES3STEP1) {
          if (indexFamily === 0) {
            navigate(LOCATAIRES2);
          } else {
            dispatch(setIndexFamily(indexFamily - 1));
            dispatch(setRenderInfoFour(true));
            navigate(LOCATAIRES3STEP4);
          }
        } else {
          const indexOfRoute = current?.list?.indexOf(location.pathname);
          if (current?.list?.[indexOfRoute - 1]) {
            navigate(current?.list?.[indexOfRoute - 1]);
          } else {
            if (routeList?.[indexTarget - 1]) {
              const nextTarget: RouteList = routeList?.[indexTarget - 1];
              navigate(nextTarget?.list?.[nextTarget?.list?.length - 1]);
            }
          }
        }
      }
    }
  };

  const newRouteListStep = useMemo(() => {
    if (routeList?.length > 0) {
      const newRouteListStep: any = [];
      let indexReplace: any;
      const list = [LOCATAIRES1, LOCATAIRES2, LOCATAIRES3, LOCATAIRES4];
      const dataRoute = routeList?.map((x: RouteList, index: number) => {
        if (x?.label === LOCATAIRES_LABEL) {
          return { ...x, realList: list };
        } else {
          return { ...x };
        }
      });

      routeList?.forEach((x: RouteList) => {
        const newRouteListValue = newRouteListStep?.map(
          (x: RouteList) => x?.value
        );
        if (!newRouteListValue?.includes(x?.value)) {
          indexReplace = newRouteListStep?.length;
          newRouteListStep?.push(x);
        } else {
          if (indexReplace >= 0) {
            const target = { ...newRouteListStep?.[indexReplace] };

            const targetList = target?.list || [];
            const newList = [...targetList, ...x.list];
            target.list = newList;
            newRouteListStep[indexReplace] = target;
          }
        }
      });
      return newRouteListStep?.map((x: RouteList, index: number) => ({
        ...x,
        key: index,
      }));
    }
  }, [routeList]);

  useEffect(() => {
    const path = location.pathname;
    if (showHeaderDesktop) {
      const line: any = document.querySelectorAll(".line");
      setTimeout(() => {
        const current = newRouteListStep?.find((x: RouteList) =>
          x?.list?.includes(path)
        );
        const indexTarget = newRouteListStep?.indexOf(current);
        const indexOfRoute = current?.list?.indexOf(path);
        for (let i = 0; i < indexTarget; i += 1) {
          line[i].style.backgroundSize = "100% 100%";
        }
        if (line[indexTarget]) {
          if (isSmallScreen) {
            line[indexTarget].style.backgroundSize = `${(indexOfRoute / current?.list?.length) * 100}% 100% `;
          } else {
            line[indexTarget].style.backgroundSize = `100% ${(indexOfRoute / current?.list?.length) * 100}%`;
          }
        } else if (indexTarget === newRouteListStep?.length - 1 && line[indexTarget - 1]) {
          if (isSmallScreen) {
            line[indexTarget - 1].style.backgroundSize = `${(indexOfRoute / current?.list?.length) * 100}%  100% `;
          } else {
            line[indexTarget - 1].style.backgroundSize = ` 100% ${(indexOfRoute / current?.list?.length) * 100}% `;
          }
        }
      });
    }
  }, [location.pathname, showHeaderDesktop, isSmallScreen]);

  useEffect(() => {
    const current = routeList?.find((x: RouteList) =>
      x?.list?.includes(location.pathname)
    );
    const routeListKey: string[] = routeList?.map((x: RouteList) => x?.key);
    const indexTarget = routeListKey?.indexOf(current?.key);
    if (indexTarget !== 0) {
      setShowPrev(true);
    } else {
      const indexOfRoute = current?.list?.indexOf(location.pathname);
      if (indexOfRoute !== 0) {
        setShowPrev(true);
      } else {
        setShowPrev(false);
      }
    }
  }, [location.pathname, routeList]);

  useEffect(() => {
    const path = location.pathname;

    if (showHeaderDesktop) {
      setTimeout(() => {
        const stepItem: any = document.querySelectorAll(".stepItem");
        const stepList: any = document.querySelectorAll(".step");
        const doneList: any = document.querySelectorAll(".done");

        const findNewRouteList: any = newRouteListStep?.find((x: RouteList) =>
          x?.list?.includes(path)
        );

        stepItem.forEach((x: any, index: number) => {
          if (index <= findNewRouteList?.key) {
            x.classList.add("active");
          } else {
            x.classList.remove("active");
          }
        });
        stepList.forEach((x: any, index: number) => {
          if (index < findNewRouteList?.key) {
            x.style.display = "none";
          } else {
            x.style.display = "block";
          }
        });
        doneList.forEach((x: any, index: number) => {
          if (index < findNewRouteList?.key) {
            x.style.display = "block";
          } else {
            x.style.display = "none";
          }
        });
      });
    }
  }, [location.pathname, newRouteListStep, showHeaderDesktop]);

  return (
    <div className="headerDesktop">
      <img src={logoBlur} alt="logoBlur" className="brand"></img>
      <img src={style?.logo} alt="logo" className="logo" />
      <div className="backToAssurance">
        {showPrev && (
          <img src={prevArrow} alt="loading" onClick={handleBackPage}></img>
        )}
        <p>Mon assurance GLI</p>
        
      </div>
      <div className="showStep">
        {newRouteListStep?.map((x: any, index: number) => {
          if (index === newRouteListStep?.length - 1) {
            return (
              <div className="stepItem" key={x?.key}>
                <p className="step">{index + 1}</p>
                <img className="done" src={check} alt="loading"></img>
                <span>{x?.label}</span>
              </div>
            );
          } else {
            return (
              <div key={x?.key}>
                <div className="stepItem">
                  <p className="step">{index + 1}</p>
                  <img src={check} alt="loading" className="done"></img>
                  <span>{x?.label}</span>
                </div>
                <div className="line"></div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default HeaderDesktop;
