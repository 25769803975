import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import arrow from "assets/image/startStep/arrow.svg";
import { handleNext, selectDossierChoices } from "features/dossierSimuSlice";
import RadioButtonChoice from "features/components/radioButton/radioButton";

type Props = {
  handleOnClick: (
    fieldName: string,
    value: boolean | string | any
  ) => void;
  isSide?: boolean
};

const CoOwnerShipEight: React.FC<Props> = ({ handleOnClick, isSide = false }) => {
  const navigate = useNavigate();
  const mri = useAppSelector(selectDossierChoices);
  const dispatch = useAppDispatch();

  const [answers, setAnswers] = useState(
    mri && mri.residences
      ? mri.residences
      : [
        {
          fieldName: "rentalPercentage",
          title: "Pourcentage de lots en location ?",
          options: ["Plus de 50%", "Moins de 50%", "Ne sait pas"],
          selectedOption: "Ne sait pas",
        },
        {
          fieldName: "airbnbActivity",
          title:
            "Y'a-t-il dans votre copropriété des activités de type Airbnb ?",
          options: ["Oui", "Non", "Ne sait pas"],
          selectedOption: "Ne sait pas",
        },
        {
          fieldName: "colivingOrStudentResidence",
          title:
            "Votre copropriété est-elle de type coliving ou résidence étudiante ?",
          options: ["Oui", "Non", "Ne sait pas"],
          selectedOption: "Ne sait pas",
        },
      ]
  );

  const handleAnswer = (selectedOption: string, answerIndex: number) => {
    const payload = {
      ...answers[answerIndex],
      selectedOption: selectedOption,
    };

    const newAnswers = [...answers];

    newAnswers[answerIndex] = payload;

    setAnswers([...newAnswers]);

    handleOnClick("residences", newAnswers);
  };

  const skipStep = () => {
    handleOnClick("residences", answers);
    dispatch(handleNext())
  };

  const renderQuestion = (question: any, answerIndex: number) => {
    return (
      <div key={question.fieldName} className={`mg-b20`}>
        <p
          className={` lh-22  cccbs2r1r1 mg-b10 ${!isSide ? "text-dark" : "text-dark-light font-14"} question-mri`}
          style={{ textAlign: "left" }}
        >
          {question.title}
        </p>
        <div className={`d-flex ${!isSide ? "flex-hor-center cccbs2r1r2" : "w-100"} cccbs2r1r2 question-mri`}>
          {question.options.map((option: string, index: number) => (
            <RadioButtonChoice
              key={index + Math.random()}
              large
              radio
              text={option}
              selected={question.selectedOption == option}
              handleOnClick={() => handleAnswer(option, answerIndex)}
              last={option === question.options[question.options.length - 1]}
              isSide={isSide === true ? true : false}
            />
          ))}
        </div>
      </div>
    );
  };

  const areAllQuestionsAnswered = () => {
    return answers.every((question: any) => question.selectedOption !== "");
  };

  return (
    <div className={`${!isSide ? "max-w1300 mg-0-auto ccc-body-step-2 d-flex flex-hor-center flex-direction-col" : ""} h-90`}>
      <div className="w-100 text-center border-box radius-15 cccbs2r1 overflow-mri-form">
        <div className={`${!isSide ? "mg-b20" : ""}`}>
          <div className="d-flex flex-center flex-direction-col">
            <p className="font-24 text-dark font-w700 mg-b30">
                Habitations dans la copropriété
            </p>
            <div className={`block column ${!isSide ? "w-90" : "w-100"}`}>
              {answers.map((question: any, index: number) =>
                renderQuestion(question, index)
              )}
            </div>
          </div>
        </div>
        {
            <div className="cursor d-flex flex-hor-end mg-r20" onClick={skipStep}>
              <p className=" font-14 text-dark-light font-w700 mg-r10">
                Passer cette étape
              </p>
              <img src={arrow} alt="next" />
            </div>
        }
      </div>
      {
            <div className="d-flex flex-hor-end mg-t10">
              <button
                className="submitButton w-30"
                disabled={!areAllQuestionsAnswered()}
                onClick={() => dispatch(handleNext())}
              >
                Étape suivante
              </button>
            </div>
        }
    </div>
  );
};

export default CoOwnerShipEight;
