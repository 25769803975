import { API_SERVER } from "constants/configs";
import publicAPIInstance from "pages/connexion/publicProxy";

import { useState } from "react";
// ATTENTION : latLon={longitude, latitude}

interface Inondations {
    message: string | null
    next: string | null
    page: number
    previous: string | null
    responseCode: number
    results: number
    totalPages: number
    zonesInondables: any[]
}

function useFloodRisks() {
    const [floodData, setFloodData] = useState<Inondations | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const fetchFloodRisks = async (latlon: string | number, page: number, pageSize: number, rayon: number) => {
        try {
            const apiUrl = `${API_SERVER}api/backoffice/georisquesgouv/zones-inondables?Latlon=${latlon}&Page=${page}&PageSize=${pageSize}&Rayon=${rayon}`;
            setLoading(true);

            const response: any = await publicAPIInstance.get(apiUrl)
            const data = response?.data;

            if (response.status === 200) {
                setFloodData(data);
                setError(null);
            } else {
                console.error("Error fetching flood risks data");
                setError("Error fetching flood risks data");
            }
        } catch (error) {
            console.error("Error fetching flood risks:", error);
            setError("Error fetching flood risks");
        } finally {
            setLoading(false);
        }
    };

    return { floodData, fetchFloodRisks, loading, error };
}

export default useFloodRisks;
