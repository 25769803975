import noOwner from "assets/image/common/man-fail.svg"

const NonEligible: React.FC = () => {
    return (
        <div className="eligible-container">
            <div className="text-center d-flex flex-direction-col flex-hor-center flex-ver-center">
                <img src={noOwner} className=""/>
                <p className="paragraphe-principale font-w700 w-80 mg-t20 mg-b20">
                    Nous sommes désoles, votre bien n'est pas élibigle à la PNO
                </p>
                <p className="description w-80">
                    La PNO est réservée aux investissement locatifs.
                </p>
            </div>
            <button className="button-orange">
                <span className="font-w700">Faire une nouvelle simulation</span>
            </button>
    </div>
    )
}

export default NonEligible