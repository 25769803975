import ModalSimu from "features/components/modal/modal"
import { useIsSmallScreen } from "hooks/isSmallScreen"
import { ReactNode, useState } from "react";
import SituationEmprunteur from "./situationEmprunteur/situationEmprunteur";
import AssureEmprunteur from "./assureEmprunteur/assureEmprunteur";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectAssuresEmprunts, selectEmpruntId } from "features/assure/assureSlice";
import { handleNext, selectDossierPrets } from "features/dossierSimuSlice";
import PretEmprunteur from "./pretEmprunteur/pretEmprunteur";
import { parseAssureEmpPersToDto, parseAssureEmpToDto, parsePretToDto } from "helpers/parseEmprunteur";
import { createPersonnePhysique } from "features/assure/assureAPI";
import { createNewAssureEmpruntPret, createNewAssuresEmprunt, createPret, updateEmprunt } from "../emprunteurAPI";
import { setBaseInformationTarificateur } from "../emprunteurSlice";

const MainStepEmprunteur: React.FC = () => {
    const isSmallScreen = useIsSmallScreen(567);
    const [isModal, setIsModal] = useState<boolean>(false);
    const [content, setContent] = useState<ReactNode>();
    const assures = useAppSelector(selectAssuresEmprunts);
    const pret = useAppSelector(selectDossierPrets);
    const [file, setFile] = useState<{
        offre: any,
        amortissement: any
    }>({amortissement: null, offre: null});
    const dispatch = useAppDispatch();

    const manageFile = (values: any, type: string) => {
        setFile((prev: any) => {
            if (type === "amortissement") {
                return {...prev, amortissement: values}
            } else if (type === "pret") {
                return {...prev, offre: values}
            }
        })
    }

    const goToTarificateur = () => {
        //Formatter les informations ici
        dispatch(setBaseInformationTarificateur({assures: assures, prets: pret}));
        dispatch(handleNext());
    }

    return (
        <div id="main-step-emp">
            <div className={`container mg-b10`}>
            {
                !isModal && 
                <div className={`w-100 mg-b20 h-100  ${isSmallScreen === false ? "overflow-xauto" : ""}`}>
                    <div className="w-100 h-100 d-flex flex-ver-center flex-direction-col">
                    <SituationEmprunteur setIsModalOpen={setIsModal} setModalContent={setContent}/>
                    <AssureEmprunteur setIsModalOpen={setIsModal} setModalContent={setContent}/>
                    {
                        assures?.length > 0 && (
                            <PretEmprunteur pret={pret} file={file} setFiles={manageFile} setIsModalOpen={setIsModal} setModalContent={setContent}/>
                        )
                    }
                    {
                        !isModal && isSmallScreen &&
                        <button className="submitButton mg-b20" onClick={() => goToTarificateur()}
                            disabled={!(assures?.length > 0 && pret?.length > 0)}
                        >
                            Valider mes informations
                        </button>
                    } 
                    </div>
                </div>
            }
            {
                isModal === true && 
                <ModalSimu isSmallScreen={isSmallScreen} setShowModal={setIsModal} children={content}/>
            }
            </div>
            {
                !isModal && !isSmallScreen &&
                <button className="submitButton mg-b20" onClick={() => goToTarificateur()}
                disabled={!(assures?.length > 0 && pret?.length > 0)}
                >
                    Valider mes informations
                </button>
            }
        </div>
    )
}

export default MainStepEmprunteur