import { Modal } from "antd"
import { useAppDispatch, useAppSelector } from "app/hooks"
import ModalSimu from "features/components/modal/modal"
import Stepper from "features/components/stepper/stepper"
import { selectShowHeader } from "features/dossierSimuSlice"
import { useIsSmallScreen } from "hooks/isSmallScreen"
import React, { ReactNode, useEffect, useState } from "react"

type Props = {
    children: ReactNode
}

const SimuLayout: React.FC<Props> = ({children}) => {

    // const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
    const modalOpen = useAppSelector(selectShowHeader);
    const isSmallScreen = useIsSmallScreen(567);

    // useEffect(() => {
    //   setIsSmallScreen(window.innerWidth < 567);
    //   const handleWindowResize = () => {
    //     setIsSmallScreen(window.innerWidth < 567);
    //   };
  
    //   window.addEventListener('resize', handleWindowResize);
  
    //   return () => {
    //     window.removeEventListener('resize', handleWindowResize);
    //   };
    // }, [])

    return (
        <div className="container-simu">
            <Stepper />
            <div className={`${!modalOpen && isSmallScreen ? "simu-modal" : "simu-form-container"} h-100`}>
                <div className="simu-form">
                    {children}
                </div>
            </div>
            {/* {
                isSmallScreen && modalOpen &&
                (
                    <ModalSimu isSmallScreen={isSmallScreen} key={"test"}></ModalSimu>
                )
            } */}
        </div>
    )
}

export default SimuLayout

