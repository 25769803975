import SelectComponent from "components/Select";
import { Unite } from "helpers/getUnite";
import { IGarantie } from "pages/mainStep/type";
import { useEffect, useState } from "react";

type Props = {
    handleSubmit?: (data:any) => void;
    data: IGarantie;
    onCancel: (value: boolean) => void;
    handleGaranties: (value: IGarantie) => void;
}

type conditionGarantie = {
    designation: string;
    duree: {
        id: string;
        unite: number;
        value: number;
    };
    id: string;
    tarification: any;
    valeurMaximale: number;
    valeurMinimale: number;   
};


const EditGarantie: React.FC<Props> = ({data, handleSubmit, onCancel, handleGaranties}) => {

    const [franchise, setFranchise] = useState<conditionGarantie>();
    const [plafond, setPlafond] = useState<conditionGarantie>();

    useEffect(() => {
        setPlafond(data.plafonds[0]);
        setFranchise(data.franchises[0]);
    }, [])


    useEffect(() => {
        if (franchise) {
          const input = document.querySelector(
            'input[type="range"]'
          ) as HTMLInputElement;
            
          input.style.backgroundSize = Math.ceil(((franchise.duree.value - franchise.valeurMinimale) * 100) / (franchise.valeurMaximale - franchise.valeurMinimale)) + "% 100%";
        }   
    }, [franchise]);

    const handleChangeValueFranchise = (direction: string) => {
        if (direction === "down" && franchise && franchise.duree.value > franchise.valeurMinimale) {
            setFranchise((prevFranchise) => {
                if (!prevFranchise) {
                  return prevFranchise; // Si prevFranchise est null ou undefined, ne rien changer
                }
            
                return {
                  ...prevFranchise,
                  duree: {
                    ...prevFranchise.duree,
                    value: prevFranchise.duree.value - 1,
                  },
                };
              });
        }
        if (direction === "up" && franchise && franchise.duree.value < franchise.valeurMaximale) {
            setFranchise((prevFranchise) => {
                if (!prevFranchise) {
                  return prevFranchise; // Si prevFranchise est null ou undefined, ne rien changer
                }
            
                return {
                  ...prevFranchise,
                  duree: {
                    ...prevFranchise.duree,
                    value: prevFranchise.duree.value + 1,
                  },
                };
              });
        }
    }

    const handleSetValueFranchise = (value: number) => {
        if (franchise) {
            setFranchise((prevFranchise) => {
                if (!prevFranchise) {
                  return prevFranchise; // Si prevFranchise est null ou undefined, ne rien changer
                }
            
                return {
                  ...prevFranchise,
                  duree: {
                    ...prevFranchise.duree,
                    value: value,
                  },
                };
              });
        }
    }

    const closeModal = () => {
        setPlafond(data.plafonds[0]);
        setFranchise(data.franchises[0]);
        onCancel(false);  
    }

    const submitFunction = () => {
        const newGarantie: IGarantie = {...data};
        if (newGarantie.plafonds.length > 0)
            newGarantie.plafonds[0] = plafond;
        if (newGarantie.franchises.length > 0)
            newGarantie.franchises[0] = franchise;
        handleGaranties(newGarantie);
        onCancel(false);
    }

    const handlePlafond = (value: number) => {
        if (plafond) {
            setPlafond((prevPlafond) => {
                if (!prevPlafond) {
                  return prevPlafond; // Si prevPlafond est null ou undefined, ne rien changer
                }
                return {
                  ...prevPlafond,
                  duree: {
                    ...prevPlafond.duree,
                    value: value,
                  },
                };
              });
        }
    }

    return (
        <div id="editGarantie">
            <h2 className="font-16 lh-19 mg-b18 main-color csf-title">
                Votre franchise
            </h2>
            <p className="font-24 lh-19 mg-b26 csf-valueInput">
                {franchise?.duree.value} {franchise && Unite[franchise.duree.unite - 1]} <span className="font-16 lh-19">/ franchise</span>
            </p>

            <div className="csf-inputRange">
                <div
                className="actionInputRange"
                onClick={() => handleChangeValueFranchise("down")}
                >
                    <span>-</span>
                </div>
                <input
                    type="range"
                    min={franchise?.valeurMinimale}
                    max={franchise?.valeurMaximale}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleSetValueFranchise(Number(e.target.value));
                    }}
                    value={franchise?.duree.value}
                />
                <div
                className="actionInputRange"
                onClick={() => handleChangeValueFranchise("up")}
                >
                    <span>+</span>
                </div>
            </div>
            {(plafond) && (
                <h2 className="font-16 lh-19 mg-b18 main-color csf-title">
                    Votre plafond
                </h2>
            )}
                
            {(plafond) && (
                <SelectComponent 
                onChange={(e: string) => {
                    handlePlafond(Number.parseInt(e));
                }}

                options={[{label: plafond.valeurMinimale, value: plafond.valeurMinimale}, {label: plafond.valeurMaximale, value: plafond.valeurMaximale}]}
                placeholder="Sélectionner un plafond"
                value={plafond.duree.value}
                className="w-90"
                ></SelectComponent>
            )}

            {(plafond) && (<br />)}

            <div className="w-100 d-flex buttonGroup flex-hor-center flex-ver-center mg-b30 mg-t10">
                <button className="submitButton" onClick={() => closeModal()}>
                    Annuler
                </button>
                <button className="submitButton" onClick={() => submitFunction()}>
                    Valider
                </button>
            </div>
        </div>        
    )
}

export default EditGarantie;