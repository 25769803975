import { useAppDispatch, useAppSelector } from "app/hooks";
import { handleBack, selectDossierIntermediateStep, selectDossierStepActive, selectShowHeader } from "features/dossierSimuSlice";
import { getSimulationStepsByType } from "helpers/getSimulationSteps"
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";
import { step } from "types/main";
import prevArrow from "assets/image/startStep/prevArrow.svg";
import check from "assets/image/mainStep/check.svg";
import logoBlur from "assets/image/common/logo-blur.svg";
import logoDefault from "assets/image/mainStep/logo.svg"
import { useIsSmallScreen } from "hooks/isSmallScreen";


const Stepper: React.FC = () => {
    const location = useLocation();
    const simulationType = location.pathname.split("/")[1];
    const stepList = getSimulationStepsByType(simulationType);
    const dispatch = useAppDispatch();
    const stepActive = useAppSelector(selectDossierStepActive);
    const stepIntermediate = useAppSelector(selectDossierIntermediateStep)
    // const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
    const showHeader = useAppSelector(selectShowHeader);
    const isSmallScreen = useIsSmallScreen(567);

    useEffect(() => {
      /* Ce UseEffect permet de gerer l'avancement des lignes des etapes */
      const line: any = document.querySelectorAll(".line");
      for (let i = 0; i < stepList.length - 1; i++) {
          //Ce cas correspond a une etape depasse, la ligne sera donc entierement orange
          if (stepActive > i) {
            line[i].style.backgroundSize = "100% 100%";
          }
          //Ce cas correspond a une etape pas encore depasse, la ligne sera donc entierement blanche
          else if (stepActive < i) {
            line[i].style.backgroundSize = "0% 0%";
          }
          //Ce cas correspond a une etape en cours, la ligne sera donc colorie proportionnellement au nombre d'etapes intermediaires passees
          else if (i === stepActive) {
            if (isSmallScreen) {
              line[i].style.backgroundSize = `${
                (stepIntermediate / stepList[i].intermediateStep) * 100
              }% 100%`;
            } else {
              line[i].style.backgroundSize = `100% ${
                (stepIntermediate / stepList[i].intermediateStep) * 100
              }% `;
            }
          }
      }
    }, [stepActive, stepIntermediate, isSmallScreen])

    // useEffect(() => {
    //   setIsSmallScreen(window.innerWidth < 567);
    //   const handleWindowResize = () => {
    //     setIsSmallScreen(window.innerWidth < 567);
    //   };
  
    //   window.addEventListener('resize', handleWindowResize);
  
    //   // Nettoyez l'écouteur d'événements lors du démontage du composant
    //   return () => {
    //     window.removeEventListener('resize', handleWindowResize);
    //   };
    // }, [])

    return ( 
        <div className={`${!showHeader && isSmallScreen === true ? "d-none" : "stepper"}`}>
          <div>
            <img src={logoBlur} alt="logoBlur" className="brand"></img>
            <img src={logoDefault} alt="logo" className="logo" />
          </div>
        <div className="backToAssurance">
          {(stepActive == 0 && stepIntermediate > 0) || stepActive > 0 ? (
             <img src={prevArrow} alt="loading" onClick={() => dispatch(handleBack())}></img>
          ) : null}
          <p>Mon assurance {simulationType === "cbno" ? "PNO" : simulationType}</p>
        </div>
        <div className="showStep">
          {stepList?.map((x: step, index: number) => {
            if (index === stepList?.length - 1) {
              return (
                <div className={`stepItem ${stepActive >= index ? 'active' : ''}`} key={x.stepName + index.toString()}>
                  <p className="step">{index + 1}</p>
                  {/* <img className="done" src={check} alt="loading"></img> */}
                  <span>{x?.stepName}</span>
                </div>
              );
            } else {
              return (
                <div key={x.stepName + index.toString()}>
                  <div className={`stepItem ${stepActive >= index ? 'active' : ''}`}>
                    <p className="step">{index + 1}</p>
                    {/* <img src={check} alt="loading" className="done"></img> */}
                    <span>{x?.stepName}</span>
                  </div>
                  <div className="line"></div>
                </div>
              );
            }
          })}
        </div>
      </div>
    )
}

export default Stepper
