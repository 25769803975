import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import TravauxIcon from "assets/image/mri/travaux.svg";
import ToitureIcon from "assets/image/mri/toiture.svg";
import ChemineeIcon from "assets/image/mri/cheminee.svg";
import EvacuationIcon from "assets/image/mri/evacuations_eaux.svg";
import nextArrow from "assets/image/startStep/arrow.svg";
import { handleNext, selectDossierChoices } from "features/dossierSimuSlice";
import CheckedCard from "features/components/checkedCard/checkedCard";

type Props = {
  handleOnClick: (
    fieldName: string,
    value: boolean | string | any
  ) => void;
  isSide?: boolean
};

const CoOwnerShipTen: React.FC<Props> = ({ handleOnClick, isSide = false }) => {
  const navigate = useNavigate();
  const mri = useAppSelector(selectDossierChoices);
  const dispatch = useAppDispatch();
  const [checkedItems, setCheckedItems] = useState<
    { label: string; value: boolean; icon: any }[]
  >(
    mri && mri.executedWorks
      ? mri.executedWorks
      : [
        { label: "Travaux de ravalement", value: false, icon: TravauxIcon },
        { label: "Réfection de toiture", value: false, icon: ToitureIcon },
        { label: "Cheminée de toit", value: false, icon: ChemineeIcon },
        {
          label: "Evacuations ou descentes d'eaux",
          value: false,
          icon: EvacuationIcon,
        },
      ]
  );

  useEffect(() => {
    if (mri && mri?.constructionDate !== "Plus de 10 ans") {
      handleOnClick("executedWorks", checkedItems);
      dispatch(handleNext());
    }
  }, [mri?.constructionDate])

  const handleCardClick = (index: number) => {
    const updatedCheckedItems = checkedItems?.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          value: !item.value,
        };
      }
      return item;
    });

    setCheckedItems(updatedCheckedItems);
    handleOnClick("executedWorks", updatedCheckedItems);
  };

  const isCommerces = mri && mri?.firstElements?.find((el: any) => el?.label === "Commerces")?.value || false
  const isOneOrMoreChecked = checkedItems?.some((items) => items?.value === true);

  const skipStep = () => {
    handleOnClick("executedWorks", checkedItems);
    dispatch(handleNext());
  };

  return (
    <div className={`${!isSide ? "max-w1300 mg-0-auto ccc-body-step-2" : ""}`}>
      <div className="w-100 text-center border-box radius-15 cccbs2r1">
        {
          !isSide ? (
            <div className="mg-b50">
              <div>
                <p className="font-18 lh-22 text-dark cccbs2r1r1">
                  Quels sont les{" "}
                  <span className="font-w700">
                    travaux qui ont été effectués il y a moins de 10 ans ?
                  </span>
                </p>
              </div>
            </div>
          ) : 
          <div className="d-flex mg-b20 text-dark-light">
            <p>
              Quels sont les{" "}travaux qui ont été effectués il y a moins de 10 ans ?
            </p>
          </div>
        }
        <div
          className={`${!isSide ? "d-flex flex-hor-center cccbs2r1r2" : "d-flex flex-hor-start"}`}
          style={{ flexWrap: "wrap" }}
        >
          {checkedItems?.map((item, index) => (
            <CheckedCard
              tiny
              key={index}
              label={item.label}
              length={checkedItems.length}
              icon={item.icon}
              checked={item.value}
              onClick={() => handleCardClick(index)}
              isSide={isSide === true ? true : false}

            />
          ))}
        </div>
        {
            <div className="cursor d-flex flex-hor-end mg-r20 mg-t20" onClick={skipStep}>
              <p className=" font-14 text-dark-light font-w700 mg-r10">
                Passer cette étape
              </p>
              <img src={nextArrow} alt="next" />
            </div>
        }
      </div>
      {
            <div className="d-flex flex-hor-end mg-t10">
              <button
                className="submitButton w-30"
                disabled={!isOneOrMoreChecked}
                onClick={() => dispatch(handleNext())}
              >
                Étape suivante
              </button>
            </div>
        }
    </div>
  );
};

export default CoOwnerShipTen;
