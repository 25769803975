import { useLocation, useNavigate } from "react-router-dom";
import prevArrow from "assets/image/startStep/prevArrow.svg";
import check from "assets/image/mainStep/check.svg";
import { useDispatch, useSelector } from "react-redux";
import { setIndexFamily, setRenderInfoFour } from "pages/mainStep/mainSlice";
import { useEffect, useMemo, useState } from "react";
import logoBlur from "assets/image/common/logo-blur.svg";
import { RouteList } from "pages/mainStep/type";
import arrow from "assets/image/startStep/arrow.svg";

import { ReactComponent as HomeIcon } from "../image/home.svg";
import { ReactComponent as Umbrella } from "../image/umbrella.svg";
import { ReactComponent as Calculater } from "../image/calculator.svg";
import { ReactComponent as BreakHome } from "../image/breakHome.svg";
import { ReactComponent as Plus } from "../image/plus.svg";

import bell from "../image/bell.svg";
import bigAvatar from "../image/bigAvatar.svg";
import facturation from "../image/facturation.svg";
import setting from "../image/setting.svg";
import shield from "../image/shield.svg";
import pay from "../image/pay.svg";
import information from "../image/information.svg";
import bigGreenPlus from "../image/bigGreenPlus.svg";
import panel from "../image/panel.svg";
import houseIcon from "../image/houseIcon.svg";
import arrowRight from "../image/arrowRight.svg";
import greenCheck from "../image/greenCheck.svg";
import houseGreenIcon from "../image/houseGreenIcon.svg";

import {
  LOCATAIRES1,
  LOCATAIRES2,
  LOCATAIRES3,
  LOCATAIRES4,
  LOCATAIRES3STEP1,
  LOCATAIRES3STEP4,
  LOCATAIRES_LABEL,
  LOCATAIRES3STEP5,
} from "pages/mainStep/routeList";
import classNames from "classnames";

const PageFour = ({ showHeaderDesktop, style }: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { indexFamily, routeList, listFamily } = useSelector(
    (state: any) => state.mainSlice
  );

  const [showPrev, setShowPrev] = useState(true);

  const handleBackPage = () => {
    const isGarant =
      listFamily?.[indexFamily]?.step2?.children?.peutEtreAttribueGarant ||
      listFamily?.[indexFamily]?.step2?.parent?.peutEtreAttribueGarant;
    let current: any = routeList?.find((x: any) =>
      x?.list?.includes(location.pathname)
    );
    if (!isGarant) {
      const newList = current?.list?.filter(
        (x: string) =>
          !x?.includes(LOCATAIRES3STEP4) && !x?.includes(LOCATAIRES3STEP5)
      );
      current = { ...current, list: newList };
    }
    const routeListKey: any = routeList?.map((x: RouteList) => x?.key);
    const indexTarget = routeListKey?.indexOf(current?.key);
    if (current) {
      if (current?.list) {
        if (location.pathname === LOCATAIRES3STEP1) {
          if (indexFamily === 0) {
            navigate(LOCATAIRES2);
          } else {
            dispatch(setIndexFamily(indexFamily - 1));
            dispatch(setRenderInfoFour(true));
            navigate(LOCATAIRES3STEP4);
          }
        } else {
          const indexOfRoute = current?.list?.indexOf(location.pathname);
          if (current?.list?.[indexOfRoute - 1]) {
            navigate(current?.list?.[indexOfRoute - 1]);
          } else {
            if (routeList?.[indexTarget - 1]) {
              const nextTarget: RouteList = routeList?.[indexTarget - 1];
              navigate(nextTarget?.list?.[nextTarget?.list?.length - 1]);
            }
          }
        }
      }
    }
  };

  const newRouteListStep = useMemo(() => {
    if (routeList?.length > 0) {
      const newRouteListStep: any = [];
      let indexReplace: any;
      const list = [LOCATAIRES1, LOCATAIRES2, LOCATAIRES3, LOCATAIRES4];
      const dataRoute = routeList?.map((x: RouteList, index: number) => {
        if (x?.label === LOCATAIRES_LABEL) {
          return { ...x, realList: list };
        } else {
          return { ...x };
        }
      });

      routeList?.forEach((x: RouteList) => {
        const newRouteListValue = newRouteListStep?.map(
          (x: RouteList) => x?.value
        );
        if (!newRouteListValue?.includes(x?.value)) {
          indexReplace = newRouteListStep?.length;
          newRouteListStep?.push(x);
        } else {
          if (indexReplace >= 0) {
            const target = { ...newRouteListStep?.[indexReplace] };

            const targetList = target?.list || [];
            const newList = [...targetList, ...x.list];
            target.list = newList;
            newRouteListStep[indexReplace] = target;
          }
        }
      });
      return newRouteListStep?.map((x: RouteList, index: number) => ({
        ...x,
        key: index,
      }));
    }
  }, [routeList]);

  useEffect(() => {
    const path = location.pathname;
    if (showHeaderDesktop) {
      const line: any = document.querySelectorAll(".line");
      setTimeout(() => {
        const current = newRouteListStep?.find((x: RouteList) =>
          x?.list?.includes(path)
        );
        const indexTarget = newRouteListStep?.indexOf(current);
        const indexOfRoute = current?.list?.indexOf(path);
        for (let i = 0; i < indexTarget; i += 1) {
          line[i].style.backgroundSize = "100% 100%";
        }
        if (line[indexTarget]) {
          line[indexTarget].style.backgroundSize = `100% ${
            (indexOfRoute / current?.list?.length) * 100
          }%`;
        } else if (
          indexTarget === newRouteListStep?.length - 1 &&
          line[indexTarget - 1]
        ) {
          line[indexTarget - 1].style.backgroundSize = ` 100% ${
            (indexOfRoute / current?.list?.length) * 100
          }% `;
        }
      });
    }
  }, [location.pathname, showHeaderDesktop]);

  useEffect(() => {
    const current = routeList?.find((x: RouteList) =>
      x?.list?.includes(location.pathname)
    );
    const routeListKey: string[] = routeList?.map((x: RouteList) => x?.key);
    const indexTarget = routeListKey?.indexOf(current?.key);
    if (indexTarget !== 0) {
      setShowPrev(true);
    } else {
      const indexOfRoute = current?.list?.indexOf(location.pathname);
      if (indexOfRoute !== 0) {
        setShowPrev(true);
      } else {
        setShowPrev(false);
      }
    }
  }, [location.pathname, routeList]);

  useEffect(() => {
    const path = location.pathname;

    if (showHeaderDesktop) {
      setTimeout(() => {
        const stepItem: any = document.querySelectorAll(".stepItem");
        const stepList: any = document.querySelectorAll(".step");
        const doneList: any = document.querySelectorAll(".done");

        const findNewRouteList: any = newRouteListStep.find((x: RouteList) =>
          x?.list?.includes(path)
        );

        stepItem.forEach((x: any, index: number) => {
          if (index <= findNewRouteList?.key) {
            x.classList.add("active");
          } else {
            x.classList.remove("active");
          }
        });
        stepList.forEach((x: any, index: number) => {
          if (index < findNewRouteList?.key) {
            x.style.display = "none";
          } else {
            x.style.display = "block";
          }
        });
        doneList.forEach((x: any, index: number) => {
          if (index < findNewRouteList?.key) {
            x.style.display = "block";
          } else {
            x.style.display = "none";
          }
        });
      });
    }
  }, [location.pathname, newRouteListStep, showHeaderDesktop]);

  const handleToItem = (value: number) => {
    if (value === 1) {
      navigate("/accueil");
    } else if (value === 2) {
      navigate("/contrats");
    } else if (value === 3) {
      navigate("/simulateur");
    } else if (value === 4) {
      navigate("/sinistres");
    } else if (value === 5) {
      navigate("/plus");
    }
  };

  return (
    <div className="pageFour">
      <div className="bg_header"></div>
      <div className="d-flex flex-direction-col flex-hor-start flex-ver-center mg-b20 pt_header">
        <div className="w-100 d-flex flex-hor-end p-relative pth_r1">
          <div className="d-flex flex-hor-center flex-ver-center mg-b20 pthr1_c1">
            <img src={bell} alt="bell" className="pthr1_rel" />
            <div className="d-flex flex-ver-center flex-hor-center pthr1_absolute">
              <p className="text-white">4</p>
            </div>
          </div>
        </div>
        <div className="w-100 d-flex flex-direction-col flex-ver-start">
          <p className="text-white font-24 text-white font-w600 text-left mg-b10 pth_r3">
            Bonjour Alexandre,
          </p>
          <p className="text-white font-15 text-white font-w600 text-left pth_r3">
            quel produit souhaitez-vous aujourd’hui ?
          </p>
        </div>
      </div>
      <div className="mg-b30 pt_body">
        <div className="d-flex mg-b30 ptb_r1">
          <div className="d-flex flex-direction-col flex-ver-center border-box  mg-l30 ptbr2_item">
            <div className="d-flex flex-hor-center">
              <img src={houseIcon} alt="question" className="mg-b20" />
            </div>
            <p className="font-13 lh-16 text-center">Garantie Loyers Impayés</p>
          </div>
          <div className="d-flex flex-direction-col flex-ver-center border-box  mg-l10 ptbr2_item">
            <div className="d-flex flex-hor-center">
              <img src={houseIcon} alt="question" className="mg-b20" />
            </div>
            <p className="font-13 lh-16 text-center">Garantie Loyers Impayés</p>
          </div>
          <div className="d-flex flex-direction-col flex-ver-center border-box  mg-l10 ptbr2_item">
            <div className="d-flex flex-hor-center">
              <img src={houseIcon} alt="question" className="mg-b20" />
            </div>
            <p className="font-13 lh-16 text-center">Garantie Loyers Impayés</p>
          </div>
          <div className="d-flex flex-direction-col flex-ver-center border-box  mg-l10 mg-r30 ptbr2_item">
            <div className="d-flex flex-hor-center">
              <img src={houseIcon} alt="question" className="mg-b20" />
            </div>
            <p className="font-13 lh-16 text-center">Garantie Loyers Impayés</p>
          </div>
        </div>

        <div className="pad-r30 mg-b30 ptb_r2">
          <img src={panel} className="w-100" alt="panel" />
        </div>

        <div className="ptb_r3">
          <p className="font-22 lh-27 font-w600 mg-b30">
            Mes dossiers en attente
          </p>
          <ul className="ptbr3_list">
            <li className="d-flex flex-ver-center flex-hor-between mg-b20 ptbr2_item">
              <div className="d-flex flex-ver-end ptbr2i_c1">
                <div className="mg-r20 d-flex flex-ver-center flex-hor-center ptbr2ic1c1">
                  <img src={houseIcon} alt="house" />
                </div>
                <div className="ptbr2ic1c2">
                  <div className=" d-flex flex-ver-center ptbr2ic1c2r1">
                    <p className="font-16 lh-19 mg-r10 ">
                      Garantie loyers impayés
                    </p>
                    <span className="font-11 lh-13">À signer</span>
                  </div>
                  <span className="font-13 lh-16">
                    30 Rue Larmeroux 92170 Vanves
                  </span>
                </div>
              </div>
              <img src={arrowRight} alt="arrow" className="ptbr2i_c1" />
            </li>
            <li className="d-flex flex-ver-center flex-hor-between mg-b20  ptbr2_item">
              <div className="d-flex flex-ver-end ptbr2i_c1">
                <div className="mg-r20 d-flex flex-ver-center flex-hor-center ptbr2ic1c1">
                  <img src={houseIcon} alt="house" />
                </div>
                <div className="ptbr2ic1c2">
                  <div className=" d-flex flex-ver-center ptbr2ic1c2r1">
                    <p className="font-16 lh-19 mg-r10 ">
                      Garantie loyers impayés
                    </p>
                    <span className="font-11 lh-13">À finaliser</span>
                  </div>
                  <span className="font-13 lh-16">
                    30 Rue Larmeroux 92170 Vanves
                  </span>
                </div>
              </div>
              <img src={arrowRight} alt="arrow" className="ptbr2i_c1" />
            </li>
          </ul>
        </div>

        <div className="ptb_r4">
          <p className="font-22 lh-27 font-w600 mg-b30 mg-l30">Mes biens</p>

          <div className="d-flex mg-b30 ptb_r1">
            <div className="d-flex flex-direction-col flex-ver-center border-box  mg-l30 ptbr2_item">
              <div className="d-flex flex-hor-center">
                <img src={houseGreenIcon} alt="question" className="mg-b20" />
              </div>
              <p className="font-13 lh-16 text-center">Maison Vanves</p>
            </div>
            <div className="d-flex flex-direction-col flex-ver-center border-box  mg-l10 ptbr2_item">
              <div className="d-flex flex-hor-center">
                <img src={houseGreenIcon} alt="question" className="mg-b20" />
              </div>
              <p className="font-13 lh-16 text-center">
                Appartement Biarritz bord de mer
              </p>
            </div>

            <div className="d-flex flex-direction-col flex-ver-center border-box  mg-l10 mg-r30 cursor ptbr2_item ptbr2_item_last">
              <div className="d-flex flex-hor-center">
                <img src={bigGreenPlus} alt="question" className="mg-b20" />
              </div>
              <p className="font-13 lh-16 text-center">Ajouter un bien</p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-100 pt_form"></div>

      <div className="d-flex flex-ver-center menuFooter">
        <div
          className="w-20 d-flex flex-direction-col flex-ver-center flex-hor-end cursor"
          onClick={() => handleToItem(1)}
        >
          <HomeIcon className="mg-b10" />
          <p className="font-11 font-w600 lh-13">ACCUEIL</p>
        </div>
        <div
          className="w-20 d-flex flex-direction-col flex-ver-center flex-hor-end cursor"
          onClick={() => handleToItem(2)}
        >
          <Umbrella className="mg-b10" />
          <p className="font-11 font-w600 lh-13">CONTRATS</p>
        </div>
        <div
          className="w-20 d-flex flex-direction-col flex-ver-center flex-hor-end cursor"
          onClick={() => handleToItem(3)}
        >
          <Calculater className="mg-b10" />
          <p className="font-11 font-w600 lh-13">SIMULATEUR</p>
        </div>
        <div
          className="w-20 d-flex flex-direction-col flex-ver-center flex-hor-end cursor"
          onClick={() => handleToItem(4)}
        >
          <BreakHome className="mg-b10" />
          <p className="font-11 font-w600 lh-13">SINISTRES</p>
        </div>
        <div
          className="w-20 d-flex flex-direction-col flex-ver-center flex-hor-end cursor"
          onClick={() => handleToItem(5)}
        >
          <Plus className="mg-b10" />
          <p className="font-11 font-w600 lh-13">PLUS</p>
        </div>
      </div>
    </div>
  );
};

export default PageFour;
