import React from "react";

type Props = {
  value: number;
  onDecrease: () => void;
  onIncrease: () => void;
  suffix?: string | null;
  max?: number;
};

const CustomRangeInput: React.FC<Props> = ({
  value,
  onDecrease,
  onIncrease,
  suffix = null,
  max,
}) => {
  const isMaxExceeded = max !== undefined && value >= max;
  return (
    <div id="custom-range-input">
      <button className="round-button" onClick={onDecrease}>
        <span className="text-dark font-w700">-</span>
      </button>
      <span className="text-dark font-w700">{`${value} ${
        suffix ? suffix : ""
      }`}</span>
      <button
        className="round-button"
        onClick={onIncrease}
        disabled={isMaxExceeded}
      >
        <span className="text-dark font-w700">+</span>
      </button>
    </div>
  );
};

export default CustomRangeInput;
