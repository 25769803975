import HeaderDesktop from 'components/headerDesktop';
import MainStep from 'pages/mainStep';
import React, { useEffect, useState } from 'react'
import { styleDefault } from 'style-config';

const GliIndex: React.FC = ({}) => {
    const [showHeaderDesktop, setShowHeaderDesktop] = useState(true); 

    const style = styleDefault;

    // useEffect(() => {
    //     window.addEventListener("resize", () => {
    //       if (window.innerWidth >= 900) {
    //         setShowHeaderDesktop(true);
    //       } else {
    //         setShowHeaderDesktop(false);
    //       }
    //     });
    //     if (window.innerWidth >= 900) {
    //       setShowHeaderDesktop(true);
    //     }
    
    //     return () =>
    //       window.removeEventListener("resize", () => {
    //         if (window.innerWidth >= 900) {
    //           setShowHeaderDesktop(true);
    //         } else {
    //           setShowHeaderDesktop(false);
    //         }
    //       });
    //   }, []);

    return (
      <div id="container">

            {showHeaderDesktop && (
                <HeaderDesktop
                    showHeaderDesktop={showHeaderDesktop}
                    style={style}
                ></HeaderDesktop>
                )}
                <MainStep
                showHeaderDesktop={showHeaderDesktop}
                style={style}
                ></MainStep>
        </div>
    );
}

export default GliIndex;