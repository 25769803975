import { useLocation, useNavigate } from "react-router-dom";
import prevArrow from "assets/image/startStep/prevArrow.svg";
import check from "assets/image/mainStep/check.svg";
import { useDispatch, useSelector } from "react-redux";
import { setIndexFamily, setRenderInfoFour } from "pages/mainStep/mainSlice";
import { useEffect, useMemo, useState } from "react";
import logoBlur from "assets/image/common/logo-blur.svg";
import { RouteList } from "pages/mainStep/type";
import arrow from "assets/image/startStep/arrow.svg";

import { ReactComponent as HomeIcon } from "../image/home.svg";
import { ReactComponent as Umbrella } from "../image/umbrella.svg";
import { ReactComponent as Calculater } from "../image/calculator.svg";
import { ReactComponent as BreakHome } from "../image/breakHome.svg";
import { ReactComponent as Plus } from "../image/plus.svg";

import bell from "../image/bell.svg";
import bigAvatar from "../image/bigAvatar.svg";
import facturation from "../image/facturation.svg";
import setting from "../image/setting.svg";
import shield from "../image/shield.svg";
import pay from "../image/pay.svg";
import information from "../image/information.svg";
import greenNote from "../image/greenNote.svg";
import blackPrevArrow from "../image/blackPrevArrow.svg";
import logout from "../image/logout.svg";

import {
  LOCATAIRES1,
  LOCATAIRES2,
  LOCATAIRES3,
  LOCATAIRES4,
  LOCATAIRES3STEP1,
  LOCATAIRES3STEP4,
  LOCATAIRES_LABEL,
  LOCATAIRES3STEP5,
} from "pages/mainStep/routeList";

const PageEight = ({ showHeaderDesktop, style }: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { indexFamily, routeList, listFamily } = useSelector(
    (state: any) => state.mainSlice
  );

  const [showPrev, setShowPrev] = useState(true);

  const handleBackPage = () => {
    const isGarant =
      listFamily?.[indexFamily]?.step2?.children?.peutEtreAttribueGarant ||
      listFamily?.[indexFamily]?.step2?.parent?.peutEtreAttribueGarant;
    let current: any = routeList?.find((x: any) =>
      x?.list?.includes(location.pathname)
    );
    if (!isGarant) {
      const newList = current?.list?.filter(
        (x: string) =>
          !x?.includes(LOCATAIRES3STEP4) && !x?.includes(LOCATAIRES3STEP5)
      );
      current = { ...current, list: newList };
    }
    const routeListKey: any = routeList?.map((x: RouteList) => x?.key);
    const indexTarget = routeListKey?.indexOf(current?.key);
    if (current) {
      if (current?.list) {
        if (location.pathname === LOCATAIRES3STEP1) {
          if (indexFamily === 0) {
            navigate(LOCATAIRES2);
          } else {
            dispatch(setIndexFamily(indexFamily - 1));
            dispatch(setRenderInfoFour(true));
            navigate(LOCATAIRES3STEP4);
          }
        } else {
          const indexOfRoute = current?.list?.indexOf(location.pathname);
          if (current?.list?.[indexOfRoute - 1]) {
            navigate(current?.list?.[indexOfRoute - 1]);
          } else {
            if (routeList?.[indexTarget - 1]) {
              const nextTarget: RouteList = routeList?.[indexTarget - 1];
              navigate(nextTarget?.list?.[nextTarget?.list?.length - 1]);
            }
          }
        }
      }
    }
  };

  const newRouteListStep = useMemo(() => {
    if (routeList?.length > 0) {
      const newRouteListStep: any = [];
      let indexReplace: any;
      const list = [LOCATAIRES1, LOCATAIRES2, LOCATAIRES3, LOCATAIRES4];
      const dataRoute = routeList?.map((x: RouteList, index: number) => {
        if (x?.label === LOCATAIRES_LABEL) {
          return { ...x, realList: list };
        } else {
          return { ...x };
        }
      });

      routeList?.forEach((x: RouteList) => {
        const newRouteListValue = newRouteListStep?.map(
          (x: RouteList) => x?.value
        );
        if (!newRouteListValue?.includes(x?.value)) {
          indexReplace = newRouteListStep?.length;
          newRouteListStep?.push(x);
        } else {
          if (indexReplace >= 0) {
            const target = { ...newRouteListStep?.[indexReplace] };

            const targetList = target?.list || [];
            const newList = [...targetList, ...x.list];
            target.list = newList;
            newRouteListStep[indexReplace] = target;
          }
        }
      });
      return newRouteListStep?.map((x: RouteList, index: number) => ({
        ...x,
        key: index,
      }));
    }
  }, [routeList]);

  useEffect(() => {
    const path = location.pathname;
    if (showHeaderDesktop) {
      const line: any = document.querySelectorAll(".line");
      setTimeout(() => {
        const current = newRouteListStep?.find((x: RouteList) =>
          x?.list?.includes(path)
        );
        const indexTarget = newRouteListStep?.indexOf(current);
        const indexOfRoute = current?.list?.indexOf(path);
        for (let i = 0; i < indexTarget; i += 1) {
          line[i].style.backgroundSize = "100% 100%";
        }
        if (line[indexTarget]) {
          line[indexTarget].style.backgroundSize = `100% ${
            (indexOfRoute / current?.list?.length) * 100
          }%`;
        } else if (
          indexTarget === newRouteListStep?.length - 1 &&
          line[indexTarget - 1]
        ) {
          line[indexTarget - 1].style.backgroundSize = ` 100% ${
            (indexOfRoute / current?.list?.length) * 100
          }% `;
        }
      });
    }
  }, [location.pathname, showHeaderDesktop]);

  useEffect(() => {
    const current = routeList?.find((x: RouteList) =>
      x?.list?.includes(location.pathname)
    );
    const routeListKey: string[] = routeList?.map((x: RouteList) => x?.key);
    const indexTarget = routeListKey?.indexOf(current?.key);
    if (indexTarget !== 0) {
      setShowPrev(true);
    } else {
      const indexOfRoute = current?.list?.indexOf(location.pathname);
      if (indexOfRoute !== 0) {
        setShowPrev(true);
      } else {
        setShowPrev(false);
      }
    }
  }, [location.pathname, routeList]);

  useEffect(() => {
    const path = location.pathname;

    if (showHeaderDesktop) {
      setTimeout(() => {
        const stepItem: any = document.querySelectorAll(".stepItem");
        const stepList: any = document.querySelectorAll(".step");
        const doneList: any = document.querySelectorAll(".done");

        const findNewRouteList: any = newRouteListStep.find((x: RouteList) =>
          x?.list?.includes(path)
        );

        stepItem.forEach((x: any, index: number) => {
          if (index <= findNewRouteList?.key) {
            x.classList.add("active");
          } else {
            x.classList.remove("active");
          }
        });
        stepList.forEach((x: any, index: number) => {
          if (index < findNewRouteList?.key) {
            x.style.display = "none";
          } else {
            x.style.display = "block";
          }
        });
        doneList.forEach((x: any, index: number) => {
          if (index < findNewRouteList?.key) {
            x.style.display = "block";
          } else {
            x.style.display = "none";
          }
        });
      });
    }
  }, [location.pathname, newRouteListStep, showHeaderDesktop]);

  const handleToItem = (value: number) => {
    if (value === 1) {
      navigate("/accueil");
    } else if (value === 2) {
      navigate("/contrats");
    } else if (value === 3) {
      navigate("/simulateur");
    } else if (value === 4) {
      navigate("/sinistres");
    } else if (value === 5) {
      navigate("/plus");
    }
  };

  return (
    <div className="pageEight">
      <div className="w-100 d-flex flex-ver-center flex-hor-between pe_header">
        <img
          className="cursor prevArrow"
          src={blackPrevArrow}
          alt="arrow"
        ></img>

        <p className="font-18 lh-22 font-w600">Mon profil</p>
        <span className="font-14 lh-17 font-w600">ENREGISTRER</span>
      </div>
      <div className="pe_body">
        <div className="mg-b20 d-flex flex-direction-col flex-ver-start peb_item">
          <p className="font-22 lh-27 mg-b30 font-w600">
            Informations générales
          </p>
          <div className="mg-b40 pebi_input">
            <p className="font-14 lh-17 mg-b10">Prénom</p>
            <input className="input" placeholder="Alexandre" />
          </div>
          <div className="mg-b20 pebi_input">
            <p className="font-14 lh-17 mg-b10">Nom de famille</p>
            <input className="input" placeholder="CAGNOLI" />
          </div>
        </div>

        <div className="mg-b20 d-flex flex-direction-col flex-ver-start peb_item">
          <p className="font-22 lh-27 mg-b30 font-w600">Adresse personnelle</p>
          <div className="mg-b40 pebi_input">
            <p className="font-14 lh-17 mg-b10">Rue</p>
            <input className="input" placeholder="Rue" />
          </div>
          <div className="mg-b20 pebi_input">
            <p className="font-14 lh-17 mg-b10">Code postal</p>
            <input className="input" placeholder="Code postal" />
          </div>
          <div className="mg-b20 pebi_input">
            <p className="font-14 lh-17 mg-b10">Ville</p>
            <input className="input" placeholder="Ville" />
          </div>
          <div className="mg-b20 pebi_input">
            <p className="font-14 lh-17 mg-b10">Pays</p>
            <input className="input" placeholder="Pays" />
          </div>
        </div>

        <div className="mg-b50 d-flex flex-direction-col flex-ver-start peb_item">
          <p className="font-22 lh-27 mg-b30 font-w600">
            Informations générales
          </p>
          <div className="mg-b30 pebi_note">
            <img src={greenNote} alt="note" />
            <p className="font-15 lh-22">
              Si vous souhaitez modifier l’adresse email rattachée à votre
              compte, merci de nous écrire à{" "}
              <span>serviceclient@confiancia.fr.</span>
            </p>
          </div>
          <div className="mg-b40 pebi_input">
            <p className="font-14 lh-17 mg-b10">Adresse email</p>
            <input className="input" placeholder="boss@agence-alfred.com" />
          </div>
          <div className="mg-b20 pebi_input">
            <p className="font-14 lh-17 mg-b10">Mot de passe</p>
            <div>
              <input className="input" placeholder="CAGNOLI" type="password" />
              <span className="font-11 lh-13 font-w600 cursor">CHANGER</span>
            </div>
          </div>
        </div>

        <div className="w-100 d-flex flex-ver-center flex-hor-center mg-b50 peb_footer">
          <img src={logout} alt="logout" className="mg-r10" />
          <p className="font-15 lh-18 font-w600">Clôturer le compte</p>
        </div>
      </div>
      <div className="d-flex flex-ver-center menuFooter">
        <div
          className="w-20 d-flex flex-direction-col flex-ver-center flex-hor-end cursor"
          onClick={() => handleToItem(1)}
        >
          <HomeIcon className="mg-b10" />
          <p className="font-11 font-w600 lh-13">ACCUEIL</p>
        </div>
        <div
          className="w-20 d-flex flex-direction-col flex-ver-center flex-hor-end cursor"
          onClick={() => handleToItem(2)}
        >
          <Umbrella className="mg-b10" />
          <p className="font-11 font-w600 lh-13">CONTRATS</p>
        </div>
        <div
          className="w-20 d-flex flex-direction-col flex-ver-center flex-hor-end cursor"
          onClick={() => handleToItem(3)}
        >
          <Calculater className="mg-b10" />
          <p className="font-11 font-w600 lh-13">SIMULATEUR</p>
        </div>
        <div
          className="w-20 d-flex flex-direction-col flex-ver-center flex-hor-end cursor"
          onClick={() => handleToItem(4)}
        >
          <BreakHome className="mg-b10" />
          <p className="font-11 font-w600 lh-13">SINISTRES</p>
        </div>
        <div
          className="w-20 d-flex flex-direction-col flex-ver-center flex-hor-end cursor"
          onClick={() => handleToItem(5)}
        >
          <Plus className="mg-b10" />
          <p className="font-11 font-w600 lh-13">PLUS</p>
        </div>
      </div>
    </div>
  );
};

export default PageEight;
