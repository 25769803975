import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useElevation, useGeoRisksTypes, useMediaQuery, useFloodRisks } from "hooks";
import { useAppSelector } from "app/hooks";
import { useAppDispatch } from "app/hooks";
import { handleNext, selectDossierChoices } from "features/dossierSimuSlice";
import { geocodeByAddress } from "react-google-places-autocomplete";
import MapContainer from "googlemaps/mapContainer";
import { useElevation, useFloodRisks, useGeoRisksTypes } from "hooksGoogleMaps";
// import StreetviewPreview from "googleMaps/StreetviewPreview";
import AltitudeIcon from "assets/image/mri/altitude.svg";
import InondationIcon from "assets/image/mri/risque-inondation.svg";
import SismiqueIcon from "assets/image/mri/risque-sismique.svg";
// import EyeCircle from "assets/image/icons/dashboard/eye-circle.svg";
import StreetViewPreview from "googlemaps/streetviewPreview";
import { useIsSmallScreen } from "hooks/isSmallScreen";

type Props = {
  isSide?: boolean
  part?: number
  handleOnClick: (
    fieldName: string,
    value: boolean | string | any
  ) => void;
};

type AddressInfo = {
  address: string | null;
  latitude: number;
  longitude: number;
};

const Georisks: React.FC<Props> = ({  handleOnClick, isSide = false, part = 1 }) => {
  const [openGeoDetails, setGeoDetails] = useState<[boolean, string | null]>([
    false,
    null,
  ]);

  const mri = useAppSelector(selectDossierChoices);

  const [selectedAddress, setSelectedAddress] = useState<AddressInfo | null>(
    mri ? mri.address : { address: null, latitude: null, longitude: null }
  );
  const [address, setAddress] = useState(
    mri ? mri.address : { address: null, latitude: null, longitude: null }
  );

  const [rayon, setRayon] = useState<number>(20);
  const isTabOrPhone = true;
  const dispatch = useAppDispatch();

  const [isLoadingAddress, setIsLoadingAddress] = useState(false);
  const { elevation, fetchElevation } = useElevation();
  const { floodData, fetchFloodRisks, loading: loadingFloodRisks } = useFloodRisks();
  const {
    geoRisks,
    fetchGeoRisksTypes,
    loading: loadingGeoRisks,
  } = useGeoRisksTypes();
  const isSmallScreen = useIsSmallScreen(993);


  const getAddressFromLatLng = async (lat: number, lng: number) => {
    const results = await geocodeByAddress(`${lat}, ${lng}`);
    if (results.length > 0) {
      return results[0].formatted_address;
    }
  };

  useEffect(() => {
    const fetchElevationFromLatLng = async () => {
      if (
        selectedAddress &&
        selectedAddress.latitude &&
        selectedAddress.longitude
      ) {
        await fetchElevation(selectedAddress.latitude, selectedAddress.longitude).then((res)=>{
          handleOnClick("address", {
            ...selectedAddress,
            altitude: Math.round(elevation || 0),
          });
        })
      }
    };

    const fetchFloodRisksFromLatLng = async () => {
      if (
        selectedAddress &&
        selectedAddress.latitude &&
        selectedAddress.longitude
      ) {
        await fetchFloodRisks(
          `${selectedAddress.longitude},${selectedAddress.latitude}`,
          1,
          10,
          rayon
        );
      }
    };

    const fetchGeoRisksTypesFromLatLng = async () => {
      if (
        selectedAddress &&
        selectedAddress.latitude &&
        selectedAddress.longitude
      ) {
        await fetchGeoRisksTypes(
          `${selectedAddress.longitude},${selectedAddress.latitude}`,
          1,
          10,
          rayon
        );
      }
    };

    const fetchTypesRisquesFromLatLng = async () => {
      if (
        selectedAddress &&
        selectedAddress.latitude &&
        selectedAddress.longitude
      ) {
        await fetchElevation(selectedAddress.latitude, selectedAddress.longitude);
      }
    };

    const fetchAddressFromLatLng = async () => {
      if (
        selectedAddress &&
        selectedAddress.latitude &&
        selectedAddress.longitude &&
        !selectedAddress.address
      ) {
        setIsLoadingAddress(true);
        const address = await getAddressFromLatLng(
          selectedAddress.latitude,
          selectedAddress.longitude
        );
        setIsLoadingAddress(false);

        if (address) {
          setAddress(address);
          setSelectedAddress({
            ...selectedAddress,
            address: address,
          });
        }
      }
    };

    fetchElevationFromLatLng();
    fetchAddressFromLatLng();
    fetchTypesRisquesFromLatLng();
    fetchFloodRisksFromLatLng()
    fetchGeoRisksTypesFromLatLng();
  }, [selectedAddress]);

  const handleAddressSelect = (selectedAddressInfo: any) => {
    setSelectedAddress(selectedAddressInfo);
    setSelectedAddress((prevAddress) => {
      handleOnClick("address", {
        ...prevAddress,
        altitude: Math.round(elevation || 0)
      });
      return selectedAddressInfo;
    });
  };


  const displayRisks = () => {
    if (loadingGeoRisks && !geoRisks) {
      return <p>Chargement des risques...</p>;
    } else {
      const risquesInondations = floodData && floodData?.zonesInondables.length > 0

      const risquesSismique = geoRisks?.typesRisques.some((typeRisque) =>
        typeRisque.risqueDetailList.some((risqueDetail) =>
          risqueDetail.libelleRisqueLong.includes("Séisme")
        )
      );

      // handleOnClick("risquesInondation", risquesInondations);
      // handleOnClick("risquesSismique", risquesSismique);
      return (
        <>
          <div className="d-flex flex-hor-start mg-b8 flex-center pad-l10">
            <img src={SismiqueIcon} alt="risque sismique" className="pad-r10" />
            <p className="font-16 text-blue">{`Risques sismique : ${risquesSismique ? "Oui" : "Non"
              }`}</p>
            {risquesSismique ? (
              <figure
                className="actions-btns"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => setGeoDetails([true, "Sismique"])}
              >
                {/* <img
                  className="cursor mg-l15 d-block"
                  src={EyeCircle}
                  alt="eye detail"
                /> */}
              </figure>
            ) : null}
          </div>
          <div className="d-flex flex-col-column flex-hor-start mg-b8 flex-center pad-l10">
            <img
              src={InondationIcon}
              alt="risque inondation"
              className="pad-r10"
            />
            <p className="font-16 text-blue mg-r5">{`Risques inondations : ${risquesInondations ? "Oui" : "Non"
              }`}</p>
            {risquesInondations ? (
              <figure
                className="actions-btns"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => setGeoDetails([true, "Inondations"])}
              >
                {/* <img
                  className="cursor mg-l15 d-block"
                  src={EyeCircle}
                  alt="eye detail"
                /> */}
              </figure>
            ) : null}
          </div>
        </>
      );
    }
  };


  return (
    <div id="create-contrat-mri">
      <div className="max-w1300 mg-0-auto ccm-body">
        <div
          className={`text-left border-box radius-15 mg-b30 ccmb-bg shadow d-flex ${geoRisks === null || geoRisks != null && isSmallScreen ? "flex-direction-col" : "flex-direction-row"}`}
        >
            {isLoadingAddress ? (
            <p className="font-20 pad-30 text-dark-light center">
              Récupération de l'adresse...
            </p>
          ) : selectedAddress?.latitude ? (
            <div className="pad-15">
              <div
                className="background-blue-light"
                style={
                    {
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      minHeight: "100%",
                    }
                }
              >
                <StreetViewPreview
                  isTabOrPhone={isTabOrPhone || isSide}
                  lat={selectedAddress.latitude}
                  lng={selectedAddress.longitude}
                />
                <div>
                  <p className="font-20 pad-l10 pad-t30 pad-b30 text-dark font-w700">
                    {selectedAddress.address}
                  </p>
                  <div>
                    <div className="d-flex flex-hor-start mg-b8 flex-center pad-l10">
                      <img
                        src={AltitudeIcon}
                        alt="altitude"
                        className="pad-r10"
                      />
                      <p className="font-16 text-blue">{`Altitude: env. ${Math.round(
                        elevation || 0
                      )}m`}</p>
                    </div>
                    {!loadingGeoRisks && geoRisks ? displayRisks() : null}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            
            <div
              className="d-flex flex-direction-col flex-center text-center"
            >
              {/* <img src={MapIcon} alt="carte icone" /> */}
              <p className="font-20 pad-30 text-dark font-w700 center">
                Quelle est l'adresse de la copropriété ?
              </p>
            </div>
          )}


       
          <MapContainer
            isTabOrPhone={isTabOrPhone || isSide}
            onAddressSelect={handleAddressSelect}
            address={address}
            setAddress={setAddress}
            showRisk={geoRisks === null ? true : false}
          />
        </div>
        {
            <div className="d-flex flex-hor-end">
              <button
                className="submitButton w-30"
                disabled={!selectedAddress?.address || isLoadingAddress}
                onClick={() => dispatch(handleNext())}
              >
                Étape suivante
              </button>
            </div>
        }
      </div>
    </div>
  );
};

export default Georisks;
