import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectDossierChoices } from "features/dossierSimuSlice";
import { ReactNode, useState } from "react";
import {ReactComponent as Home} from "assets/image/emprunteur/home (2).svg"
import edit from "assets/image/infoStepFour/edit.png"
import SituationEmprunteurEdit from "./situationEmprunteurEdit";

type Props = {
    setModalContent: (content: ReactNode) => void,
    setIsModalOpen: (value: boolean) => void
}


const SituationEmprunteur: React.FC<Props> = ({setModalContent, setIsModalOpen}) => {

    const dossierChoices = useAppSelector(selectDossierChoices);

    const openSituationEditForm = () => {
        setModalContent(<SituationEmprunteurEdit onCancel={() => setIsModalOpen(false)}/>);
        setIsModalOpen(true);
    }


    return (
        <>
            <div className="emprunteur-container w-90">
                {/* Mettre une div intermediaire pour centreer */}
                <div className="title-container">
                    <Home className="svg-primary mg-r10" style={{height: "20px", width: "20px"}}/>
                    <p>Situation de l'emprunt</p>
                </div>
                <div className="content-container">
                    <div>
                        <p>Projet: {dossierChoices?.Projet}</p>
                        <p>Situation: {dossierChoices?.Situation}</p>
                    </div>
                    <img src={edit} alt="edit" onClick={() => openSituationEditForm()}/>
                </div>
            </div>
        </>
    )
}

export default SituationEmprunteur