import { useAppDispatch, useAppSelector } from "app/hooks"
import EditGarantie from "./editGarantie"
import { selectModalType, setShowHeader} from "features/dossierSimuSlice";
import prevArrow from "assets/image/startStep/prevArrowBlack.svg";
import { ReactNode } from "react";

type Props = {
    isSmallScreen: boolean;
    setShowModal: (value:boolean) => void;
    children: ReactNode;
}

const ModalSimu: React.FC<Props> = ({isSmallScreen, setShowModal, children}) => {

    const dispatch = useAppDispatch();

    const closeModal = () => {
        dispatch(setShowHeader(true));
        setShowModal(false);
    }

    return (
        <div className={`${isSmallScreen ? "modal-container" : "d-flex flex-direction-col h-100 w-100"}`}>
            <div className="modal-retour">
                <img src={prevArrow} alt="loading" onClick={() => closeModal()}></img>
            </div>
                {children}
        </div>
    )
}

export default ModalSimu;