import { ReactComponent as PrevArrow } from "assets/image/startStep/prevArrow.svg";

const ContratExplain = ({ close, data }: any) => {
  return (
    <div id="contratExplain">
      <PrevArrow className="cursor prevArrow" onClick={close}></PrevArrow>
      <img src={data?.image} alt="loading" className="cst-image"></img>
      <h2 className="font-16 lh-19 mg-b18 main-color cst-title">
        {data?.designation}
      </h2>
      <p className="font-14 lh-22 w-90 mg-b26 cst-description">
        {data?.descriptionCourte}
      </p>
      <div className="scroll-y-auto" dangerouslySetInnerHTML={{__html: data?.descriptionLongue}} />
    </div>
  );
};

export default ContratExplain;
