import React from "react";

type Props = {
  lat: string | number;
  lng: string | number;
  isTabOrPhone?: boolean;
};

const StreetViewPreview: React.FC<Props> = ({
  lat,
  lng,
  isTabOrPhone = false,
}) => {
  const apiKey = process.env.REACT_APP_GMAPS_API_KEY;

  const imageUrl = `https://maps.googleapis.com/maps/api/streetview?size=435x300&location=${lat},${lng}&key=${apiKey}`;

  return (
    <div id="create-contrat-mri" className="d-flex flex-hor-center flex-ver-center">
      <img
        src={imageUrl}
        alt="Street View Preview"
        className="streetview"
        style={{ borderRadius: 15, width: isTabOrPhone ? "90%" : "90%" }}
      />
    </div>
  );
};

export default StreetViewPreview;
