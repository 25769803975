import { fetchNaturePret } from "features/Emprunteur/emprunteurAPI";
import { useEffect, useState } from "react";

export const useNaturePret = () => {
    const [listNaturePret, setListNaturePret] = useState<any>(); 

    useEffect(() => {
        const getNaturePret = async () => {
            const naturePret = await fetchNaturePret();
            setListNaturePret(naturePret);
        }

        getNaturePret();
    }, [])

    return listNaturePret;
}