import "../../../type.d.ts";
import phoneIcon from "../../../assets/image/stepFivePageOne/phone-icon.svg";
import paperPlane from "assets/image/common/paper-plane.svg";
import bigWarningCircle from "assets/image/common/big-warning-circle.svg";
import Information from "features/commonSteps/information";

const ConfirmStepTwo = () => {
  return (
    // <div id="confirmStepTwo">
    //   <div className="d-flex flex-direction-col flex-hor-center cst-body">
    //     <img src={paperPlane} alt="paperPlane" className="cstb-image"></img>
    //     <h2 className="main-color font-24 lh-32 mg-b14 cstb-title">
    //       Un grand merci !
    //     </h2>
    //     <p className="font-16 lh-25  mg-b25 cstb-description">
    //       Votre dossier est en route et un de nos gentils assureurs va s’en
    //       occuper dans les plus brefs délais.
    //     </p>
    //     <p className="font-16 lh-25 mg-b25 cstb-description">
    //       Vous serez averti automatiquement par email lorsque la souscription
    //       sera définitivement validée, cela peut prendre jusqu’à 48h.
    //     </p>
    //     <div className="w-100 d-flex flex-direction-col flex-ver-center cstb-content">
    //       <img
    //         src={bigWarningCircle}
    //         alt="bigWarningCircle"
    //         className="mg-b25"
    //       />
    //       <p className="font-15 lh-18 main-color">
    //         N’oubliez pas de saisir vos coordonnées de paiement sur votre compte
    //         client afin que votre souscription soit active
    //       </p>
    //     </div>

    //     <div className="buttonGroup">
    //       <button className="submitButton">Je me connecte</button>
    //     </div>
    //   </div>
    //   <div className="w-100 d-flex flex-direction-col flex-ver-center cto-footer">
    //     <p className="font-13 lh-19 mg-b5 ctofr1">
    //       Des questions ? Appelez-nous.
    //     </p>
    //     <div className="d-flex flex-ver-center mg-b7 ctofr2">
    //       <img src={phoneIcon} className="mg-r5" alt="phone" />
    //       <p className="mg-r8">0900 752 857</p>
    //       <h6>du lundi au vendredi de 9h à 17h30</h6>
    //     </div>
    //     <p className="font-9 lh-19 ctofr3">
    //       *prix d’un appel local + frais éventuels de votre opérateur
    //     </p>
    //   </div>
    // </div>
    <div id="confirmStepTwo">
      <Information type="GLI"></Information>
    </div>
  );
};

export default ConfirmStepTwo;
