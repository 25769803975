export function parsePersonneMorale(dataForm: any, gender: any, dataAddress: any) {
  const data = {
    designation: dataForm?.designation,
    raisonJuridique: dataForm.formeJuridique,
    adresse: { ...dataAddress, ligne2: dataForm?.anotherAddress },
    telephone: `${dataForm?.telephone}`,
    contacts: [{
      civiliteId: gender,
      nom: dataForm.nom,
      prenom: dataForm.prenom,
      telephone: dataForm.telephone,
      email: dataForm.email
    }],
    numeroSiret: dataForm.siret,
    rcsVille: dataForm?.rcsVille,
    rcsNumero: dataForm?.rcsNumero,
    capital: dataForm?.capital,
  };
  return data;
}