import { useState, useEffect } from "react";

import "../../type.d.ts";

import PiecesStepOne from "features/pieces/piecesStepOne";
import PiecesStepTwo from "features/pieces/piecesStepTwo";
import classNames from "classnames";
import { LocataireDoc } from "pages/mainStep/type";

const StepFour = ({ setShowHeader }: any) => {
  const [showEdit, setShowEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState<LocataireDoc>();

  const onShowEdit = (data: LocataireDoc) => {
    setShowEdit(true);
    setDataEdit(data);
  };

  useEffect(() => {
    setShowHeader(!showEdit);
  }, [showEdit]);

  return (
    <div id="pieces">
      <PiecesStepOne onShowEdit={onShowEdit} showEdit={showEdit} />
      <div className={classNames("editContainer", { active: showEdit })}>
        <PiecesStepTwo
          setShowEdit={setShowEdit}
          showEdit={showEdit}
          dataEdit={dataEdit}
        ></PiecesStepTwo>
      </div>
    </div>
  );
};

export default StepFour;
