import React, { useEffect, useState } from "react";
import CheckedIcon from "assets/image/mri/check-blue.svg";
import { useIsSmallScreen } from "hooks/isSmallScreen";

type Props = {
  label: string;
  icon: string;
  checked: boolean;
  length: number;
  tiny?: boolean;
  onClick: () => void;
  isSide?: boolean
};

const CheckedCard: React.FC<Props> = ({
  label,
  icon,
  checked,
  onClick,
  length,
  tiny = false,
  isSide
}) => {

  const isSmallScreen = useIsSmallScreen(993);


  // useEffect(() => {
  //   setIsSmallScreen(window.innerWidth < 993);
  //   const handleWindowResize = () => {
  //     setIsSmallScreen(window.innerWidth < 993);
  //   };

  //   window.addEventListener('resize', handleWindowResize);

  //   return () => {
  //     window.removeEventListener('resize', handleWindowResize);
  //   };
  // }, [])

  return (
    <>
    {!isSide ? (
      <div
        className={`d-flex flex-direction-col flex-ver-center flex-hor-center text-center cursor radius-15 mg-r5 mg-l5 mg-t5 mg-b5 pad-10-15 ${
          checked
            ? "bg-blue-light border-blue"
            : "bg-blue-light-3 border-grey-light"
        }`}
        style={
            { minWidth: `calc(${100 / length}% - 10%)` }
        }
        onClick={onClick}
      >
        {checked && (
          <div className="d-flex w-100 flex-hor-end">
            <img src={CheckedIcon} alt="Checked Icon" className="check-icon" />
          </div>
        )}
          <img src={icon} alt={label} className="mg-b10" style={{width: (!isSmallScreen ? "54px" : "34px"), height: (!isSmallScreen ? "54px" : "34px")}}/>
        <p
          className={`font-16 lh-19 font-w400 ${checked ? "font-w700" : ""} w-100`}
          style={{maxWidth: "159px", minWidth: "80px"}}
        >
          {label}
        </p>
      </div>)
    : 
      <div className="d-flex flex-ver-center flex-ver-center text-center mg-r10 mg-b10">
        <label className="custom-checkbox mg-r10 mg-t2">
        <input
          type="checkbox"
          name={label + Math.random().toString()}
          onChange={onClick}
          checked={checked}
          className=""
        />
        <span className="checkmark"></span>
        </label>
        <p>{label}</p>
      </div>
    }
    </>
  );
};

export default CheckedCard;
