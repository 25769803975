import { FormInstance, Select } from "antd"
import FormItem from "antd/es/form/FormItem";
import AmortissableForm from "./amortissableForm";
import { useState } from "react";

type Props = {
    form: FormInstance;
}

const PretRelais: React.FC<Props> = ({form}) => {
    const [remboursement, setRemboursement] = useState<any>("");

    return (
        <>
            <AmortissableForm form={form} />
            <div className="mg-b20 formItem">
                  <p>Avec remboursement des interets</p>
                  <FormItem name="remboursement"
                     rules={[
                        { required: true, message: "Veuillez rentrer ce champ" },
                        ]}
                  > 
                    <ul className="w-100 d-flex  radioGroup">
                        <li
                          className={`w-50 radius-4 cursor mg-r10 ${
                            remboursement === "Oui" ? "activeLi" : ""
                          }`}
                          onClick={() => {setRemboursement("Oui"); form.setFieldsValue({remboursement: "Oui"})}}
                        >
                          <span className="font-w600 font-14 lh-17">
                            {"Oui"}
                          </span>
                        </li>
                        <li
                          className={`w-50 radius-4 cursor mg-r10 ${
                            remboursement === "Non" ? "activeLi" : ""
                          }`}
                          onClick={() => {setRemboursement("Non"); form.setFieldsValue({remboursement: "Non"})}}
                        >
                          <span className="font-w600 font-14 lh-17">
                            {"Non"}
                          </span>
                        </li>
                    </ul>
                  </FormItem >
                </div>
        </>
    )
}
export default PretRelais