import React from "react";
import { useAppSelector } from "app/hooks";
import { selectDossierChoices } from "features/dossierSimuSlice";
import RadioButtonChoice from "features/components/radioButton/radioButton";

type Props = {
  handleOnClick: (
    fieldName: string,
    value: boolean | string | any
  ) => void;
  buildingIndex: number;
  handleAnswer: (
    buildingId: number,
    fieldName: string,
    selectedOption: string
  ) => void;
  isSide: boolean
};

const BuildingForm: React.FC<Props> = ({ buildingIndex, handleAnswer, isSide }) => {
  const mri = useAppSelector(selectDossierChoices);

  const building = mri && mri?.buildings?.buildings[buildingIndex];

  const renderQuestion = (question: any) => {
    return (
      <div key={question.fieldName} className="question-mri ">
        <p
          className="font-18 lh-22 text-dark cccbs2r1r1 mg-b10"
          style={{ textAlign: "left" }}
        >
          {question.title}
        </p>
        <div className="d-flex flex-hor-center cccbs2r1r2 mg-b30 question-mri grid-container">
          {question.options.map((option: string, index: number) => (
            <RadioButtonChoice
              key={index + Math.random()}
              large
              radio
              text={option}
              selected={question.selectedOption === option}
              handleOnClick={() =>
                handleAnswer(building.id, question.fieldName, option)
              }
              last={true}
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={`${isSide ? "side-view-form" : "w-100"}`}>
      <div className="d-flex mg-b20">
        <div className="flex-direction-col mg-r5">
          <div className="w-100 mg-b20" style={{ textAlign: "left" }}>
            <label
              className="font-16 text-dark w-100"
              style={{ textAlign: "left" }}
              htmlFor="floors"
            >
              Nombre d'étages
            </label>
          </div>
          <input
            name="floors"
            value={building.floors}
            onChange={(e) =>
              handleAnswer(building.id, "floors", e.target.value)
            }
            className="pr-db-input-bg"
            placeholder="Ex : 8"
          />
        </div>
        <div className="flex-direction-col mg-r10">
          <div className="w-100 mg-b20" style={{ textAlign: "left" }}>
            <label
              className="font-16 text-dark w-100"
              style={{ textAlign: "left" }}
              htmlFor="basements"
            >
              Nombre de sous-sols
            </label>
          </div>
          <input
            name="basements"
            value={building.basements}
            onChange={(e) =>
              handleAnswer(building.id, "basements", e.target.value)
            }
            className="pr-db-input-bg"
            placeholder="Ex : 3"
          />
        </div>
      </div>
      <div className="flex-direction-col">
        {building.fields.map((field: any) => renderQuestion(field))}
      </div>
    </div>
  );
};

export default BuildingForm;
