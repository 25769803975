import publicAPIInstance from "pages/connexion/publicProxy";
import { CreateAssureCoproDto, CreateCoproDto } from "./assureTypes";

export type Assure = {
  id: string
  identifiantPartenaire: string
  courtierId: string
  personneMoraleId: string
  personnePhysiqueId: string | null
  typePartenaire: number | null
}


export async function createAssuresCopro(
    createAssureCoproDto: CreateAssureCoproDto
  ): Promise<any> {
    if (createAssureCoproDto.personnesMorales && !createAssureCoproDto.personnesPhysiques) {
      createAssureCoproDto.personnesPhysiques = [];
    } else if (createAssureCoproDto.personnesPhysiques && !createAssureCoproDto.personnesMorales) {
      createAssureCoproDto.personnesMorales = [];
    }
  
      const response = await publicAPIInstance.post(
        '/api/backoffice/assures-coproprietes/create',
        createAssureCoproDto
      );
  
      return response.data;
  
  }
  
  export async function createNewCopro(createCoproDto: CreateCoproDto): Promise<any> {
      const response = await publicAPIInstance.post(
        '/api/backoffice/coproprietes/create',
        createCoproDto
      );
  
      return response.data;
  
  }

  export async function updateMRISimulation(data:any):Promise<any> {
    return (
      await publicAPIInstance.put(
        `/api/simulations/updateMRI`, data
      )
    )?.data
  }

  export async function createPersonnePhysique(data: any
    ): Promise<any> {
      return (
        await publicAPIInstance.post(
          `/api/backoffice/acteurs/personnes-physiques`, data
        )
      )?.data
    }

    export async function createPersonneMorale(data: any
      ): Promise<any> {
        return (
          await publicAPIInstance.post(
            `/api/backoffice/acteurs/personnes-morales`, data
          )
        )?.data
      }

  export async function editPersonnePhysique(data: any
      ): Promise<any> {
        return (
          await publicAPIInstance.put(
            `/api/backoffice/acteurs/personne-physique`, data
          )
        )?.data
      }

export async function createAssure(data: any
  ): Promise<Assure> {
    return (
      await publicAPIInstance.post(
        `/api/backoffice/acteurs/assure`, data
      )
    )?.data
  }

  export async function setSimulationRisqueAssure(params: { simulationId: string, risqueAssureId: string, type:string }) {
    return (
      await publicAPIInstance.put(
        `/api/simulations/gli/setSimulation${params.type}RisquesAssure?simulationId=${params.simulationId}&risqueAssureId=${params.risqueAssureId}`
        
      )
    )?.data  
  }

  export async function createOwner(data: any
    ): Promise<any> {
      return (
        await publicAPIInstance.post(
          `/api/backoffice/proprietairesBiensImmobiliers/proprietaire`, data
        )
      )?.data
    }

  export async function createRepresentant(data: any
    ): Promise<any> {
      return (
        await publicAPIInstance.post(
          `/api/backoffice/acteurs/representant`, data
        )
      )?.data
    }

    export async function createSyndicat(data: any
      ): Promise<any> {
        return (
          await publicAPIInstance.post(
            `/api/backoffice/acteurs/syndicat`, data
          )
        )?.data
      }