import { combineReducers } from "redux";
import monBienSlice from "features/monBien/monBienSlice";
import mainSlice from "pages/mainStep/mainSlice";
import locatairesSlice from "features/locataires/locatairesSlice";
import contratSlice from "features/contrat/contratSlice";
import piecesSlice from "features/pieces/piecesSlice";
import confirmationSlice from "features/confirmation/confirmationSlice";
import dossierSimuSlice from "features/dossierSimuSlice";
import assuresSlice from "features/assure/assureSlice";
import emprunteurSlice from "features/Emprunteur/emprunteurSlice";

const rootReducer = combineReducers({
  mainSlice: mainSlice,
  monBienSlice: monBienSlice,
  locatairesSlice,
  contratSlice,
  piecesSlice,
  confirmationSlice,
  dossierSimuSlice,
  assuresSlice,
  emprunteurSlice
});

export default rootReducer;
