import check from "../../../assets/image/stepTwo/checked.svg";
import { useAppSelector } from "app/hooks";
import { useEffect, useState } from "react";
import { getDetailLocataires } from "../pieces";
import manImage from "assets/image/common/man.svg";
import womanImage from "assets/image/common/woman.svg";
import { Spin } from "antd";
import { DetailLocataire } from "pages/mainStep/type";
import { useMemo } from "react";
import { Document } from "pages/mainStep/type";

const LocataireDetail = ({ data, onShowEdit }: any) => {
  const { simulationId } = useAppSelector((state) => state.mainSlice);

  const [detail, setDetail] = useState<DetailLocataire>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getDetail = async () => {
      setLoading(true);
      const res: any = await getDetailLocataires({
        simulationId,
        locataireId: data?.locataireId,
      });
      setLoading(false);
      setDetail(res?.data);
    };
    getDetail();
  }, [simulationId, data]);

  const active = useMemo(() => {
    const requiredList = data?.documents?.filter(
      (x: Document) => x?.isRequired
    );
    const doneUpload = data?.documents?.filter(
      (x: Document) => x?.documentStatus === x?.nombreDeDocuments
    );

    if (requiredList?.length !== doneUpload?.length) {
      return false;
    } else {
      return true;
    }
  }, [data]);

  return (
    <div
      className={`mg-b20  pso-item ${active ? "activeItem" : ""}`}
      onClick={() => onShowEdit(data)}
    >
      {loading ? (
        <Spin></Spin>
      ) : (
        <div>
          <img src={check} alt="check" className="pso-check" />
          <div className="w-100 d-flex flex-ver-top flex-hor-between mg-b8 psoir1">
            <img
              src={
                detail?.personnePhysique?.civiliteId ===
                "e9a44bbe-31cd-0181-e7cf-3a098cc8f214"
                  ? manImage
                  : womanImage
              }
              alt="gender"
            />
            <p className="font-12 lh-15">
              {active ? "Dossier complet" : "Complétez le dossier"}
            </p>
          </div>
          <p className="font-12 lh-15 mg-b5 psoir2">
            {detail?.situationProfessionnelle?.displayName}
          </p>
          <p className="font-15 lh-18 psoir3">
            {detail?.personnePhysique?.nomComplet}
          </p>
        </div>
      )}
    </div>
  );
};

export default LocataireDetail;
