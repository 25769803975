import { useState, useEffect, useId } from "react";
import dropdown from "assets/image/select/dropdown.svg";
interface Option {
  label: string;
  value: string | number;
}
const SelectComponent = ({
  onChange,
  value,
  options,
  placeholder,
}: {
  onChange?: any;
  value?: string | number;
  options?: Option | any;
  placeholder?: string;
  className?: string;
}) => {
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState("");

  const id = useId();

  useEffect(() => {
    if (value && options) {
      const find = options?.find((x: Option) => x.value === value);
      if (find) {
        setLabel(find?.label);
      }
    }
  }, [value]);

  useEffect(() => {
    window.addEventListener("click", function (e: any) {
      const select = document.getElementById(id);
      if (!select?.contains(e.target)) {
        setOpen(false);
      }
    });
    return () =>
      window.removeEventListener("click", function (e: any) {
        const select = document.getElementById(id);
        if (!select?.contains(e.target)) {
          setOpen(false);
        }
      });
  });

  const handleChange = (value: string | number) => {
    onChange(value);
    setOpen(false);
  };

  return (
    <div id={id} className="selectContainer" onClick={() => setOpen(!open)}>
      <div
        className={`w-100 d-flex flex-hor-between flex-ver-center cursor sc-header ${
          open ? "active" : ""
        }`}
      >
        {label ? (
          <span className="activeLabel">{label}</span>
        ) : (
          <span className="font-15 lh-18 placeholder">
            {placeholder || "Select"}
          </span>
        )}
        <img src={dropdown} alt="dropdown" className="mg-l5" />
      </div>
      {open && (
        <ul className="sc-body">
          {options?.length > 0 ? (
            options?.map((x: Option) => (
              <li
                className={`cursor ${value === x?.value ? "active" : ""}`}
                onClick={() => handleChange(x?.value)}
              >
                <span className="font-15 lh-18 main-color">{x?.label}</span>
              </li>
            ))
          ) : (
            <li className={`cursor`} onClick={() => setOpen(false)}>
              <span className="font-15 lh-18 main-color">Empty list</span>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default SelectComponent;
