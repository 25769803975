import publicAPIInstance from "pages/connexion/publicProxy";

export function connectSimulationApp() {
  return new Promise((resolve, reject) => {
    try {
      const res = publicAPIInstance.get(`api/backoffice/auth/connect-simulation-app`);

      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export function getCourtierBySubDomain(subDomain: string) {
  return new Promise((resolve, reject) => {
    try {
      const res = publicAPIInstance.get(`api/backoffice/acteurs/courtierBySubDomain?subDomain=${subDomain}`);

      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export function beginSimulation(natureCode: string, courtierId: string) {
  return new Promise((resolve, reject) => {
    try {
      const res = publicAPIInstance.get(`api/simulations/gli/begin-in-naturecode/${natureCode}/${courtierId}`);
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export function getTunnelsGLI() {
  return new Promise((resolve, reject) => {
    try {
      const res = publicAPIInstance.get(`api/backoffice/tunnels`);

      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export function uploadImage(id: string, data: any) {
  return new Promise((resolve, reject) => {
    try {
      const res = publicAPIInstance.put(
        `api/simulations/biens/${id}/biens/upload`,
        data
      );

      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export function workflow(code: string) {
  return new Promise((resolve, reject) => {
    try {
      const res = publicAPIInstance.get(
        `api/backoffice/tunnels-gli/workflow?tunnelId=${code}`
      );

      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export function simulationInfo(simulationId: string) {
  return new Promise((resolve, reject) => {
    try {
      const res = publicAPIInstance.get(
        `api/simulations/gli/${simulationId}`
      );

      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export async function getSimulationInfo(simulationId: string, simulationType: string) {
  return (
    await publicAPIInstance.get(
      `api/simulations/get${simulationType}?id=${simulationId}`
    ))?.data;
}

export function garantiesRetenues(param: {simulationId: string, tunnelId: string, loyerMensuel: number}) {
  return new Promise((resolve, reject) => {
    try {
      const res = publicAPIInstance.get(
        `api/backoffice/garanties-retenues/list/?SimulationId=${param.simulationId}&TunnelId=${param.tunnelId}&LoyerMensuel=${param.loyerMensuel}&IsContratIndividuel=true`
      );

      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export function createDevis(params : {simulationId: string, assureId: string | undefined, contratParentId: string | undefined} ) {
  return new Promise((resolve, reject) => {
    try {
      let url = `api/contrat-configurator/${params.simulationId}/devis`
      if(params.assureId != null && params.contratParentId != null) {
        url += `?assureId=${params.assureId}&contratParentId=${params.contratParentId}`
      }
      else if (params.assureId != null) {
        url += `?assureId=${params.assureId}`
      }
      else if(params.contratParentId != null) {
        url += `?contratParentId=${params.contratParentId}`
      }
      const res = publicAPIInstance.post(url);
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export async function downloadDocumentByStoredId(storedFileId: string):Promise<any> {
  return (
    await publicAPIInstance.get(
      `/api/backoffice/documents/download/${storedFileId}`,{
        responseType: 'blob',
        timeout: 30000,
      }
    )
  )?.data
}

export async function getPrixClientByGaranties(tunnelId: string, garantieTunnelId: string, franchiseClient?: number, plafondClient?: number) {
  return new Promise((resolve, reject) => {

    let url = `/api/backoffice/garanties-tunnels/prix-client?TunnelId=${tunnelId}&GarantieTunnelId=${garantieTunnelId}`
    if (franchiseClient != undefined) {
      url += `&FranchiseClient=${franchiseClient}`
    }
    if (plafondClient != undefined) {
      url += `&PlafondClient=${plafondClient}`
    }

    try {
        const res = publicAPIInstance.get(
          url
        )
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
}

export async function configureGarantiesSimulation(param: {simulationId:string, data:any}): Promise<any> {
  try {
    const response = await publicAPIInstance.post(`/api/contrat-configurator/${param.simulationId}/configurer`, param.data);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la création des garanties retenues :', error);
    throw error; // Renvoie l'erreur pour qu'elle puisse être gérée ailleurs si nécessaire
  }
}

export async function fetchPlafondPartiesByTunnel(tunnelId: string) {
  return (
    await publicAPIInstance.get(
      `/api/backoffice/tunnels/plafond-parties?tunnelId=${tunnelId}`
    )
  )?.data  
}