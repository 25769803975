import key from "assets/image/contrat/key.svg";

import { Form, message } from "antd";
import { useAppSelector } from "../../../app/hooks";
import { useNavigate } from "react-router-dom";
import { setAuthAction, setRouteList } from "pages/mainStep/mainSlice";
import { useDispatch } from "react-redux";
import { loginAction } from "features/contrat/contratSlice";
import { handleNextStep } from "helpers/handleNextStep";
import { useState } from "react";
import { CONNEXION_LABEL, CONNEXION_LOGIN } from "pages/mainStep/routeList";

const Login = ({ setShowRegister }: any) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { renderStepStartPageTwo, routeList } = useAppSelector(
    (state) => state.mainSlice
  );

  const handleSubmit = (form: any) => {
    setLoading(true);
    const dataSubmit = {
      userNameOrEmailAddress: form?.email,
      password: form?.pass,
    };

    navigate(handleNextStep(routeList));

    // dispatch(loginAction(dataSubmit)).then((data: any) => {
    //   if (data?.payload?.result === 1) {
    //     setLoading(false);
    //     dispatch(setAuthAction());
    //     message.success("login success !", 1);

    //     const newRouteList = routeList?.map((x: any) => {
    //       if (x?.label === CONNEXION_LABEL) {
    //         return {
    //           ...x,
    //           list: x?.list?.filter(
    //             (y: string) => !y?.includes(CONNEXION_LOGIN)
    //           ),
    //         };
    //       } else {
    //         return x;
    //       }
    //     });

    //     setTimeout(() => {
    //       navigate(handleNextStep(routeList));
    //     }, 1000);

    //     setTimeout(() => {
    //       dispatch(setRouteList(newRouteList));
    //     }, 2500);
    //   } else {
    //     setLoading(false);
    //     message.error("login fail !", 1);
    //   }
    // });
  };

  return (
    <div id="login">
      <img src={key} alt="laoding" className="houseImage"></img>
      <h2>Connectez-vous à votre compte</h2>
      <Form onFinish={handleSubmit} form={form}>
        <div className="inputGroup">
          <div className="formItem">
            <p>Adresse email</p>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
              ]}
            >
              <input placeholder="Email" className="input"></input>
            </Form.Item>
          </div>
          <div className="formItem">
            <p>Choisissez un mot de passe</p>
            <Form.Item
              name="pass"
              rules={[{ required: true, message: "Please input your pass!" }]}
            >
              <input
                placeholder="Passe"
                className="input"
                type="password"
              ></input>
            </Form.Item>
            <a>Mot de passe oublié ?</a>
          </div>
        </div>

        <div className="buttonGroup">
          <button className="submitButton" type="submit" disabled={loading}>
            <p>Je me mon connecte</p>
          </button>
          <button
            type="button"
            onClick={() => setShowRegister(true)}
            disabled={loading}
          >
            Créer un compte
          </button>
        </div>
      </Form>
    </div>
  );
};

export default Login;
