import key from "assets/image/contrat/key.svg";
import { useEffect, useState } from "react";
import { Form, message } from "antd";

import { useNavigate } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import { registerAction } from "features/contrat/contratSlice";
import { useDispatch } from "react-redux";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";

const Register = ({ showRegister, setShowRegister }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [dataAddress, setDataAddress] = useState({});

  const check = Form.useWatch("check", form);

  const handleFormatPlace = (place: any) => {
    const listPlace = place?.address_components;

    const googlePlaceId = place?.place_id;
    const codePostal = listPlace?.find((x: any) =>
      x?.types?.includes("postal_code")
    )?.long_name;
    const country = listPlace?.find((x: any) =>
      x?.types?.includes("country")
    )?.long_name;
    const city = listPlace?.find((x: any) =>
      x?.types?.includes("locality")
    )?.long_name;
    const address = place?.formatted_address?.split(",")?.[0];
    geocodeByAddress(place?.formatted_address)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        const dataAddress = {
          latitude: lat,
          longitude: lng,
          pays: country,
          ville: city,
          codePostal,
          adresseFormatee: address
            ? `${address},${city},${country}`
            : place?.formatted_address,
          googlePlaceId,
          ligne1: address,
        };
        setDataAddress(dataAddress);

        form.setFieldsValue({
          address: address,
          ville: `${city}`,
          code: codePostal,
        });
      });
  };

  const handleSubmit = (form: any) => {
    const dataSubmit = {
      userName: form?.name,
      emailAddress: form?.email,
      password: form?.pass,
      appName: "Angular",
      returnUrl: "",
      returnUrlHash: "",
      captchaResponse: "",
    };
    dispatch(registerAction(dataSubmit)).then((data: any) => {
      if (data?.error) {
        message.error("Register fail !");
      } else {
        setShowRegister(false);
        message.success("Register success !");
      }
    });
  };

  useEffect(() => {
    if (showRegister) {
      const container = document.getElementById("register");
      container?.scrollTo({ top: 0 });
    }
  }, [showRegister]);

  return (
    <div id="register">
      <img src={key} alt="laoding" className="houseImage"></img>
      <h2>Créez votre compte</h2>
      <Form onFinish={handleSubmit} form={form}>
        <div className="formItem">
          <p>Nom</p>
          <Form.Item
            name="name"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <input placeholder="DURAND" className="input"></input>
          </Form.Item>
        </div>
        <div className="formItem">
          <p>Prénom</p>
          <Form.Item
            name="subName"
            rules={[{ required: true, message: "Please input your sub name!" }]}
          >
            <input placeholder="Marc" className="input"></input>
          </Form.Item>
        </div>
        <div className="formItem">
          <p>Email</p>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "The input is not valid E-mail!",
              },
            ]}
          >
            <input placeholder="Son adresse email" className="input"></input>
          </Form.Item>
        </div>
        <div className="formItem">
          <p>Adresse</p>
          <Form.Item
            name="address"
            rules={[{ required: true, message: "Please input your address!" }]}
          >
            <Autocomplete
              className="input"
              id={"countryAnother" + Math.floor(Math.random() * 10000)}
              placeholder="Ex : 29 rue des Apennins"
              apiKey="AIzaSyCCelMiuDG-cQfWRb0KWDyPD_glL2hrwPM"
              onPlaceSelected={(place) => handleFormatPlace(place)}
              options={{
                types: ["geocode", "establishment"],
                componentRestrictions: { country: "fr" },
              }}
            />
          </Form.Item>
        </div>
        <div className="formItem">
          <p>Code postal</p>
          <Form.Item
            name="code"
            rules={[
              { required: true, message: "Please input your code postal!" },
            ]}
          >
            <input placeholder="Ex : 75017" className="input"></input>
          </Form.Item>
        </div>

        <div className="formItem">
          <p>Ville</p>
          <Form.Item
            name="ville"
            rules={[{ required: true, message: "Please input your city!" }]}
          >
            <input placeholder="Ex : PARIS" className="input"></input>
          </Form.Item>
        </div>
        <div className="formItem">
          <p>Complément d'adresse</p>
          <Form.Item name="anotherAddress">
            <input
              placeholder="Ex : Au fond de la cour"
              className="input"
            ></input>
          </Form.Item>
        </div>
        <div className="formItem">
          <p>Téléphone</p>
          <Form.Item
            name="phone"
            rules={[{ required: true, message: "Please input your phone!" }]}
          >
            <input
              placeholder="+33"
              className="input"
              type="number"
              min={0}
              onKeyDown={(e: any) =>
                (e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 189) &&
                e.preventDefault()
              }
            ></input>
          </Form.Item>
        </div>
        <div className="formItem">
          <p>Choisissez un mot de passe</p>
          <Form.Item
            name="pass"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const pattern =
                    /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
                  const pass = getFieldValue("pass");
                  const res = pass.match(pattern);

                  if (pass?.length < 6) {
                    if (pass?.length === 0) {
                      return Promise.reject(
                        new Error("Please input your password!")
                      );
                    } else {
                      return Promise.reject(
                        new Error("Pass must be at least 6 characters!")
                      );
                    }
                  } else {
                    if (res) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        new Error(
                          "Pass must includes special character,one uppercase letter,number!"
                        )
                      );
                    }
                  }
                },
              }),
            ]}
          >
            <input className="input" type="password"></input>
          </Form.Item>
        </div>
        <div className="formItem">
          <p>Confirmez votre mot de passe</p>
          <Form.Item
            name="repeatPass"
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("pass") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <input className="input" type="password"></input>
          </Form.Item>
        </div>
        <div className="checkboxGroup">
          <Form.Item name="check" valuePropName="checked">
            <input type="checkbox"></input>
          </Form.Item>
          <p>
            J’accepte les <span>conditions d’utilisation</span> de Confiancia
          </p>
        </div>
        <div className="buttonGroup">
          <button className="submitButton" type="submit" disabled={!check}>
            <p>Je crée mon compte</p>
          </button>
          <button type="button" onClick={() => setShowRegister(false)}>
            J'ai déjà un compte
          </button>
        </div>
      </Form>
    </div>
  );
};

export default Register;
