import { PageTransition } from "@steveeeie/react-page-transition";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { resetDossierSimu, resetDossierStore, selectDirection, selectDossierIntermediateStep, selectDossierStepActive, selectDossierType, selectShowHeader } from "features/dossierSimuSlice";
import { useEffect } from "react";
import StepOne from "./stepOne";
import StepTwo from "../components/choiceGarantie/stepTwo";
import { beginSimulationAction, getSimulationData, resetMainStore, setInfoUserAction } from "pages/mainStep/mainSlice";
import { useIsSmallScreen } from "hooks/isSmallScreen";
import Information from "features/commonSteps/information";
import SimulationTerminee from "features/commonSteps/simulationTerminee";
import { resetAssureStore } from "features/assure/assureSlice";
import { resetConfirmationStore } from "features/confirmation/confirmationSlice";
import { resetContratStore } from "features/contrat/contratSlice";
import { resetEmprunteurStore } from "features/Emprunteur/emprunteurSlice";
import { resetLocataireSlice } from "features/locataires/locatairesSlice";
import { resetPiecesStore } from "features/pieces/piecesSlice";


const CBNOSimu: React.FC = () => {

    const activeStep = useAppSelector(selectDossierStepActive);
    const intermediateStep = useAppSelector(selectDossierIntermediateStep);
    const dossierType = useAppSelector(selectDossierType);
    const direction = useAppSelector(selectDirection);
    const dispatch = useAppDispatch();
    const { courtierId, simulationId, infoUser} = useAppSelector(
        (state) => state.mainSlice
      ); 
    const showHeader = useAppSelector(selectShowHeader);
    const isSmallScreen = useIsSmallScreen(567);

    useEffect(() => {
        if (dossierType != "cbno") {
            dispatch(resetDossierSimu());
            dispatch(resetDossierStore());
            dispatch(resetAssureStore());
            dispatch(resetConfirmationStore());
            dispatch(resetContratStore());
            dispatch(resetEmprunteurStore());
            dispatch(resetLocataireSlice());
            dispatch(resetPiecesStore());
            dispatch(resetMainStore());
            dispatch(setInfoUserAction(null));
            localStorage.clear();
            const natureCode = "cbno";
            if (courtierId) {
                dispatch(beginSimulationAction({natureCode, courtierId}));
            }
        }
    }, [courtierId])

    return (
        <PageTransition
        preset={direction}
        transitionKey={activeStep?.toString()}
        enterAnimation={""}
        exitAnimation={""}
      >
        <div className={`${activeStep === 0 && intermediateStep === 3 || activeStep > 1 || activeStep === 1 ? `${!showHeader && isSmallScreen ? "form-modal" : "form-bien"}` : "form"}`}>

        {(() => {
                switch (activeStep) {
                    case 0:
                        return <StepOne />
                    case 1:
                        return <StepTwo />
                    case 2:
                        return <Information type="CBNO"/>
                    case 3: 
                        return <SimulationTerminee />
                }
            })()}
        </div>
        </PageTransition>
    );
}

export default CBNOSimu