import { useAppDispatch, useAppSelector } from "app/hooks";
import { resetDossierSimu, resetDossierStore, selectDossierStepActive, selectDossierType } from "features/dossierSimuSlice";
import { beginSimulationAction, resetMainStore } from "pages/mainStep/mainSlice";
import { useEffect  } from "react";
import ProjetIndex from "./Projet/stepOneIndex";
import MainStepEmprunteur from "./mainStep/mainStep";
import { resetAssureStore } from "features/assure/assureSlice";
import { resetConfirmationStore } from "features/confirmation/confirmationSlice";
import { resetContratStore } from "features/contrat/contratSlice";
import { resetEmprunteurStore } from "./emprunteurSlice";
import { resetLocataireSlice } from "features/locataires/locatairesSlice";
import { resetPiecesStore } from "features/pieces/piecesSlice";
import TarificateurEmp from "./TarificateurEmp/tarificateurEmp";
import { useIsMounted } from "hooksGoogleMaps";
import { useIsSmallScreen } from "hooks/isSmallScreen";
import Quotite from "./Quotite/quotite";
import SimulationTerminee from "features/commonSteps/simulationTerminee";
import paperplane from "assets/image/common/paper-plane.svg"

const EmprunteurSimu: React.FC = () => {

    const activeStep = useAppSelector(selectDossierStepActive);
    const dispatch = useAppDispatch();
    const { courtierId, simulationId } = useAppSelector(
        (state: any) => state.mainSlice
      ); 
    const dossierType = useAppSelector(selectDossierType);
    const isSmallScreen = useIsSmallScreen(567);

    const resetSimu = () => {
      dispatch(resetDossierSimu());
      dispatch(resetMainStore());
      dispatch(resetDossierStore());
      dispatch(resetAssureStore());
      dispatch(resetConfirmationStore());
      dispatch(resetContratStore());
      dispatch(resetEmprunteurStore());
      dispatch(resetLocataireSlice());
      dispatch(resetPiecesStore());
    }


    //Ce useEffet permet de reset le store si jamais l'ancienne simulation n'etait pas une simulation Emprunteur
    useEffect(() => {
      if (dossierType != "emprunteur")
      {
        dispatch(resetDossierSimu());
        dispatch(resetMainStore());
        dispatch(resetDossierStore());
        dispatch(resetAssureStore());
        dispatch(resetConfirmationStore());
        dispatch(resetContratStore());
        dispatch(resetEmprunteurStore());
        dispatch(resetLocataireSlice());
        dispatch(resetPiecesStore());
        localStorage.clear();
      }
    }, [])

    useEffect(() => {
        const natureCode = "EMP";
        if (courtierId && dossierType != "emprunteur")
            dispatch(beginSimulationAction({natureCode, courtierId}));
    }, [courtierId])

    return (
        <>
        {(() => {
            switch (activeStep) {
                case 0:
                    return (
                      <ProjetIndex />
                    )
                case 1:
                    return (
                      <MainStepEmprunteur />
                    )
                case 2:
                  return (
                  <div id="tarificateur-emp">
                          <TarificateurEmp/>
                  </div>
                  )
                case 3:
                  return (
                    <div id="quotite">
                        <Quotite/>
                    </div>
                  )
                case 4:
                  return (
                    <div id="quotite">
                        <div className="container">
                          <div className="d-flex flex-direction-col choice-garantie flex-ver-center">
                          <div className="d-flex flex-direction-col flex-hor-center flex-ver-center mg-t30">
                              <img src={paperplane} alt="loading" className="houseImage mg-t20"></img>
                              <h2 className="mg-b30 mg-t20">La simulation est terminée</h2>
                          </div>
                          <div className=" w-90 text-center mg-b40">
                              <p className=" text-dark-light">Vous serez averti automatiquement par email lorsque la souscription sera définitivement validée, cela peut prendre jusqu'a 48h.</p>
                          </div>
                          <button className="submitButton w-50" onClick={() => resetSimu()}>
                                  Retour a mon espace personnel
                          </button>
                          </div>
                        </div>
                    </div>
                  )
            }
        })()}
    </>
    );
}
export default EmprunteurSimu