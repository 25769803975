import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { handleNext, selectDossierChoices } from "features/dossierSimuSlice";
import RadioButtonChoice from "features/components/radioButton/radioButton";
import { fetchPlafondPartiesByTunnel, getSimulationInfo } from "pages/mainStep/mainStepAPI";
import { useLocation } from "react-router-dom";

type Props = {
  handleOnClick: (
    fieldName: string,
    value: boolean | string | any
  ) => void;
  isSide?: boolean
};

const CoOwnerShipEleven: React.FC<Props> = ({ handleOnClick, isSide = false }) => {
  const mri = useAppSelector(selectDossierChoices);
  const dispatch = useAppDispatch();
  const [optionsPartiesCommunes, setOptionsPartiesCommunes] = useState<number[]>([]);
  const [optionsPartiesPrivatives, setOptionsPartiesPrivatives] = useState<number[]>([]);
  const { simulationId } = useAppSelector((state:any) => state?.mainSlice);
  const location = useLocation();

  const [answers, setAnswers] = useState(
    mri && mri.propertyCoverageAmount
      ? mri.propertyCoverageAmount
      : [
        {
          fieldName: "commonAreasCoverageAmount",
          title: "Montant maximum du contenu des parties communes à assurer",
          selectedOption: "",
        },
        {
          fieldName: "privateAreasCoverageAmount",
          title:
            "Montant maximum du contenu des parties privatives à assurer (cas des appartements meublés)",
          selectedOption: "",
        },
      ]
  );

  useEffect(() => {
    getPlafondPartiesOptions()
    handleOnClick("propertyCoverageAmount", answers);
  }, [])

  const handleAnswer = (selectedOption: string, answerIndex: number) => {
    const payload = {
      ...answers[answerIndex],
      selectedOption: selectedOption,
    };

    const newAnswers = [...answers];

    newAnswers[answerIndex] = payload;

    setAnswers([...newAnswers]);

    handleOnClick("propertyCoverageAmount", newAnswers);
  };

  const getPlafondPartiesOptions = async () => {
    await getSimulationInfo(simulationId, location.pathname.split("/")[1].toUpperCase()).then(async (res) => {
      await fetchPlafondPartiesByTunnel(res?.tunnelId as string).then((res: any) => {
        setOptionsPartiesCommunes(res.partiesCommunes);
        setOptionsPartiesPrivatives(res.partiesPrivatives);
      })
    }).catch();
  }

  const renderQuestion = (question: any, answerIndex: number) => {
    const options = answerIndex === 0 ? optionsPartiesCommunes : optionsPartiesPrivatives;

    return (
      <div key={question.fieldName} className={`mg-b30 `}>
        <p
          className={` lh-22  cccbs2r1r1 mg-b10 ${!isSide ? "text-dark font-16" : "text-dark-light font-14"}`}
          style={{ textAlign: "left" }}
        >
          {question.title}
        </p>
        <div className={`d-flex ${!isSide ? "flex-hor-center cccbs2r1r2" : "w-100"} cccbs2r1r2  question-mri`}>
        {options?.map((option: number, index: number) => (
            <RadioButtonChoice
              key={index + Math.random()}
              large
              radio
              text={option.toString() + " €"}
              selected={question.selectedOption == option}
              handleOnClick={() => handleAnswer(option.toString(), answerIndex)}
              last={index === options?.length - 1}
              isSide={isSide === true ? true : false}
            />
          ))}
        </div>
      </div>
    );
  };

  const areAllQuestionsAnswered = () => {
    return answers.every((question: any) => question.selectedOption !== "");
  };

  return (
    <div className={`${!isSide ? "max-w1300 mg-0-auto ccc-body-step-2" : ""}`}>
      <div className="w-100 text-center border-box radius-15 cccbs2r1">
        <div className={`${!isSide ? "mg-b50" : ""}`}>
          <div className="d-flex flex-center flex-direction-col mg-b20">
            <p className="font-20 text-dark mg-b30">
              Quel est le{" "}
              <span className="font-w700">
                montant à couvrir des biens mobiliers de la copropriété ?
              </span>{" "}
            </p>
            <div className={`block column ${!isSide ? "w-95" : "w-100"}`}>
              {answers.map((question: any, index: number) =>
                renderQuestion(question, index)
              )}
            </div>
          </div>
        </div>
      </div>
      {
            <div className="d-flex flex-hor-end mg-t10">
              <button
                className="submitButton w-30"
                disabled={!areAllQuestionsAnswered()}
                onClick={() => dispatch(handleNext())}
              >
                Étape suivante
              </button>
            </div>
        }
    </div>
  );
};

export default CoOwnerShipEleven;
