import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { handleNext, selectDossierChoices } from "features/dossierSimuSlice";
import InfoBlock from "features/components/infoblock/infoblock";

type Props = {
  handleOnClick: (
    fieldName: string,
    value: boolean | string | any
  ) => void;
};

const CoOwnerShipTwo: React.FC<Props> = ({ handleOnClick }) => {
  const mri = useAppSelector(selectDossierChoices);
  const [surface, setSurface] = useState(mri && mri.surface ? mri.surface : "");
  const dispatch = useAppDispatch()

  const handleChange = (value: string) => {
    setSurface(value);
    setSurface((prevSurface: string) => {
      handleOnClick("surface", prevSurface);
      return value;
    });
  };

  const handleSubmit = () => {
      dispatch(handleNext());
  }

  return (
    <div className="max-w1300 mg-0-auto ccc-body-step-2">
      <div className="w-100 text-center border-box radius-15 cccbs2r1">
        <div className="mg-b50">
          <div>
            <p className="font-18 lh-22 text-dark cccbs2r1r1">
              Quelle est la{" "}
              <span className="font-w700">surface de la copropriété ?</span>
            </p>
          </div>
        </div>
        <div
          className="d-flex flex-hor-center cccbs2r1r2 mg-30"
          style={{ margin: 30 }}
        >
          <div className="block" style={{ padding: "16px 10vw" }}>
            <input
              value={surface}
              onChange={(e) => handleChange(e.target.value)}
              className="pr-db-input-bg"
              placeholder="Ex : 3000 m²"
            />
          </div>
        </div>
        <InfoBlock text="Confiancia propose des produits adaptés à toutes les surfaces même les plus petites" />
      </div>
      {
          <div className="d-flex flex-hor-end mg-t10">
          <button
            className="submitButton w-30"
            disabled={!surface}
            onClick={() => handleSubmit()}
          >
            Étape suivante
          </button>
        </div>
      }
    </div>
  );
};

export default CoOwnerShipTwo;
