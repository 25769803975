import React from "react";

type Props = {
  text: string;
  handleOnClick: () => void;
  last: boolean;
  selected: boolean;
  radio: boolean;
  large?: boolean;
  isSide?: boolean;
};

const RadioButtonChoice: React.FC<Props> = ({
  text,
  handleOnClick,
  last,
  radio,
  selected,
  large = false,
  isSide
}) => {
  const buttonClassName = `d-flex flex-ver-center flex-hor-center text-center cursor radius-8 ${
    last ? "" : "mg-r15"
  } cccbs2r1r2-item2 ${selected ? "selected" : ""} ${large ? "large" : ""}`;

  return (
    <div
      className={`d-flex  flex-ver-center flex-hor-center text-center cursor radius-8 ${buttonClassName} cccbs2r1r2-item2 ${isSide === true ? "cccbs2r1r2-item3" : ""}`}
      onClick={handleOnClick}
    >
      {radio && (
        <div className="radio-button mg-r15 mg-l5">
          <label className="d-flex">
            <input
              key={Math.random() + 1}
              type="radio"
              name={`choice ${Math.random()}`}
              checked={selected}
              onChange={handleOnClick}
            />
            <span className="radio-mark"></span>
          </label>
        </div>
      )}
      <p className="font-16 lh-19 font-w400 text-dark">{text}</p>
    </div>
  );
};

export default RadioButtonChoice;
