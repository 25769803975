import phoneIcon from "../../../assets/image/stepFivePageOne/phone-icon.svg";
import arrow from "../../../assets/image/startStep/arrow.svg";

import noMoney from "assets/image/common/no-money.svg";
import shakingHand from "assets/image/common/shaking-hand.svg";

import { getGarantiesRetenues } from "pages/mainStep/mainSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect } from "react";

const ConfirmStepOne = ({ 
  handleNextPage 
}: any) => {
  const dispatch = useAppDispatch();
  const { garantiesRetenues, simulationId, simulation } = useAppSelector((state:any) => state?.mainSlice);

  let totalGarantie = 0;
  garantiesRetenues?.map((garantie: any) => {
    totalGarantie += parseFloat(garantie.prixClient);
  });

  useEffect(() => {
    dispatch(getGarantiesRetenues({
      simulationId: simulationId, 
      tunnelId: simulation?.tunnelId, 
      loyerMensuel: simulation?.bienImmobilier?.loyerMensuel
    }));
  }, [dispatch, simulationId]);
  
  return (
    <div id="confirmStepOne">
      <div className="w-100 d-flex flex-direction-col cto-body">
        <img src={shakingHand} alt="shakingHand" className="ctob-image"></img>
        <h2 className="font-24 lh-32 main-color mg-b14 ctob-title">
          Votre dossier est finalisé !
        </h2>
        <span className="font-16 lh-25 mg-b22 ctob-description">
          Vous n’êtes plus qu’à un clic d’être assuré…
        </span>
        <div className="w-100 d-flex flex-direction-col ctob-table">
          <div className="mg-b21 ctobt-header">
            <p className="font-16 lh-19">Vos garanties</p>
          </div>
          {garantiesRetenues?.map((garantie: any) => (
            <div className="w-100 d-flex flex-ver-center mg-b24 ctobt-item">
              <img src={noMoney} alt="noMoney" className="mg-r10 ctobtic1"></img>
              <div className="d-flex flex-ver-end flex-hor-between ctobtic2">
                <div className="ctobtic2c1">
                  <p className="font-14 lh-17">{garantie.garantieTunnel.descriptionCourte}</p>
                </div>
                <p className="font-15 lh-24 main-color ctobtic2c2">
                  {(garantie.prixClient / 12).toFixed(2)} € <span className="font-12 lh-24">/ mois</span>
                </p>
              </div>
            </div>
          ))}
          <div className="d-flex flex-direction-col ctobt-ad">
            <p className="text-white font-13 lh-18 ctobtar1">Votre assurance</p>
            <p className="text-white font-20 lh-24 ctobtar2">
              {(totalGarantie / 12).toFixed(2)} € <span className="font-14 lh-24">/ mois</span>
            </p>
            <p className="text-white font-12 lh-24 ctobtar3">
              soit {(totalGarantie).toFixed(2)}€ /an
            </p>
          </div>
        </div>
        <div className="buttonGroup">
          <button onClick={handleNextPage} className="submitButton">
            Je valide mes garanties
            <img src={arrow} alt="loading"></img>
          </button>
        </div>
      </div>
      <div className="w-100 d-flex flex-direction-col flex-ver-center cto-footer">
        <p className="font-13 lh-19 mg-b5 ctofr1">
          Des questions ? Appelez-nous.
        </p>
        <div className="d-flex flex-ver-center mg-b7 ctofr2">
          <img src={phoneIcon} className="mg-r5" alt="phone" />
          <p className="mg-r8">0900 752 857</p>
          <h6>du lundi au vendredi de 9h à 17h30</h6>
        </div>
        <p className="font-9 lh-19 ctofr3">
          *prix d’un appel local + frais éventuels de votre opérateur
        </p>
      </div>
    </div>
  );
};

export default ConfirmStepOne;
