import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Situation, TypeList, Civilites } from "pages/mainStep/type";
import {
  situationsList,
  typesList,
  civilitesList,
  addLocataires,
  editLocatairesItem,
} from "./locataires";

export interface CounterState {
  value: number;
  status: "idle" | "loading" | "failed";
  loading: boolean;
  situationsList: Situation[];
  typesList: TypeList[];
  civilites: Civilites[];
}

const initialState: CounterState = {
  value: 0,
  status: "idle",
  loading: false,
  situationsList: [],
  typesList: [],
  civilites: [],
};

export const getSituationsList = createAsyncThunk(
  "GET_SITUATIONS_LIST",
  async (_, { rejectWithValue }) => {
    try {
      const res: any = await situationsList();
      return res?.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const getTypesList = createAsyncThunk(
  "GET_TYPES_LIST",
  async (_, { rejectWithValue }) => {
    try {
      const res: any = await typesList();
      return res?.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const getCivilitesList = createAsyncThunk(
  "GET_CIVILITES_LIST",
  async (_, { rejectWithValue }) => {
    try {
      const res: any = await civilitesList();
      return res?.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const addLocatairesAction = createAsyncThunk(
  "ADD_LOCATAIRES_ACTION",
  async (data: any, { rejectWithValue }) => {
    try {
      const res: any = await addLocataires(data);
      return res?.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const editLocatairesAction = createAsyncThunk(
  "EDIT_LOCATAIRES_ACTION",
  async (data: any, { rejectWithValue }) => {
    try {
      const res: any = await editLocatairesItem(data);
      return res?.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const locatairesSlice = createSlice({
  name: "locataires",
  initialState,
  reducers: {
    resetLocataireSlice(state) {
      state = initialState;
    }
  },

  extraReducers(builder) {
    builder
      .addCase(getSituationsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSituationsList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getSituationsList.fulfilled, (state, action) => {
        state.loading = false;
        state.situationsList = action.payload;
      })
      .addCase(getTypesList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTypesList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getTypesList.fulfilled, (state, action) => {
        state.loading = false;
        state.typesList = action.payload;
      })
      .addCase(getCivilitesList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCivilitesList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCivilitesList.fulfilled, (state, action) => {
        state.loading = false;
        state.civilites = action.payload;
      })
      .addCase(addLocatairesAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(addLocatairesAction.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addLocatairesAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(editLocatairesAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(editLocatairesAction.rejected, (state) => {
        state.loading = false;
      })
      .addCase(editLocatairesAction.fulfilled, (state, action) => {
        state.loading = false;
      });
  },
});

export const {
  resetLocataireSlice
} = locatairesSlice.actions;

export default locatairesSlice.reducer;
