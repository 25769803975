import { PageTransition } from "@steveeeie/react-page-transition";
import { useEffect, useMemo, useState } from "react";
import profile from "../../assets/image/stepTwo/profile.svg";
import arrow from "../../assets/image/startStep/arrow.svg";

import avatar from "../../assets/image/stepTwo/avatar.svg";
import "../../type.d.ts";
import checked from "../../assets/image/stepTwo/checked.svg";

import alert from "assets/image/common/alert.svg";
import ItemOne from "features/locataires/itemOne";
import ItemTwo from "features/locataires/itemTwo";
import ItemThree from "features/locataires/itemThree";
import ItemFour from "features/locataires/itemFour";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { deleteLocatairesItem } from "features/locataires/locataires";
import { ReactComponent as Plus } from "../../assets/image/locataire/plus.svg";
import manImage from "assets/image/common/man.svg";
import womanImage from "assets/image/common/woman.svg";
import baloon from "assets/image/common/baloon.svg";
import manFail from "assets/image/common/man-fail.svg";

import {
  resetMainStore,
  setFamilyAction,
  setIndexFamily,
  setInfoUserAction,
  setRenderInfoFour,
  setRenderStepTwoPageFour,
} from "../mainStep/mainSlice";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getSituationsList,
  getTypesList,
  getCivilitesList,
  resetLocataireSlice,
} from "features/locataires/locatairesSlice";
import { ListFamily } from "pages/mainStep/type";
import {
  LOCATAIRES3,
  LOCATAIRES4,
  LOCATAIRES3STEP4,
} from "pages/mainStep/routeList";
import classNames from "classnames";
import { handleNextStep } from "helpers/handleNextStep";
import { resetDossierSimu, resetDossierStore } from "features/dossierSimuSlice";
import { resetAssureStore } from "features/assure/assureSlice";
import { resetConfirmationStore } from "features/confirmation/confirmationSlice";
import { resetContratStore } from "features/contrat/contratSlice";
import { resetEmprunteurStore } from "features/Emprunteur/emprunteurSlice";
import { resetPiecesStore } from "features/pieces/piecesSlice";

const Locataires = ({ setShow, show, valueList, setValueList }: any) => {

  const [key, setKey] = useState("");
  const [oldKey, setOldKey] = useState(0);
  const [direction, setDirection] = useState("moveToLeftFromRight");
  const [numberFamily, setNumberFamily] = useState(0);
  const [step, setStep] = useState("");
  const [oldStep, setOldStep] = useState(0);
  const [directionStep, setDirectionStep] = useState("moveToLeftFromRight");

  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { listFamily, indexFamily, simulationId, renderInfoFour, routeList } =
    useAppSelector((state: any) => state.mainSlice);


  //Gestion des différentes étapes du locataire
  const routeListLocataires = useMemo(() => {
    const target = routeList?.find((x: any) =>
      x?.list?.includes(location?.pathname)
    );
    const routes: any = [];
    let stepChildren = 1;
    target?.list?.forEach((x: any, index: number) => {
      if (x?.includes(LOCATAIRES3)) {
        const data = {
          key: 3,
          link: LOCATAIRES3,
          children: routes[2]?.children
            ? [...routes[2].children, { step: stepChildren, link: x }]
            : [{ step: stepChildren, link: x }],
        };
        stepChildren += 1;
        routes[2] = data;
      } else {
        routes.push({ key: index + 1, link: x });
      }
    });
    return routes;
  }, [routeList, listFamily, indexFamily]);

  const handleToStepThree = () => {
    if (listFamily?.length === 0) {
      setDirection("moveToLeftFromRight");
      setTimeout(() => {
        const newFamily: any = Array.from({ length: numberFamily }, (a, b) => ({
          value: b,
          step1: {},
          step2: {},
          step3: [],
          allDone: false,
        }));
        navigate(handleNextStep(routeList));
        dispatch(setIndexFamily(0));
        dispatch(setFamilyAction(newFamily));
      });
    } else if (numberFamily === listFamily?.length) {
      //Si on a finit 
      console.log("Famille rempli -> On passe à la suite");
      navigate(handleNextStep(routeList));
    } else if (numberFamily < listFamily?.length) {
      listFamily?.slice(numberFamily).forEach((x: any) => {
        if (x?.id) {
          deleteLocatairesItem({ id: simulationId, idItem: x?.id });
        }
      });
      dispatch(setFamilyAction(listFamily?.slice(0, numberFamily)));
      dispatch(setIndexFamily(0));
      navigate(handleNextStep(routeList));
    } else {
      const deviant: number = numberFamily - listFamily?.length;
      const newFamily: any = Array.from({ length: deviant }, (a, b) => ({
        value: b,
        step1: {},
        step2: {},
        step3: [],
        allDone: false,
      }));
      dispatch(setFamilyAction([...listFamily, ...newFamily]));
      navigate(handleNextStep(routeList));
      dispatch(setIndexFamily(0));
    }
  };

  const handleStep = () => {
    setDirection("moveToLeftFromRight");

    setTimeout(() => {
      navigate(handleNextStep(routeList));
    });
  };

  const handleToNextStep = () => {
    navigate(handleNextStep(routeList));
    setTimeout(() => {
      dispatch(setRenderStepTwoPageFour(true));
    }, 100);
  };

  const handleNextFamily = () => {
    const stepTwoItemThree = document.querySelector(".stepTwoItemThree");
    stepTwoItemThree?.scrollTo({ top: 0, behavior: "smooth" });
    if (indexFamily === listFamily?.length - 1) {
      setDirection("moveToLeftFromRight");
      setTimeout(() => {
        navigate(handleNextStep(routeList));
      }, 450);
    } else {
      setDirectionStep("moveToLeftFromRight");
      dispatch(setIndexFamily(indexFamily + 1));
      setOldStep(0);
      setTimeout(() => {
        navigate(routeListLocataires[2]?.children?.[0]?.link);
      }, 450);
    }
  };
  const handleUp = () => {
    setNumberFamily(numberFamily + 1);
  };

  const handleDown = () => {
    if (numberFamily > 0) {
      setNumberFamily(numberFamily - 1);
    }
  };

  useEffect(() => {
    const target: any = routeListLocataires?.find(
      (x: any) =>
        x?.link === location?.pathname ||
        x?.children?.map((x: any) => x?.link)?.includes(location?.pathname)
    );
    if (target) {
      const newDirection =
        oldKey < target?.key ? "moveToLeftFromRight" : "moveToRightFromLeft";
      setOldKey(target?.key);
      setDirection(newDirection);
      setTimeout(() => {
        setKey(`${target?.key}`);
      });
      if (target?.children?.length > 0) {
        const targetItem: any = target?.children?.find(
          (x: any) => x?.link === location?.pathname
        );
        if (targetItem) {
          let newDirection;
          if (renderInfoFour) {
            newDirection = "moveToRightFromLeft";
          } else {
            newDirection =
              oldStep < targetItem?.step
                ? "moveToLeftFromRight"
                : "moveToRightFromLeft";
          }

          //Changer ce set pour aller chercher l'information dans le state contenu par le parent
          setNumberFamily(listFamily?.length);
          setOldStep(targetItem?.step);
          setDirectionStep(newDirection);
          setTimeout(() => {
            setStep(`${targetItem?.step}`);
          });
        } else {
          setStep("4");
        }
      }
    } else {
      setTimeout(() => {
        setKey("1");
      }, 1000);
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (location.pathname !== LOCATAIRES4) {
      dispatch(setRenderStepTwoPageFour(false));
    }
    if (location.pathname === LOCATAIRES3STEP4) {
      dispatch(setRenderInfoFour(false));
    }
    const itemWrap = document.querySelector(".stepTwoItemThree");
    setTimeout(() => {
      itemWrap?.scrollTo({ top: 0, behavior: "smooth" });
    }, 300);
  }, [dispatch, location.pathname]);

  useEffect(() => {
    if (key === "3") {
      const headerFamily = document.querySelector(".headerFamily");
      headerFamily?.addEventListener("wheel", (e: any) => {
        e.preventDefault();
        headerFamily.scrollLeft += e?.wheelDelta;
      });
      if (simulationId) {
        dispatch(getSituationsList());
        dispatch(getTypesList());
        dispatch(getCivilitesList());
      }
    }
  }, [key]);

  const handleAddFamily = () => {
    dispatch(
      setFamilyAction([
        ...listFamily,
        {
          value: listFamily?.length,
          step1: {},
          step2: {},
          step3: [],
          allDone: false,
        },
      ])
    );
  };

  const fail = useMemo(() => {
    const filterForFail = listFamily?.filter((x: any) => !x?.isEligible);
    if (filterForFail?.length > 0) {
      return true;
    } else {
      return false;
    }
  }, [listFamily]);

  const success = useMemo(() => {
    const filterForSuccess = listFamily?.filter((x: any) => x?.isEligible);
    if (filterForSuccess?.length > 0) {
      return true;
    } else {
      return false;
    }
  }, [listFamily]);

  const realStep = useMemo(() => {
    if (["3", "4", "5"]?.includes(step)) {
      return 3;
    } else if (step === "6") {
      return 4;
    } else {
      return Number(step);
    }
  }, [step]);

  const resetSimu = () => {
    dispatch(resetDossierSimu());
    dispatch(resetMainStore());
    dispatch(resetDossierStore());
    dispatch(resetAssureStore());
    dispatch(resetConfirmationStore());
    dispatch(resetContratStore());
    dispatch(resetEmprunteurStore());
    dispatch(resetLocataireSlice());
    dispatch(setInfoUserAction(null));
    dispatch(resetPiecesStore());
    dispatch(setFamilyAction([]));
    localStorage.clear();
  }

  return (
    <div id="stepTwo">
      {key && (
        <PageTransition
          preset={direction}
          transitionKey={key}
          enterAnimation={""}
          exitAnimation={""}
        >
          {(() => {
            switch (key) {
              case "1":
                return (
                  <div className="stepTwoItemOne">
                    <img
                      src={profile}
                      alt="loading"
                      className="houseImage"
                    ></img>
                    <h2>Mon dossier de location</h2>
                    <h6>
                      Un dossier de location référence les personnes qui vont
                      apparaitre ensemble sur le bail locatif
                    </h6>
                    <p className="contentP">
                      Une fois le dossier locataires finalisé, nous vous
                      indiquerons si les locataires sont éligibles à l’assurance
                      Gestion des Loyers Impayés (GLI)
                    </p>
                    <button className="submitButton" onClick={handleStep}>
                      Je crée mon dossier de location
                    </button>
                  </div>
                );
              case "2":
                return (
                  <div className="stepTwoItemTwo">
                    <div className="showTitle">
                      <img
                        src={profile}
                        alt="loading"
                        className="houseImage"
                      ></img>
                      <h2>Combien de locataires vont occuper ce bien ?</h2>
                      <h3>
                        Attention,il s'agit des personnes qui seront inscrites
                        sur le bail
                      </h3>
                    </div>

                    <div className="pickerNumber">
                      <a onClick={handleDown}>-</a>
                      <p>{numberFamily}</p>
                      <a onClick={handleUp}>+</a>
                    </div>
                    <button
                      className="submitButton"
                      onClick={() => {
                        handleToStepThree();
                      }}
                      disabled={numberFamily === 0}
                    >
                      Renseigner mon dossier
                    </button>
                  </div>
                );
              case "3":
                return (
                  <div className="stepTwoItemThree">
                    <ul className="headerFamily">
                      {listFamily?.map((x: ListFamily, index: number) => (
                        <li
                          className={classNames("headerFamilyItem cursor", {
                            border1: Number(step) >= 1 && indexFamily >= index,
                            border2: Number(step) >= 2 && indexFamily >= index,
                            border3: Number(step) >= 3 && indexFamily >= index,
                            border4: Number(step) >= 6 && indexFamily >= index,
                            allDone: x?.allDone,
                            hide: indexFamily < index,
                          })}
                          key={index}
                        >
                          <div className="pad-10 mg-b10 avatarWrap">
                            {x?.step1?.civilite?.code ? (
                              <img
                                src={
                                  x?.step1?.civilite?.code === "Mme"
                                    ? womanImage
                                    : manImage
                                }
                                alt="avatar"
                                className="avatarGender"
                              ></img>
                            ) : (
                              <img
                                src={avatar}
                                alt="avatar"
                                className="avatarGender"
                              ></img>
                            )}
                            {x?.allDone && indexFamily > index && (
                              <img
                                src={checked}
                                className="avatarChecked"
                                alt="checked"
                              />
                            )}
                          </div>
                          {indexFamily === index && (
                            <p>Locataire {realStep}/4</p>
                          )}
                        </li>
                      ))}
                      <li
                        onClick={handleAddFamily}
                        className="cursor d-flex flex-direction-col flex-hor-start flex-ver-center headerFamilyAddItem"
                      >
                        <div className="d-flex flex-hor-center flex-ver-center cursor mg-b10 addIcon">
                          <Plus />
                        </div>
                        <p>Ajouter un locataire</p>
                      </li>
                    </ul>

                    {step && (
                      <PageTransition
                        preset={directionStep}
                        transitionKey={renderInfoFour ? "4" : step}
                        enterAnimation={""}
                        exitAnimation={""}
                      >
                        {(() => {
                          switch (step) {
                            case "1":
                              return <ItemOne></ItemOne>;
                            case "2":
                              return <ItemTwo></ItemTwo>;
                            case "3":
                              return (
                                <ItemThree
                                  show={show}
                                  setShow={setShow}
                                  setValueList={setValueList}
                                  valueList={valueList}
                                ></ItemThree>
                              );
                            case "4":
                              return <ItemTwo garant></ItemTwo>;
                            case "5":
                              return (
                                <ItemThree
                                  show={show}
                                  setShow={setShow}
                                  setValueList={setValueList}
                                  valueList={valueList}
                                  garant
                                ></ItemThree>
                              );
                            default:
                              return (
                                <ItemFour
                                  handleNextFamily={handleNextFamily}
                                  show={show}
                                  setShow={setShow}
                                  setValueList={setValueList}
                                  valueList={valueList}
                                ></ItemFour>
                              );
                          }
                        })()}
                      </PageTransition>
                    )}
                  </div>
                );
              default:
                return (
                  <div className="stepTwoItemFour">
                    <div>
                      <img
                        src={fail ? manFail : baloon}
                        alt="baloon"
                        className="houseImage"
                      />
                      <h2>{fail ? "Dommage..." : "Bonne nouvelle!"}</h2>
                      <h3 className="lh-25">
                        {fail
                          ? "Il semblerait que certains de vos locataires ne soient pas éligibles à la GLI, cela signifie que l’assurance sera portée seulement par les locataires éligibles"
                          : "Tous vos locataires sont éligibles à la GLI"}
                      </h3>
                    </div>

                    <div className="familyList">
                      {listFamily?.map((x: ListFamily, index: number) => (
                        <div
                          className={classNames("familyItem", {
                            activeFamilyItem: x?.isEligible,
                          })}
                          key={index}
                        >
                          <div className="d-flex flex-ver-center nameFamily">
                            <img
                              src={
                                x?.step1?.civilite?.code === "Mme"
                                  ? womanImage
                                  : manImage
                              }
                              className="mg-r20"
                              alt="avatar"
                            />
                            <p>{x?.step1?.name}</p>
                          </div>
                          <div className="font-14 font-w600 statusFamily">
                            {x?.isEligible ? "Éligible" : "Non-éligible"}
                          </div>
                          <img
                            src={x?.isEligible ? checked : alert}
                            alt="checked"
                            className="checkedFamilyItem"
                          ></img>
                        </div>
                      ))}
                    </div>
                    <div className="buttonGroup">
                      {success && (
                        <button
                          onClick={handleToNextStep}
                          className="submitButton"
                        >
                          Je configure mon contrat
                          <img src={arrow} alt="loading"></img>
                        </button>
                      )}

                      <button onClick={() => resetSimu()}>Déposer un autre dossier</button>
                    </div>
                  </div>
                );
            }
          })()}
        </PageTransition>
      )}
    </div>
  );
};

export default Locataires;
