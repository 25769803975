import publicAPIInstance from "pages/connexion/publicProxy"

export async function fetchNatureEmprunt() {
    return (
      await publicAPIInstance.get(
        `/api/backoffice/referentiel/natures-epmrunts`
        
      )
    )?.data  
  }
  
  export async function fetchSituationEmprunt() {
    return (
      await publicAPIInstance.get(
        `/api/backoffice/referentiel/situations-epmrunts`
        
      )
    )?.data  
  }

  export async function createEmprunt(data: any) {
    return (
      await publicAPIInstance.post(
        `/api/backoffice/emprunts/create`, data
      )
    )?.data
  }

  export async function updateSimuEmprunteur(data: any) {
    return (
      await publicAPIInstance.put(
        `/api/simulations/updateEmprunteur`, data
      )
    )?.data
  }
  

  export async function createNewAssuresEmprunt(data: any) {
    return (
      await publicAPIInstance.post(
        `/api/backoffice/assures-emprunts/create`, data
      )
    )?.data
  }

  export async function createPret(dataPret: any) {
    return (
      await publicAPIInstance.post(
        `/api/backoffice/prets/create`, dataPret
      )
    )?.data  
  }


export async function createNewAssureEmpruntPret(data: any) {
  return (
    await publicAPIInstance.post(
      `/api/backoffice/assures-emprunts-prets`, data
    )
  )?.data
}

export async function updateEmprunt(data: any) {
  return (
    await publicAPIInstance.put(
      `/api/backoffice/emprunts/update`, data
    )
  )?.data
}

export async function fetchListGender(
  ): Promise<any> {
    return (
      await publicAPIInstance.get(
        `/api/backoffice/referentiel/civilites`
      )
    )?.data
  }

  export async function fetchNaturePret() {
    return (
      await publicAPIInstance.get(
        `/api/backoffice/referentiel/natures-prets`
      )
    )?.data
  }
  
  export async function fetchEmprunteurSimulation(simulationId: string) {
    return (
      await publicAPIInstance.get(
        `/api/simulations/getEmprunteur?id=${simulationId}`
      )
    )?.data  
  }

  export async function fetchListQuestionnaire(age: number, montantPret: number, produitId: string) {
    return (
      await publicAPIInstance.get(
        `/api/backoffice/produits/sante/params/by-age-and-montant?produitId=${produitId}&age=${age}&montant=${montantPret}`
      )
    )?.data  
  }
  
  export async function fetchAssureEmpruntPretList() {
    return (
      await publicAPIInstance.get(
        `/api/backoffice/assures-emprunts-prets/list`
      )
    )?.data  
  }
  
  export async function createPretForSimu(params: {simulationId: string, data: any}) {
    return (
      await publicAPIInstance.post(
        `/api/backoffice/prets/create-for-simulation?simulationId=${params?.simulationId}`, params?.data
      )
    )?.data  
  }
  
  export async function createAssureForSimu(params: {simulationId: string, data: any}) {
    return (
      await publicAPIInstance.post(
        `/api/backoffice/assures-emprunts/create-for-simulation?simulationId=${params?.simulationId}`, params?.data
      )
    )?.data  
  }
  
  export async function deleteAssurEmpruntPretByIds(params: {assureId: string, pretId: any}) {
    return (
      await publicAPIInstance.delete(
        `/api/backoffice/assures-emprunts-prets/delete-by-ids?pretId=${params?.pretId}&assureEmpruntId=${params.assureId}`
      )
    )?.data  
  }
  
  export async function updateAssurEmpruntPretByIds(data: any) {
    return (
      await publicAPIInstance.put(
        `/api/backoffice/assures-emprunts-prets/update-by-ids`, data
      )
    )?.data  
  }
  
  export async function getActiveQuestionEmprunteur(produitId: string) {
    return (
      await publicAPIInstance.get(
        `/api/backoffice/produits/param-emprunteur/list-code-active?produitId=${produitId}`
      )
    )?.data  
  }

  export async function updateAssuresEmprunt(data: any) {
    return (
      await publicAPIInstance.put(
        `/api/backoffice/assures-emprunts/update`, data
      )
    )?.data
  }

  export async function updatePret(dataPret: any) {
    return (
      await publicAPIInstance.put(
        `/api/backoffice/prets/update`, dataPret
      )
    )?.data  
  }


export async function fetchActiviteAggravante(produitId: string) {
  return (
    await publicAPIInstance.get(
      `/api/backoffice/produits/emprunteur/activiteAggravante/listByProduitId?produitId=${produitId}`
      
    )
  )?.data  
}

export async function fetchActiviteARisque(produitId: string) {
  return (
    await publicAPIInstance.get(
      `/api/backoffice/produits/emprunteur/activiteARisque/listByProduitId?produitId=${produitId}`
      
    )
  )?.data  
}

export async function fetchCategorieSocioPro(produitId: string) {
  return (
    await publicAPIInstance.get(
      `/api/backoffice/produits/emprunteur/categorieSocioProfessionnelle/listByProduitId?produitId=${produitId}`
    )
  )?.data  
}

export async function fetchDeplacementPro(produitId: string) {
  return (
    await publicAPIInstance.get(
      `/api/backoffice/produits/emprunteur/deplacementPro/listByProduitId?produitId=${produitId}`
    )
  )?.data  
}

export async function fetchExpositionPolitique(produitId: string) {
  return (
    await publicAPIInstance.get(
      `/api/backoffice/produits/emprunteur/expositionPolitique/listByProduitId?produitId=${produitId}`
    )
  )?.data  
}

export async function fetchFumeur(produitId: string) {
  return (
    await publicAPIInstance.get(
      `/api/backoffice/produits/emprunteur/fumeur/listByProduitId?produitId=${produitId}`
    )
  )?.data  
}

export async function fetchTravauxManuel(produitId: string) {
  return (
    await publicAPIInstance.get(
      `/api/backoffice/produits/emprunteur/travauxManuel/listByProduitId?produitId=${produitId}`
    )
  )?.data  
}

export async function getTunnelInfi(tunnelId: string) {
  return (
    await publicAPIInstance.get(
      `/api/backoffice/tunnels/${tunnelId}`
    )
  )?.data  
}

export async function configureGarantiesEmp(param: {simulationId:string, data:any}): Promise<any> {
  try {
    const response = await publicAPIInstance.post(`/api/contrat-configurator/${param.simulationId}/configurer/prets`, param.data);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la création des garanties retenues :', error);
    throw error; // Renvoie l'erreur pour qu'elle puisse être gérée ailleurs si nécessaire
  }
}

export async function updateStatutSimu(param: {simulationId:string, code:string}): Promise<any> {
  try {
    const response = await publicAPIInstance.put(`/api/simulations/update-statut`, param);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la mise a jour du statut :', error);
    throw error; // Renvoie l'erreur pour qu'elle puisse être gérée ailleurs si nécessaire
  }
}