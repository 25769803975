import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchListGender } from "features/Emprunteur/emprunteurAPI";
import { selectAssuresEmprunts, setAssuresEmprunt } from "features/assure/assureSlice";
import { ReactNode, useEffect, useState } from "react";
import {ReactComponent as Home} from "assets/image/emprunteur/home (2).svg"
import edit from "assets/image/infoStepFour/edit.png"
import trash from "assets/image/infoStepFour/remove.svg"
import {ReactComponent as Plus} from "assets/image/emprunteur/plus-blue.svg"
import AssuresForm from "./assureForm";


type Props = {
    setModalContent: (content: ReactNode) => void,
    setIsModalOpen: (value: boolean) => void
}

const AssureEmprunteur: React.FC<Props> = ({setIsModalOpen, setModalContent}) => {

    const assures = useAppSelector(selectAssuresEmprunts);
    const [showSideMenu, setShowSideMenu] = useState<boolean>(false);
    const [currentAssureIndex, setCurrentAssureIndex] = useState<number>(0);
    const [listGender, setListGender] = useState<any>([]);
    const dispatch = useAppDispatch();

    const handleGetData = async () => {
        const resGender = (await fetchListGender()) || [];
        setListGender(resGender);
      };

    useEffect(() => {
        handleGetData();
    }, [])


    function getSideMenuContent(index: number) {
        const assureExiste = index >= 0 && index < (assures?.length || 0);
        const assure = assureExiste ? assures[index] : null;
        let label = `Assuré ${index + 1}`
        if (assureExiste) {
            label = label + `: ${assure?.information?.prenom} ${assure?.information?.nom}`
        }

        setModalContent(<AssuresForm onCancel={() => setIsModalOpen(false)}
            assure={{no: index, assure: assure, label: label}}
            key={index}
        />)
        setIsModalOpen(true);
    }

    const formatDate = (date: string) => {
        const dateObj = new Date(date);

        const day = dateObj.getDate();
        const month = dateObj.getMonth() + 1;
        const year = dateObj.getFullYear();

        const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
        return formattedDate
    }

    const deleteAssure = (index: number) => {
        const updatedAssures = [...assures];
        updatedAssures.splice(index, 1);
        dispatch(setAssuresEmprunt(updatedAssures));
    }

    return (
        <div className="emprunteur-container w-90 mg-t30">
            <div className="title-container">
                <Home className="mg-r10 svg-primary" style={{height: "20px", width: "20px"}}/>
                <p>Les assurés</p>
            </div>
                    {
                    assures?.length >= 1 ?
                        <div className="assure-map">
                            {
                                assures?.map((currentAssure: any, index: number) => {
                                return (
                                    <div className="assure-content" key={"assures"+ index}>
                                        <div className="text-left w-90">
                                            <span className="font-w700 text-left">{listGender?.find((gender: any) => gender?.id === currentAssure?.information?.civilite)?.libelle}</span>
                                            <span className="font-w700 text-left"> {currentAssure?.information?.nom}</span>
                                            <span className="font-w700 text-left"> {currentAssure?.information?.prenom}</span>
                                            <span> né(e) le {formatDate(currentAssure?.information?.dateNaissance)}</span>
                                            <p className="text-left">
                                                <span>{currentAssure?.information?.email} - </span>
                                                <span>{currentAssure?.information?.telephone}</span>
                                            </p>
                                        </div>
                                        <div>
                                            <img src={edit} alt="edit" onClick={() => {
                                                setCurrentAssureIndex(index);
                                                getSideMenuContent(index)
                                            }}/>
                                            <img src={trash} alt="trash" style={{ transform: 'translateX(-30px)' }} onClick={() => {
                                                deleteAssure(index)
                                            }}/>
                                        </div>
                                    </div>
                            )})}
                            <div className="assure-content" onClick={() => {
                                setCurrentAssureIndex(assures.length);
                                getSideMenuContent(assures.length)
                            }}>
                                <div className="ajout-asssure-container">
                                    <p className="text-blue">
                                        Ajouter un assuré
                                    </p>
                                    <Plus className="svg-primary"/>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="assure-container">

                            <div className="ajout-assure" onClick={() => {
                                setCurrentAssureIndex(0);
                                getSideMenuContent(0)
                            }}>
                                <Plus className="svg-primary"/>
                                <p className="text-blue">
                                    Ajouter un assuré
                                </p>
                            </div>
                        </div>
                    }
        </div>
    )
}

export default AssureEmprunteur