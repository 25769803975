import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { handleNext, selectDossierChoices } from "features/dossierSimuSlice";
import RadioButtonChoice from "features/components/radioButton/radioButton";
import InfoBlock from "features/components/infoblock/infoblock";

type Props = {
  handleOnClick: (
    fieldName: string,
    value: boolean | string | any
  ) => void;
  isSide?: boolean;
};

const CoOwnerShipThree: React.FC<Props> = ({ handleOnClick, isSide = false }) => {
  const navigate = useNavigate();
  const mri = useAppSelector(selectDossierChoices);
  const [selectedValue, setSelectedValue] = useState<string | null>(
    mri && mri.occupation ? mri.occupation : ""
  );
  const dispatch = useAppDispatch();

  const handleAnswer = (
    fieldName: string,
    value: boolean | string | any
  ) => {
    setSelectedValue(value);
    setSelectedValue((prevValue) => {
      handleOnClick(fieldName, prevValue);
      return value;
    });
  };

  return (
    <div className={`${!isSide ? "max-w1300 mg-0-auto ccc-body-step-2" : ""}`}>
      <div className="w-100 text-center border-box radius-15 cccbs2r1">
          {
            <div className="mg-b50">
                <>
                    <div>
                    <p className="font-18 lh-22 text-dark cccbs2r1r1">
                        Quel est le{" "}
                        <span className="font-w700">pourcentage d'occupation</span> de la
                        copropriété ?
                    </p>
                    </div>
                </>
            </div>
          }
        <div className={`d-flex ${!isSide ? "flex-hor-center cccbs2r1r2" : "w-100"} cccbs2r1r2`}>
          <RadioButtonChoice
            radio
            text="Moins de 50%"
            selected={selectedValue === "Moins de 50%"}
            handleOnClick={() => handleAnswer("occupation", "Moins de 50%")}
            last={false}
            isSide={isSide === true ? true : false}

          />
          <RadioButtonChoice
            radio
            text="Plus de 50%"
            selected={selectedValue === "Plus de 50%"}
            handleOnClick={() => handleAnswer("occupation", "Plus de 50%")}
            last={true}
            isSide={isSide === true ? true : false}
          />
        </div>
        {
          !isSide ? (
            <InfoBlock text="Confiancia propose des produits spécifiques aux biens inoccupés (moins de 50% d'occupation)" />
          ) : null
        }
      </div>
      {
            <div className="d-flex flex-hor-end mg-t10">
              <button
                className="submitButton w-30"
                disabled={!selectedValue}
                onClick={() => dispatch(handleNext())}
              >
                Étape suivante
              </button>
            </div>
        }
    </div>
  );
};

export default CoOwnerShipThree;
