
import logoDefault from "./assets/image/mainStep/logo.svg"
import logoTest from "./assets/image/mainStep/logoTest.svg"

export const styleDefault = {
    "color-main": '#3B909B',
    "color-sub": "#EC7033",
    "logo": logoDefault
}

export const styleTest1 = {
    "color-main": '#172062',
    "color-sub": "#DC0668",
    "logo": logoTest
}

export const styleTest2 = {
    "color-main": 'red',
    "color-sub": "blue",
    "logo": `url("assets/image/mainStep/logo.png")`
}