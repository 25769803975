import classNames from "classnames";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFamilyAction } from "pages/mainStep/mainSlice";
import { useNavigate } from "react-router-dom";
import clock from "assets/image/stepTwo/clock.svg";
import checkItem from "assets/image/stepTwo/checkItem.svg";
import SelectComponent from "components/Select";
import { Situation } from "pages/mainStep/type";
import { LOCATAIRES3STEP5, LOCATAIRES3STEP3 } from "pages/mainStep/routeList";
import { handleNextStep } from "helpers/handleNextStep";

const ItemTwo = ({ garant }: any) => {
  const [data, setData] = useState("");
  const [select, setSelect] = useState<string | any>();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { listFamily, indexFamily, routeList } = useSelector(
    (state: any) => state?.mainSlice
  );

  const { situationsList } = useSelector(
    (state: any) => state?.locatairesSlice
  );

  useEffect(() => {
    if (indexFamily >= 0) {
      if (garant) {
        setData(listFamily?.[indexFamily]?.situationGarant?.children?.id || "");
        setSelect(listFamily?.[indexFamily]?.situationGarant?.parent?.id);
      } else {
        setData(listFamily?.[indexFamily]?.step2?.children?.id || "");
        setSelect(listFamily?.[indexFamily]?.step2?.parent?.id);
      }
    }
  }, []);

  const handleSetData = (value: Situation) => {
    setData(value?.id);
  };

  const handleSubmit = () => {
    const parent = optionsList?.find((x: Situation) => x?.id === select);

    const dataSubmit = {
      children: data
        ? parent?.enfants?.find((x: Situation) => x?.id === data)
        : "",
      parent,
    };
    const newListFamily = [...listFamily];
    if (garant) {
      newListFamily[indexFamily] = {
        ...newListFamily[indexFamily],
        situationGarant: dataSubmit,
      };
      dispatch(setFamilyAction(newListFamily));
      navigate(handleNextStep(routeList));
    } else {
      newListFamily[indexFamily] = {
        ...newListFamily[indexFamily],
        step2: dataSubmit,
      };
      dispatch(setFamilyAction(newListFamily));
      navigate(handleNextStep(routeList));
    }
  };

  const optionsList = useMemo(
    () =>
      situationsList?.map((x: Situation) => {
        if (x?.code === "Etudiant") {
          return {
            ...x,
            label: x?.displayName,
            value: x?.id,
            enfants: [
              {
                code: "withGarant",
                displayName: "Avec garant",
                id: "withGarant",
                peutEtreAttribueGarant: true,
              },
              {
                code: "scholarship",
                displayName: "Boursier",
                id: "scholarship",
              },
              { code: "other", displayName: "Avec revenus", id: "other" },
            ],
          };
        } else {
          return {
            ...x,
            label: x?.displayName,
            value: x?.id,
          };
        }
      }) || [],
    [situationsList]
  );

  const childrenList = useMemo(() => {
    return optionsList.find((x: Situation) => x?.id === select)?.enfants || [];
  }, [optionsList, select]);

  return (
    <div id="itemTwo">
      <h2 className="main-color">
        {garant
          ? "Quelle est sa situation professionnelle du garant ?"
          : "Quelle est sa situation professionnelle ?"}
      </h2>
      <SelectComponent
        onChange={(e: string) => {
          setSelect(e);
          setData("");
        }}
        value={select}
        options={optionsList}
        placeholder={garant
          ? "Sélectionnez la situation du garant"
          : "Sélectionnez la situation du locataire"}
      ></SelectComponent>

      <div
        className={classNames("selectGroup", {
          show: childrenList?.length > 0,
        })}
      >
        <p>Préciser la situation</p>
        <div className="chooseGroup">
          {childrenList?.map((x: Situation, index: number) => (
            <div
              onClick={() => handleSetData(x)}
              className={classNames({ active: data === x?.id })}
              key={index}
            >
              <p>{x?.displayName}</p>
              {data === x?.id && <img src={checkItem} alt="loading"></img>}
            </div>
          ))}
        </div>
      </div>
      <div className="buttonGroup">
        <button
          disabled={!select || (select && childrenList?.length > 0 && !data)}
          onClick={handleSubmit}
          className="submitButton"
        >
          Étape suivante
        </button>
        <div className="footerButton">
          <img src={clock} alt="loading"></img>
          <p>Sauvegarder mon dossier et finir plus tard</p>
        </div>
      </div>
    </div>
  );
};

export default ItemTwo;
