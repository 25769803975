import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchDocuments, uploadDocument } from "./pieces";

export interface CounterState {
  value: number;
  status: "idle" | "loading" | "failed";
  loading: boolean;
  listLocataires: any;
}

const initialState: CounterState = {
  value: 0,
  status: "idle",
  loading: false,
  listLocataires: [],
};

export const fetchDocumentsAction: any = createAsyncThunk(
  "FETCH_DOCUMENTS",
  async (data: any) => {
    const response: any = await fetchDocuments(data);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const uploadDocAction: any = createAsyncThunk(
  "UPLOAD_DOC",
  async (data: {
    params: { simulationId: string; documentId: string };
    dataForm: any;
  }) => {
    const response: any = await uploadDocument(data);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const piecesSlice = createSlice({
  name: "pieces",
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload;
    },
    resetPiecesStore(state) {
      state = initialState;
    }
  },

  extraReducers: {
    [fetchDocumentsAction.pending]: (state) => {
      state.loading = true;
    },
    [fetchDocumentsAction.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchDocumentsAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.listLocataires = action?.payload || [];
    },
  },
});

export const { increment, decrement, incrementByAmount, resetPiecesStore } = piecesSlice.actions;

export default piecesSlice.reducer;
