import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { handleJustify } from "helpers/handleJustify";
import { useNavigate } from "react-router-dom";
import { handleNextStep } from "helpers/handleNextStep";

const ConnexionForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { infoStepThreePageOne, routeList } = useSelector(
    (state: any) => state.mainSlice
  );

  useEffect(() => {
    if (infoStepThreePageOne) {
      form.setFieldsValue(infoStepThreePageOne);
    }
  }, []);

  useEffect(() => {
    handleJustify("contratStepOne");
  }, [window.innerHeight]);

  useEffect(() => {
    window.addEventListener("resize", () => handleJustify("contratStepOne"));

    return () =>
      window.removeEventListener("resize", () =>
        handleJustify("contratStepOne")
      );
  }, []);

  const handleSubmit = (form: any) => {
    navigate(handleNextStep(routeList));
  };

  return (
    <div id="connexionForm">
      <h2 className="mg-b50">
        Quelques informations sur vous avant de commencer
      </h2>
      <Form form={form} onFinish={handleSubmit}>
        <div className="inputGroup">
          <div className="formItem">
            <p>Nom</p>
            <Form.Item
              name="name"
              rules={[{ required: true, message: "Please input your name!" }]}
            >
              <input placeholder="Ex : DUPONT" className="input"></input>
            </Form.Item>
          </div>
          <div className="formItem">
            <p>Prénom</p>
            <Form.Item
              name="subName"
              rules={[
                { required: true, message: "Please input your sub name!" },
              ]}
            >
              <input placeholder="Ex : Marc" className="input"></input>
            </Form.Item>
          </div>
          <div className="formItem">
            <p>Email</p>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
              ]}
            >
              <input
                placeholder="Ex : marc.dupont@gmail.com"
                className="input"
              ></input>
            </Form.Item>
          </div>

          <div className="checkboxGroup">
            <Form.Item name="check" valuePropName="checked">
              <input type="checkbox"></input>
            </Form.Item>
            <p>
              J'accepte de recevoir des informations personnalisées de la part
              de Confiancia (garantie zéro spam)
            </p>
          </div>
        </div>

        <button type="submit" className="submitButton">
          Suivant
        </button>
      </Form>
    </div>
  );
};

export default ConnexionForm;
