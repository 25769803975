import { Form, Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import { useAppSelector } from "app/hooks";
import SelectComponent from "components/Select";
import { fetchActiviteARisque, fetchActiviteAggravante, fetchCategorieSocioPro, fetchDeplacementPro, fetchExpositionPolitique, fetchFumeur, fetchTravauxManuel, getActiveQuestionEmprunteur, getTunnelInfi } from "features/Emprunteur/emprunteurAPI";
import { getSimulationInfo } from "pages/mainStep/mainStepAPI";
import { useEffect, useState } from "react";

type Props = {
    onCancel: (pram:void) => void;
    onSubmit: (formData: any) => void;
    situation: any;
    assure: {
        no: number,
        assure: any
    }
}

const questions = {
    catSocioPro: 'Catégorie socio-professionnelle',
    isFumeur: 'L\'assuré est-il fumeur ?',
    travauxManuel: 'Travaux manuels',
    sport: 'Sport ou activité à risque',
    deplacementPro: 'Déplacements professionnels',
    expoPol: 'Exposition politique',
    profession: 'L\'assuré exerce-t-il une des professions suivantes ?'
};

const questionKeyMapping = {
    CSP: "catSocioPro",
    FUMEUR: "isFumeur",
    TRAVAUX: "travauxManuel",
    DEPLAPRO: "deplacementPro",
    EXPOPOLITIQUE: "expoPol",
    ACTARISQUE: "sport",
    ACTAGGRAV: "profession",
}

interface FormType {
    catSocioPro?: string
    isFumeur?: string
    travauxManuel?: string
    sport?: string
    deplacementPro?: string
    expoPol?: string
    profession?: string
  }

const SituationForm: React.FC<Props> = ({onCancel, onSubmit, assure, situation}) => {
    const { courtierId, simulationId } = useAppSelector(
        (state) => state.mainSlice
      ); 
    const [form] = Form.useForm();

    const [questionsOptions, setQuestionsOptions] = useState<Partial<{
        catSocioPro: { label: string; value: any }[];
        isFumeur: { label: string; value: any }[];
        travauxManuel: { label: string; value: any }[];
        sport: { label: string; value: any }[];
        deplacementPro: { label: string; value: any }[];
        expoPol: { label: string; value: any }[];
        profession: { label: string; value: any }[];
    }>>({
        catSocioPro: [],
        isFumeur: [],
        travauxManuel: [],
        sport: [],
        deplacementPro: [],
        expoPol: [],
        profession: []
    });

    const parseOptions = (res: any) => {
        return res?.map((currentValue: any) => {
            const { libelle, id } = currentValue; // Extract properties safely
            return {
                label: libelle !== undefined ? libelle : undefined,
                value: id !== undefined ? id : undefined
            }
        });
    }

    const fetchOptions = async () => {
        const res = await getSimulationInfo(simulationId, "Emprunteur");
        const tunnel = await getTunnelInfi(res?.tunnelId)
        const produitId = tunnel?.produit?.id;

        const activatedQuestions = await getActiveQuestionEmprunteur(produitId);
        
        const filteredOptions: { [key: string]: any[] } = {};

        for (const activatedQuestionKey of activatedQuestions) {
            const questionKey = questionKeyMapping[activatedQuestionKey as keyof typeof questionKeyMapping];
            if (questionKey && questions[questionKey as keyof typeof questions] !== undefined) {
                let options: any[] = [];
                switch (activatedQuestionKey) {
                    case 'CSP':
                        options = await fetchCategorieSocioPro(produitId);
                        break;
                    case 'FUMEUR':
                        options = await fetchFumeur(produitId);
                        break;
                    case 'TRAVAUX':
                        options = await fetchTravauxManuel(produitId);
                        break;
                    case 'ACTARISQUE':
                        options = await fetchActiviteARisque(produitId);
                        break;
                    case 'DEPLAPRO':
                        options = await fetchDeplacementPro(produitId);
                        break;
                    case 'EXPOPOLITIQUE':
                        options = await fetchExpositionPolitique(produitId);
                        break;
                    case 'ACTAGGRAV':
                        options = await fetchActiviteAggravante(produitId);
                        break;
                    default:
                        break;
                }
                filteredOptions[questionKey] = parseOptions(options);
            }
        }

        // Mettre à jour l'état questionsOptions avec les options filtrées
        setQuestionsOptions(filteredOptions);
    };
    

    useEffect(() => {
        fetchOptions();
        if (situation != null && situation != undefined) {
            Object.keys(situation)?.map((currentSituation: any) =>{
                form.setFieldValue(currentSituation, situation[currentSituation]);
            })
        }
    }, [])

    return (
        <div className="w-100 h-100 d-flex">
            <Form onFinish={onSubmit} form={form} autoComplete="off" initialValues={situation}>
            {Object.keys(questionsOptions).map((key: any, index: number) => (
                <div key={key} className={`formItem ${(Object.keys(questionsOptions).length - 1) === index ? "min-w200" : ""}`}>
                    <FormItem shouldUpdate={(prevValues, currentValues) => prevValues[key] !== currentValues[key]}>
                    {
                        () => {
                            return (
                            <FormItem name={key} trigger="onChange">
                            <p>{questions[key as keyof typeof questions]}</p>
                            <Select dropdownStyle={{backgroundColor: "white"}} id="custom-select" onChange={(e) => form.setFieldValue(key, e)} value={form.getFieldValue(key)}>
                                {
                                    questionsOptions[key as keyof typeof questionsOptions]?.map((currenOption: any) => {
                                        return (
                                            <Select.Option value={currenOption?.value}>
                                                <span>{currenOption?.label}</span>
                                            </Select.Option>
                                        )
                                    })
                                }
                              
                            </Select>
                        </FormItem>
                            )
                        }
                    }
                   
                    </FormItem>
                </div>
            ))}
                <button className="submitButton" type="submit">
                    Valider mes informations
                </button>
            </Form>
        </div>
    )
}
export default SituationForm;