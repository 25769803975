import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { simulationInfo, minMaxApi, calculateInsurance, createBien } from "./monBien";

export interface CounterState {
  value: number;
  status: "idle" | "loading" | "failed";
  loading: boolean;
  minMax: { montantLoyerMinimal: number; montantLoyerMaximal: number };
  insurance: { cotisationAnnuelle: number; cotisationMensuelle: number };
}

const initialState: CounterState = {
  value: 0,
  status: "idle",
  loading: false,
  minMax: { montantLoyerMinimal: 0, montantLoyerMaximal: 0 },
  insurance: { cotisationAnnuelle: 0, cotisationMensuelle: 0 },
};

export const simulationInfoAction: any = createAsyncThunk(
  "SIMULATION_ACTION",
  async (data, { rejectWithValue }) => {
    try {
      const res: any = await simulationInfo(data);
      return res?.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const createBienCBNO: any = createAsyncThunk(
  "CREATE_BIEN_CBNO",
  async (data, { rejectWithValue }) => {
    try {
      const res: any = await createBien(data);
      return res?.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const getMinMaxAction: any = createAsyncThunk(
  "MINMAX_ACTION",
  async (id: string, { rejectWithValue }) => {
    try {
      const res: any = await minMaxApi(id);
      return res?.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const getCalculateInsurance: any = createAsyncThunk(
  "CALCULTE_INSURANCE_ACTION",
  async (data: any, { rejectWithValue }) => {
    try {
      const res: any = await calculateInsurance(data);
      return res?.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const monBienSlice = createSlice({
  name: "monBien",
  initialState,
  reducers: {},
  extraReducers: {
    [simulationInfoAction.pending]: (state) => {
      state.loading = true;
    },
    [simulationInfoAction.rejected]: (state, action) => {
      state.loading = false;
    },
    [simulationInfoAction.fulfilled]: (state, action) => {
      state.loading = false;
    },

    [createBienCBNO.pending]: (state) => {
      state.loading = true;
    },
    [createBienCBNO.rejected]: (state, action) => {
      state.loading = false;
    },
    [createBienCBNO.fulfilled]: (state, action) => {
      state.loading = false;
    },

    [getMinMaxAction.pending]: (state) => {
      state.loading = true;
    },
    [getMinMaxAction.rejected]: (state, action) => {
      state.loading = false;
    },
    [getMinMaxAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.minMax = action.payload;
    },

    [getCalculateInsurance.pending]: (state) => {
      state.loading = true;
    },
    [getCalculateInsurance.rejected]: (state, action) => {
      state.loading = false;
    },
    [getCalculateInsurance.fulfilled]: (state, action) => {
      state.loading = false;
      state.insurance = action.payload;
    },
  },
});

export default monBienSlice.reducer;
