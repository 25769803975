import house from "assets/image/startStep/house.svg";
import { setBienId, setInfoUserAction } from "pages/mainStep/mainSlice";
import { Form, Upload } from "antd";
import { useEffect, useState } from "react";
import { getBase64 } from "../../../helpers/getBase64";
import upload from "assets/image/startStep/upload.svg";
import Autocomplete from "react-google-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import close from "assets/image/mainStep/close.svg";
import { uploadImage } from "pages/mainStep/mainStepAPI";
import { Spin } from "antd";
import { createBienCBNO } from "features/monBien/monBienSlice";
import { handleNext, selectDossierType } from "features/dossierSimuSlice";
import { setSimulationRisqueAssure } from "features/monBien/monBien";
import { useIsSmallScreen } from "hooks/isSmallScreen";

interface FormType {
  address: string;
  anotherAddress: string;
  nomDuBien: string;
  nombreDePieces: string;
  surface: string;
  ville: string;
  url: string;
  montantCharges: string;
}

const ChoiceBien = ({ handleNextStep }: any) => {
  const [url, setURL] = useState<string>();
  const [value, setValue] = useState(1);
  const [dataAddress, setDataAddress] = useState({});
  const [photoId, setPhotoId] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const dossierType = useAppSelector(selectDossierType);
  const [form] = Form.useForm();

  const { infoUser, simulationId } = useAppSelector(
    (state: any) => state.mainSlice
  );

  const isSmallScreen = useIsSmallScreen(567);

  useEffect(() => {
    if (infoUser) {
      form.setFieldsValue(infoUser);
      setDataAddress(infoUser?.dataAddress);

      if (infoUser?.url) {
        setURL(infoUser?.url);
      }
    }
  }, [infoUser, dispatch, form]);



  const handleChangeImage = async (img: any) => {
    setLoading(true);
    const data = new FormData();
    data.append("content", img?.file?.originFileObj);

    const res: any = await uploadImage(simulationId, data);
    if (res?.data?.fileId) {
      setLoading(false);
      setPhotoId(res?.data?.fileId);
      getBase64(img?.file?.originFileObj, (base64: string) => {
        setURL(base64);
      });
    } else {
      setLoading(false);
    }
  };

  const handleSubmit = async (form: FormType) => {
    setLoading(true);
    const dataSubmit: any = {
      adresse: { ...dataAddress, ligne2: form?.anotherAddress },
      nomDuBien: form?.nomDuBien,
      nombreDePieces: Number(form?.nombreDePieces),
      surface: Number(form?.surface),
      montantLoyer: Number(value),
      montantCharges: 0,
    };

    if (photoId) {
      dataSubmit.photoDuBienId = photoId;
    }

    const saveData = { ...form, price: value, dataAddress };
    if (url) {
      saveData.url = url;
    }
    //TO-DO: ici verifier si le bien existe deja si c'est le cas update
    const response = await dispatch(
      createBienCBNO({ id: simulationId, data: dataSubmit })
    );
    if (response?.error?.message !== "Rejected") {
      const param = {
        simulationId: simulationId,
        risqueAssureId: response.payload.id,
        type: "CBNO"
      }
      await setSimulationRisqueAssure(param);
      setLoading(false);
      setTimeout(() => {
        dispatch(setInfoUserAction(saveData));
        dispatch(setBienId(response.payload.id))
      }, 500);
      dispatch(handleNext());
    } else {
      setLoading(false);
    }
  };

  const handleFormatPlace = (place: any) => {
    const listPlace = place?.address_components;
    const googlePlaceId = place?.place_id;
    const codePostal = listPlace?.find((x: any) =>
      x?.types?.includes("postal_code")
    )?.long_name;
    const country = listPlace?.find((x: any) =>
      x?.types?.includes("country")
    )?.long_name;
    const city = listPlace?.find((x: any) =>
      x?.types?.includes("locality")
    )?.long_name;
    const address = place?.formatted_address?.split(",")?.[0];
    geocodeByAddress(place?.formatted_address)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        const dataAddress = {
          latitude: `${lat}`,
          longitude: `${lng}`,
          pays: country,
          ville: city,
          codePostal,
          adresseFormatee: address
            ? `${address},${city},${country}`
            : place?.formatted_address,
          googlePlaceId,
          ligne1: address,
        };
        setDataAddress(dataAddress);

        form.setFieldsValue({
          address: address,
          ville: `${city} (${codePostal})`,
        });
      });
  };

  return (
    <div id="monBienForm">
      <img src={house} alt="loading" className="houseImage mg-t20"></img>
      <h2 className="mg-b30 mg-t20">Votre bien à louer</h2>
      <div className="container">
      <Form onFinish={handleSubmit} form={form} autoComplete="off">
        <div className="formItem">
          <p>Nommez votre bien (facultatif)</p>
          <Form.Item name="nomDuBien">
            <input
              placeholder="Ex : Appartement rue blablabla"
              className="input"
              name="name"
            ></input>
          </Form.Item>
        </div>
        <div className="formItem">
          <p>Adresse</p>
          <Form.Item
            name="address"
            rules={[{ required: true, message: "Please input your city!" }]}
          >
            <Autocomplete
              className="input"
              id={"countryAnother" + Math.floor(Math.random() * 10000)}
              placeholder="Adresse"
              apiKey="AIzaSyCCelMiuDG-cQfWRb0KWDyPD_glL2hrwPM"
              onPlaceSelected={(place) => {
                handleFormatPlace(place);
              }}
              options={{
                types: ["geocode", "establishment"],
                componentRestrictions: { country: "fr" },
              }}
            />
          </Form.Item>
        </div>
        <div className="formItem">
          <p>Ville</p>
          <Form.Item
            name="ville"
            rules={[{ required: true, message: "Please input your country!" }]}
          >
            <input placeholder="ville" className="input"></input>
          </Form.Item>
        </div>

        <div className="formItem">
          <p>Complément d'adresse</p>
          <Form.Item name="anotherAddress">
            <input placeholder="Complément d'adresse" className="input"></input>
          </Form.Item>
        </div>
        {
            !isSmallScreen ? (
            <>
                <Upload
                    onChange={handleChangeImage}
                    showUploadList={false}
                    disabled={loading}
                    style={{width: `100%`}}
                    className="custom-upload"
                >
                  <div className="upload">
                      {loading ? (
                      <Spin className="p-absolut"></Spin>
                      ) : (
                      <p>Ajouter une photo (facultatif)</p>
                      )}
                      {!loading && <img alt="loading" src={upload}></img>}
                  </div>
                </Upload>
                {url && (
                    <div className="profileImgWrap">
                        <img className="profileImg" src={url} alt="avatar" />
                        <img
                            className="removeImage"
                            src={close}
                            alt="close"
                            onClick={() => {
                                setPhotoId("");
                                setURL("");
                            }}
                        />
                    </div>
                )}
            </>
            ) : null
        }
        <div className="inputGroup">
          <div className="formItem">
            <p>Nombre de pièces</p>
            <Form.Item
              name="nombreDePieces"
              rules={[{ required: true, message: "Please input your area!" }]}
            >
              <input
                placeholder="Nombre de pièces"
                min="0"
                className="input"
                type="number"
                onKeyDown={(e) =>
                  (e.keyCode === 69 ||
                    e.keyCode === 190 ||
                    e.keyCode === 189) &&
                  e.preventDefault()
                }
              ></input>
            </Form.Item>
          </div>

          <div className="formItem">
            <p>Nombre de mètres carrés</p>
            <Form.Item
              name="surface"
              rules={[{ required: true, message: "Please input your number!" }]}
            >
              <input
                placeholder="Nombre de mètres carrés"
                className="input"
                type="number"
                min="0"
                onKeyDown={(e) =>
                  (e.keyCode === 69 ||
                    e.keyCode === 190 ||
                    e.keyCode === 189) &&
                  e.preventDefault()
                }
              ></input>
            </Form.Item>
          </div>
        </div>
        {
        /* <div className="formItem">
          <p>Montant charges</p>
          <Form.Item
            name="montantCharges"
            rules={[{ required: true, message: "Please input your charge!" }]}
          >
            <input
              placeholder="Montant des charges"
              className="input"
              type="number"
              min="0"
              onKeyDown={(e) =>
                (e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 189) &&
                e.preventDefault()
              }
            ></input>
          </Form.Item>
        </div> */
        }
        {
            isSmallScreen ? (
            <>
                <Upload
                    onChange={handleChangeImage}
                    showUploadList={false}
                    disabled={loading}
                    style={{width: `100%`}}
                    className="custom-upload"
                >
                <div className="upload">
                    {loading ? (
                    <Spin className="p-absolut"></Spin>
                    ) : (
                    <p>Ajouter une photo (facultatif)</p>
                    )}
                    {!loading && <img alt="loading" src={upload}></img>}
                </div>
                </Upload>
                {url && (
                    <div className="profileImgWrap">
                        <img className="profileImg" src={url} alt="avatar" />
                        <img
                            className="removeImage"
                            src={close}
                            alt="close"
                            onClick={() => {
                                setPhotoId("");
                                setURL("");
                            }}
                        />
                    </div>
                )}
            </>
            ) : null
        }
        <button className="submitButton" type="submit" disabled={loading}>
          Valider mon bien
        </button>
      </Form>
      </div>
    </div>
  );
};

export default ChoiceBien;
