import { useAppDispatch, useAppSelector } from "app/hooks";
import { handleNext, selectDossierChoices } from "features/dossierSimuSlice";
import { useState } from "react";
import { createEmprunt, fetchNatureEmprunt, fetchSituationEmprunt, updateSimuEmprunteur } from "../emprunteurAPI";
import { setEmpruntId } from "features/assure/assureSlice";
import home from "assets/image/emprunteur/home (2).svg"
import vacances from "assets/image/emprunteur/Groupe 2856.svg"
import maisonCle from "assets/image/emprunteur/Groupe 2858.svg"
import travaux from "assets/image/emprunteur/travaux.svg"
import commerces from "assets/image/emprunteur/commerces.svg"
import { useIsSmallScreen } from "hooks/isSmallScreen";

type Props = {
    handleOnClick: (
      fieldName: string,
      value: boolean | string | number
    ) => void;
};

const Situation: React.FC<Props> = ({handleOnClick}) => {
    const dossierChoices = useAppSelector(selectDossierChoices);
    const [selectedValue, setSelectedValue] = useState<string>(dossierChoices?.Situation);
    const isSmallScreen = useIsSmallScreen(567)
    const { simulationId } = useAppSelector(
        (state) => state.mainSlice
      ); 
    const dispatch = useAppDispatch();

    const submitEmprunt = async () => {

        const natureEmprunt = await fetchNatureEmprunt();
        const situationEmprunt = await fetchSituationEmprunt();
        const dataEmprunt = {
            natureEmpruntId: natureEmprunt?.find((currentNature: any) => currentNature?.libelle === selectedValue)?.id,
            situationEmpruntId: situationEmprunt?.find((currentSituation: any) => currentSituation?.libelle === dossierChoices?.Projet)?.id,
            pretsIds: [
            ]
        }
        //Ici verifier si l'id d'emprunteur existe deja si oui alors update seulement l'emprunt
        await createEmprunt(dataEmprunt).then(async (res) => {
            const updateEmp = {
                id: simulationId,
                empruntId: res?.id
            };

            await updateSimuEmprunteur(updateEmp);
            dispatch(setEmpruntId(res?.id))
        }).catch()

        handleOnClick("Situation", selectedValue)
        dispatch(handleNext());
    }
    return (
        <div id="projet">
            <div className={`container`}>
                <div className="">
                    <div className="question-container">
                        <p>Quelle situation correspond à votre projet d'assurance emprunteur ?</p>
                        <button onClick={() => setSelectedValue("Nouveau prêt")}
                            className={`w-100 question-button mg-top  ${selectedValue === "Nouveau prêt" ? "active" : ""}`}
                        >
                            <img src={home} alt="Home"></img>
                            <p className={`text-left `}>Nouveau prêt</p>
                        </button>
                        <button onClick={() => setSelectedValue("Renégociation banque")}
                            className={`w-100 question-button mg-top  ${selectedValue === "Renégociation banque" ? "active" : ""}`}
                        >
                            <img src={vacances} alt="Tongues"></img>
                            <p className={`text-left `}>Renégociation d'assurance (banque)</p>
                        </button>
                        <button onClick={() => setSelectedValue("Renégociation courtier")} 
                            className={`w-100 question-button mg-top  ${selectedValue === "Renégociation courtier" ? "active" : ""}`}
                        >
                            <img src={maisonCle} alt="Home"></img>
                            <p className={`text-left `}>Renégociation d'assurance (courtier)</p>
                        </button>
                        <button onClick={() => setSelectedValue("Ne sais pas")}
                            className={`w-100 question-button mg-top  ${selectedValue === "Ne sais pas" ? "active" : ""}`}
                        >
                            <img src={travaux} alt="Home"></img>
                            <p className={`text-left `}>Je ne sais pas</p>
                        </button>
                    </div>
                </div>
            </div>
            <div className={`w-90 d-flex ${isSmallScreen ? "flex-hor-center" : "flex-hor-end"}`}>
                <div className="submit-container w-50">
                    <button
                        className="submitButton"
                        disabled={selectedValue === undefined}
                        onClick={() =>  {
                            submitEmprunt()
                        }}
                    >
                        Étape suivante
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Situation