import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import nextArrow from "assets/image/startStep/arrow.svg";
import { handleNext, selectDossierChoices } from "features/dossierSimuSlice";

type Props = {
  handleOnClick: (
    fieldName: string,
    value: boolean | string | any
  ) => void;
};

const CoOwnerShipThirteen: React.FC<Props> = ({  handleOnClick }) => {
  const mri = useAppSelector(selectDossierChoices);
  const dispatch = useAppDispatch();

  const [inputs, setInputs] = useState({
    montant: mri && mri.prime?.montant ? mri.prime.montant : "",
    annee: mri && mri.prime?.annee ? mri.prime.annee : "",
    franchiseGenerale:
      mri && mri.prime?.franchiseGenerale ? mri.prime.franchiseGenerale : "",
    franchiseIncendie:
      mri && mri.prime?.franchiseIncendie ? mri.prime.franchiseIncendie : "",
    franchiseVol: mri && mri.prime?.franchiseVol ? mri.prime.franchiseVol : "",
    franchiseDDE: mri && mri.prime?.franchiseDDE ? mri.prime.franchiseDDE : "",
    franchiseVandalisme:
      mri && mri.prime?.franchiseVandalisme
        ? mri.prime.franchiseVandalisme
        : "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setInputs((prevInputs) => {
      const updatedInputs = {
        ...prevInputs,
        [name]: value,
      };

      handleOnClick("prime", updatedInputs);

      return updatedInputs;
    });
  };

  const skipStep = () => {
    handleOnClick("prime", null);
    dispatch(handleNext());
  };

  const {
    montant,
    annee,
    franchiseGenerale,
    franchiseIncendie,
    franchiseVol,
    franchiseDDE,
    franchiseVandalisme,
  } = inputs;

  const isButtonDisabled = () => {
    return (montant.trim() !== "" && annee.trim() === "") || (montant.trim() === "" && annee.trim() !== "");
  };

  return (
    <div className="max-w1300 mg-0-auto ccc-body-step-2">
      <div className="w-100 text-center border-box radius-15 cccbs2r1">
        <div className="mg-b50">
          <div className="d-flex flex-center flex-direction-col">
            <p className="font-24 text-dark font-w700 mg-b30">Prime actuelle</p>
            <div>
              <div className="d-flex mg-b20">
                <div className="flex-direction-col mg-r10">
                  <div className="w-100 mg-b20 d-flex flex-ver-center" style={{ textAlign: "left",  minHeight: "2.5em"  }}>
                    <label
                      className="text-dark font-w700 w-100"
                      style={{ textAlign: "left" }}
                      htmlFor="montant"
                    >
                      Montant H.T de la première prime
                    </label>
                  </div>

                  <input
                    name="montant"
                    value={montant}
                    onChange={handleChange}
                    className="pr-db-input-bg "
                    placeholder="Ex : 3000 €"
                  />
                </div>
                <div className="flex-direction-col mg-r10">
                  <div className="w-100 mg-b20 d-flex flex-ver-center" style={{ textAlign: "left",  minHeight: "2.5em"  }}>
                    <label
                      className="text-dark font-w700 w-100"
                      style={{ textAlign: "left" }}
                      htmlFor="annee"
                    >
                      Année d'exercice
                    </label>
                  </div>
                  <input
                    name="annee"
                    value={annee}
                    onChange={handleChange}
                    className="pr-db-input-bg"
                    placeholder="Ex : 2022"
                  />
                </div>
              </div>
              <hr style={{ border: "1px solid #f4f4f4" }} />
              <div className="d-flex mg-b20">
                <div className="flex-direction-col mg-r10">
                  <div className="w-100 mg-b20 d-flex flex-ver-center" style={{ textAlign: "left",  minHeight: "2.5em"  }}>
                    <label
                      className="text-dark font-w700 w-100"
                      style={{ textAlign: "left" }}
                      htmlFor="franchiseGenerale"
                    >
                      Franchise générale
                    </label>
                  </div>

                  <input
                    name="franchiseGenerale"
                    value={franchiseGenerale}
                    onChange={handleChange}
                    className="pr-db-input-bg"
                    placeholder="Ex : 3000€"
                  />
                </div>

                <div className="flex-direction-col mg-r10">
                  <div className="w-100 mg-b20 d-flex flex-ver-center" style={{ textAlign: "left",  minHeight: "2.5em"  }}>
                    <label
                      className="text-dark font-w700 w-100"
                      style={{ textAlign: "left" }}
                      htmlFor="franchiseIncendie"
                    >
                      Franchise incendie
                    </label>
                  </div>

                  <input
                    name="franchiseIncendie"
                    value={franchiseIncendie}
                    onChange={handleChange}
                    className="pr-db-input-bg"
                    placeholder="Ex : 3000€"
                  />
                </div>

                <div className="flex-direction-col mg-r10">
                  <div className="w-100 mg-b20 d-flex flex-ver-center" style={{ textAlign: "left",  minHeight: "2.5em"  }}>
                    <label
                      className="text-dark font-w700 w-100"
                      style={{ textAlign: "left" }}
                      htmlFor="franchiseVol"
                    >
                      Franchise vol
                    </label>
                  </div>

                  <input
                    name="franchiseVol"
                    value={franchiseVol}
                    onChange={handleChange}
                    className="pr-db-input-bg"
                    placeholder="Ex : 3000€"
                  />
                </div>
              </div>

              <div className="d-flex mg-b20">
                <div className="flex-direction-col mg-r10 w-50">
                  <div className="w-100 mg-b20 d-flex flex-ver-center" style={{ textAlign: "left",  minHeight: "2.5em"  }}>
                    <label
                      className="text-dark font-w700 w-100"
                      style={{ textAlign: "left" }}
                      htmlFor="franchiseDDE"
                    >
                      Franchise DDE
                    </label>
                  </div>

                  <input
                    name="franchiseDDE"
                    value={franchiseDDE}
                    onChange={handleChange}
                    className="pr-db-input-bg"
                    placeholder="Ex : 3000€"
                  />
                </div>

                <div className="flex-direction-col mg-r10 w-50">
                  <div className="w-100 mg-b20 d-flex flex-ver-center" style={{ textAlign: "left",  minHeight: "2.5em"  }}>
                    <label
                      className="text-dark font-w700 w-100"
                      style={{ textAlign: "left" }}
                      htmlFor="franchiseVandalisme"
                    >
                      Franchise vandalisme
                    </label>
                  </div>

                  <input
                    name="franchiseVandalisme"
                    value={franchiseVandalisme}
                    onChange={handleChange}
                    className="pr-db-input-bg"
                    placeholder="Ex : 3000€"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cursor d-flex flex-hor-end mg-r20" onClick={skipStep}>
          <p className=" font-14 text-dark-light font-w700 mg-r10">
            Passer cette étape
          </p>
          <img src={nextArrow} alt="next" />
        </div>
      </div>
      {
            <div className="d-flex flex-hor-end mg-t10">
              <button
                className="submitButton w-30"
                disabled={isButtonDisabled()}
                onClick={() => dispatch(handleNext())}
              >
                Étape suivante
              </button>
            </div>
    }
    </div>
  );
};

export default CoOwnerShipThirteen;


