import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import arrow from "assets/image/startStep/arrow.svg";
import { handleBack, handleNext, selectDossierChoices } from "features/dossierSimuSlice";

type Props = {
  handleOnClick: (
    fieldName: string,
    value: boolean | string | any
  ) => void;
};

const CoOwnerShipNine: React.FC<Props> = ({  handleOnClick }) => {
  const mri = useAppSelector(selectDossierChoices);
  const dispatch = useAppDispatch();
  const [surface, setSurface] = useState(
    mri && mri.commercialParking ? mri.commercialParking : ""
  );

  const handleChange = (value: string) => {
    setSurface(value);
    setSurface((prevSurface: string) => {
      handleOnClick("commercialParking", prevSurface);
      return value;
    });
  };

  useEffect(() => {
    if (mri && mri?.firstElements) {
      const isCommerces = mri?.firstElements.find((el: any) => el.label === "Commerces").value
      if (!isCommerces) {
        handleOnClick("commercialParking", null);
        dispatch(handleNext())
      }
    }
  }, [mri])

  return (
    <div className="max-w1300 mg-0-auto ccc-body-step-2">
      <div className="w-100 text-center border-box radius-15 cccbs2r1">
        <div className="mg-b50">
          <div className="mg-b20 mg-t20">
            <p className="font-18 lh-22 text-dark cccbs2r1r1">
              Quelle est la{" "}
              <span className="font-w700">surface du parking commercial ?</span>{" "}
            </p>
          </div>
        </div>
        <div
          className="d-flex flex-hor-center mg-30 flex-direction-col"
          style={{ margin: 30 }}
        >
          <div className="block column" style={{ padding: "16px 10vw" }}>
            <input
              value={surface}
              onChange={(e) => handleChange(e.target.value)}
              className="pr-db-input-bg"
              placeholder="Ex : 3000 m²"
            />
          </div>
          {
            parseInt(mri?.surface) < parseInt(surface) && 
            <p className="error-message " style={{ padding: "0px 10vw" }}>
              La surface du parking doit être inférieur à la surface totale de la copropriété
            </p>
          }
        </div>
      </div>
      {
            <div className="d-flex flex-hor-end mg-t10">
              <button
                className="submitButton w-30"
                disabled={!surface ||  parseInt(mri?.surface) < parseInt(surface)}
                onClick={() => dispatch(handleNext())}
              >
                Étape suivante
              </button>
            </div>
        }
    </div>
  );
};

export default CoOwnerShipNine;
