import { step } from "types/main";

export const cbnoSteps: step[] = [
    {stepName: "Mon bien", intermediateStep: 11},
    {stepName: "Garanties", intermediateStep: 1},
    {stepName: "Dossier", intermediateStep: 1},
    {stepName: "Confirmation", intermediateStep: 1}
]

export const gliSteps: step[] = [
    {stepName: "Mon bien", intermediateStep: 2},
    {stepName: "Garanties", intermediateStep: 1},
    {stepName: "Dossier", intermediateStep: 1},
    {stepName: "Confirmation", intermediateStep: 1}
]

export const mriSteps: step[] = [
    {stepName: "Ma copropriete", intermediateStep: 14},
    {stepName: "Garanties", intermediateStep: 1},
    {stepName: "Dossier", intermediateStep: 1},
    {stepName: "Confirmation", intermediateStep: 1}
]

export const emprunteurSteps: step[] = [
    {stepName: "Le projet", intermediateStep: 2},
    {stepName: "Les prets", intermediateStep: 1},
    {stepName: "Garanties", intermediateStep: 1},
    {stepName: "Quotité", intermediateStep: 1},
    {stepName: "Dossier", intermediateStep: 1}
]