
export function parsePretToDto(pret: any) {
    let dataPret: any = {
        naturePretId: pret?.naturePret, 
        montant: pret?.montantPret,
        montantAssuranceActuelle: pret?.montantAssurance,
        tauxFixe: pret?.natureTaux === "Fixe",
        taux: pret?.pourcentageTaux,
        duree: 0,
        differe: pret?.differe,
        loyer: pret?.montantLoyer,
        valeurResiduelle: pret?.valeurResiduelle,
        remboursementInteret: pret?.remboursement === "Oui",
        dureeTotaleInitiale: parseInt(pret?.dureeTotale) || 0,
        paliers: pret?.palier
    }
    if (pret?.id) {
        dataPret = {...dataPret, id:pret?.id}
    }
    return dataPret
}

export function parseAssureEmpPersToDto(assure: any, id?:string, adresseId?: string) {

    //Il faudra gerer quand l'assure n'est pas emprunteur en cherchant une personne morale existante ou bien la creer si elle n'existe pas

    let personnePhysique: any = {
        civiliteId: assure?.information?.civilite,
        dateNaissance: assure?.information?.dateNaissance,
        nom: assure?.information?.nom, 
        prenom: assure?.information?.prenom,
        adresse: {
            ligne1: assure?.information?.address,
            ligne2: assure?.information?.additionalAddress,
            codePostal: assure?.information?.codePostal,
            ville: assure?.information?.city,
            pays: "France",
            adresseFormattee: assure?.information?.adresseFormattee,
            latitude: assure?.information?.latitude?.toString(),
            longitude: assure?.information?.longitude?.toString(),
            googlePlaceId: assure?.information?.googlePlaceId,
            id: adresseId ? adresseId : undefined
        },
        telephone: assure?.information?.telephone,
        email: assure?.information?.email
    }

    if (id) {
        personnePhysique = {...personnePhysique, personnePhysiqueId: id};
    }
    return personnePhysique
}

export function parseAssureEmpToDto(pers: any, assure: any, assureId?:string) {

    console.log("personne ->", assure);

    let dataAssure: any = {
        personnePhysiqueId: pers?.id,
        estCaution: false,
        estEmprunteur: assure?.information?.isEmprunteur === "Oui",
        travauxManuelId: assure?.situation?.travauxManuel === "" ? null : assure?.situation?.travauxManuel,
        cspId: assure?.situation?.catSocioPro === "" ? null : assure?.situation?.catSocioPro,
        fumeurId: assure?.situation?.isFumeur === "" ? null : assure?.situation?.isFumeur,
        expositionPolitiqueId: assure?.situation?.expoPol === "" ? null : assure?.situation?.expoPol,
        activiteARisqueId: assure?.situation?.sport === "" ? null : assure?.situation?.sport,
        deplacementProId: assure?.situation?.deplacementPro === "" ? null : assure?.situation?.deplacementPro,
        activiteAggravanteId: assure?.situation?.profession === "" ? null : assure?.situation?.profession
    };

    if (assureId) {
        dataAssure = {...dataAssure, id:assureId}
        dataAssure = {...dataAssure, emprunteurId:null}
    }
    return dataAssure; 
}

export function parseDtoToAssure(data: any) {
    const assure = {
        id: data?.id,
        persPhysiqueId: data?.personnePhysique?.id,
        information: {
            civilite: data?.personnePhysique?.civiliteId,
            dateNaissance: data?.personnePhysique?.dateNaissance,
            nom: data?.personnePhysique?.nom, 
            prenom: data?.personnePhysique?.prenom,
            address: data?.personnePhysique?.adresse?.ligne1,
            additionalAddress: data?.personnePhysique?.adresse?.ligne2,
            codePostal: data?.personnePhysique?.adresse?.codePostal,
            city: data?.personnePhysique?.adresse?.ville,
            adresseFormattee: data?.personnePhysique?.adresse?.ligne1,
            latitude: parseFloat(data?.personnePhysique?.adresse?.geolocalisation?.latitude) || 0,
            longitude: parseFloat(data?.personnePhysique?.adresse?.geolocalisation?.longitude) || 0,
            googlePlaceId: data?.personnePhysique?.adresse?.geolocalisation?.googlePlaceId,
            telephone: data?.personnePhysique?.telephone,
            email: data?.personnePhysique?.email,
            isEmprunteur: data?.estEmprunteur === true ? "1" : "2",
            adresseId: data?.personnePhysique?.adresse?.id
        },
        situation: {
            isFumeur: data?.fumeurId,
            expoPol: data?.expositionPolitiqueId,
            sport: data?.activiteARisqueId,
            deplacementPro: data?.deplacementProId,
            profession: data?.activiteAggravanteId,
            catSocioPro: data?.cspId,
            travauxManuel: data?.travauxManuelId,
        }
    }
    return assure;
}


export function parseDtoToPret(data: any, garanties: any, quotite: number) {

    const pret: any = {
        naturePret: data?.naturePret?.id, 
        montantPret: data?.montant,
        montantAssurance: data?.montantAssuranceActuelle,
        natureTaux: data?.tauxFixe === true ? "Fixe" : "Variable",
        taux: data?.pourcentageTaux,
        duree: 0,
        differe: data?.differe,
        montantLoyer: data?.loyer,
        valeurResiduelle: data?.valeurResiduelle,
        remboursement: data?.remboursementInteret === true ? "Oui" : "Non",
        dureeTotale: data?.dureeTotaleInitiale,
        palier: data?.paliers,
        garanties: garanties,
        id: data?.id,
        quotite: quotite
    }
    return pret
}

export function parseSimulationOnPricing(simulation:any, garanties: any) {
    const assureAvecPrets = simulation.emprunt.assuresEmprunt.map((assure: any) => {
        const prets = simulation.emprunt.assuresEmpruntPrets
            .filter((aep: any) => aep.assureEmprunt.id === assure.id)
            .map((aep: any) => {
                return parseDtoToPret(aep?.pret, garanties, aep?.quotite);
            });
    
        return {
            assureInfo: parseDtoToAssure(assure),
            prets: prets,
            selectedItems: [],
            premiereAnnee: 15,
            anneesSuivantes: 15,
            prelevement: 0
        };
    });
    return assureAvecPrets;
}