export const handleJustify = (value: string) => {
  const box = document.getElementById(value) as HTMLDivElement;
  if (box) {
    if (box?.offsetHeight < box?.scrollHeight) {
      box.style.justifyContent = "flex-start";
    } else {
      box.style.justifyContent = "center";
    }
  }
};
