import publicAPIInstance from "pages/connexion/publicProxy";
import { resolve } from "path";


// A mock function to mimic making an async request for data
export function fetchCount(amount = 1) {
  return new Promise<{ data: number }>((resolve) =>
    setTimeout(() => resolve({ data: amount }), 500)
  );
}

export function simulationInfo(data: any) {
  return new Promise((resolve, reject) => {
    try {
      const res = publicAPIInstance.post(
        `api/simulations/biens/${data?.id}/biens`,
        data?.data
      );
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export function createBien(data:any) {
  return new Promise((resolve, reject) => {
    try {
      const res = publicAPIInstance.post(
        `api/simulations/biens/biens`,
        data?.data
      );
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export function minMaxApi(id: string) {
  return new Promise((resolve, reject) => {
    try {
      const res = publicAPIInstance.get(
        `api/simulations/gli/${id}/parametres`);
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export function calculateInsurance(data: any) {
  return new Promise((resolve, reject) => {
    try {
      const res = publicAPIInstance.get(
        `api/simulations/gli/${data?.id}/estimation?MontantLoyerMensuelle=${data?.data?.montantLoyerMensuelle}&IsContratIndividuel=true`
      );
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export async function setSimulationRisqueAssure(params: { simulationId: string, risqueAssureId: string, type:string }) {
  return new Promise((resolve, reject) => {
    try {
      const res = publicAPIInstance.put(
      `/api/simulations/gli/setSimulation${params.type}RisquesAssure?simulationId=${params.simulationId}&risqueAssureId=${params.risqueAssureId}`
      );
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export async function updateCBNOSimulation(data:any):Promise<any> {
  return new Promise((resolve, reject) => {
    try {
      const res = publicAPIInstance.put(
      `/api/simulations/updateCBNO`, data
      );
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}