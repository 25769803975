import { API_SERVER } from "constants/configs";
import publicAPIInstance from "pages/connexion/publicProxy";
import { useState } from "react";

function useElevation() {
  const [elevation, setElevation] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(null);

  const fetchElevation = async (latitude: number, longitude: number) => {
    try {
      const apiUrl = `${API_SERVER}api/backoffice/georisquesgouv/elevations?latlon=${latitude},${longitude}`;
      setLoading(true)

      const res: any = await publicAPIInstance.get(apiUrl);
      const data = res?.data;

      if (data.status === "OK") {
        setElevation(data.results[0].elevation);
        setError(null)
      } else {
        console.error("Error fetching elevation from Google Maps API");
      }
    } catch (error) {
      console.error("Error fetching elevation:", error);
    } finally {
        setLoading(false)
    }
  }

  return { elevation, fetchElevation, loading, error };
}

export default useElevation;
