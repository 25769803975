import { store } from "app/store";
import axios, { AxiosInstance } from "axios";
import { API_SERVER } from "constants/configs";

const publicAPIInstance: AxiosInstance = axios.create({
    baseURL: API_SERVER,
});

publicAPIInstance.interceptors.request.use(
    (config) => {
        const accessToken = store.getState().mainSlice.accessToken;
        if (accessToken && config.headers != null) {
        config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default publicAPIInstance;