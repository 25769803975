import { useEffect, useState } from "react";

export function useIsSmallScreen(customScreenWidth: number) {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < customScreenWidth);
  
    useEffect(() => {
      const handleWindowResize = () => {
        setIsSmallScreen(window.innerWidth < customScreenWidth);
      };
  
      window.addEventListener('resize', handleWindowResize);
  
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, [customScreenWidth]);
  
    return isSmallScreen;
  }