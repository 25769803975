import { useState, useEffect, useMemo } from "react";
import { PageTransition } from "@steveeeie/react-page-transition";

import Register from "features/connexion/register";
import Login from "features/connexion/login";
import ConnexionForm from "features/connexion/connexionForm";

import classNames from "classnames";
import { CONNEXION_LABEL } from "pages/mainStep/routeList";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteList } from "pages/mainStep/type";
import { useSelector } from "react-redux";
import { CONNEXION_LOGIN } from "pages/mainStep/routeList";
import { handleNextStep } from "helpers/handleNextStep";

const Connexion = ({ setShowHeader }: any) => {
  const [showRegister, setShowRegister] = useState(false);
  const [key, setKey] = useState("");
  const [oldKey, setOldKey] = useState(0);
  const location = useLocation();

  const [direction, setDirection] = useState("moveToLeftFromRight");

  const navigate = useNavigate();

  const { routeList, auth } = useSelector((state: any) => state.mainSlice);
  const routeListConnexion = useMemo(() => {
    const targetFilter: RouteList[] | any = routeList?.filter(
      (x: RouteList) => x?.label === CONNEXION_LABEL
    );

    const target = targetFilter?.find((x: any) =>
      x?.list?.includes(location.pathname)
    );
    return target?.list?.map((x: string, index: number) => ({
      key: index + 1,
      link: x,
    }));
  }, [routeList]);

  useEffect(() => {
    const target: { key: number; link: string } | undefined =
      routeListConnexion?.find((x: any) => x?.link === location?.pathname);
    if (target) {
      const newDirection =
        oldKey < target?.key ? "moveToLeftFromRight" : "moveToRightFromLeft";
      setOldKey(target?.key);
      setDirection(newDirection);
      setTimeout(() => {
        setKey(`${target?.key}`);
      });
    }
  }, [location?.pathname]);

  return (
    <div id="connexion">
      {key && (
        <PageTransition
          preset={direction}
          transitionKey={key}
          enterAnimation={""}
          exitAnimation={""}
        >
          {(() => {
            switch (key) {
              case "1":
                return <ConnexionForm />;
              default:
                return <Login setShowRegister={setShowRegister}></Login>;
            }
          })()}
        </PageTransition>
      )}
      <div
        className={classNames("registerContainer", { active: showRegister })}
      >
        <Register
          setShowRegister={setShowRegister}
          showRegister={showRegister}
        ></Register>
      </div>
    </div>
  );
};

export default Connexion;
