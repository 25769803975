import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import arrow from "assets/image/startStep/arrow.svg";
import { handleNext, selectDossierChoices } from "features/dossierSimuSlice";
import RadioButtonChoice from "features/components/radioButton/radioButton";

type Props = {
  handleOnClick: (
    fieldName: string,
    value: boolean | string | any
  ) => void;
  isSide?: boolean
};

const Eligibility: React.FC<Props> = ({ handleOnClick, isSide = false }) => {
  const mri = useAppSelector(selectDossierChoices);
  const dispatch = useAppDispatch();

  const [answers, setAnswers] = useState(
    mri && mri.eligibility
      ? mri.eligibility
      : [
        {
          fieldName: "urbanArea",
          title: "La copropriété est-elle située en zone urbaine sensible ?",
          options: ["Oui", "Non"],
          selectedOption: "",
        },
        {
          fieldName: "industrialActivity",
          title:
            "Y'a-t-il une activité industrielle à l'intérieur ou attenante à la copropriété ?",
          options: ["Oui", "Non"],
          selectedOption: "",
        },
        {
          fieldName: "religiousActivity",
          title:
            "La copropriété abrite-t-elle des activités à caractère religieux ou politique (consulat, ambassade, bâtiments syndicaux, permanence politique, activités à caractère religieux) ?",
          options: ["Oui", "Non"],
          selectedOption: "",
        },

        {
          fieldName: "nonHardBuildingsOver75Percent",

          title:
            "Y'a-t-il des bâtiments qui ne sont pas construits en dur sur plus de 75% ?",
          options: ["Oui", "Non"],
          selectedOption: "",
        },
        {
          fieldName: "nearbyEntertainmentVenues",
          title:
            "Y'a-t-il à l'intérieur ou à moins de 50m de la copropriété une des activités suivantes: dancing, cabaret, bar à ouverture tardive, bar à ambiance, casino, bowling ?",
          options: ["Oui", "Non"],
          selectedOption: "",
        },
      ]
  );

  useEffect(() => {
    if (mri && mri?.address && mri?.address?.altitude > 1500) {
      const alreadyExist = mri?.eligibility.find((el: any) => el.fieldName === 'altitudeRescueNearby')

      if (!alreadyExist) {
        setAnswers((prevAnswers: any) => [
          ...prevAnswers,
          {
            fieldName: "altitudeRescueNearby",

            title:
              "Votre copropriété est-située à plus de 1500m d'altitude. Y'a-t-il un centre de secours à moins de 3 km ?",
            options: ["Oui", "Non"],
            selectedOption: "",
          },
        ]);
      }
    }
  }, [mri?.address])

  const removeCommercialQuestions = () => {
    const updatedAnswers = answers.filter(
      (question: any) =>
        question.fieldName !== "commercialAreaExceeds25Percent" &&
        question.fieldName !== "commercialActivity"
    );
    setAnswers(updatedAnswers);
    handleOnClick("eligibility", updatedAnswers);
  };

  useEffect(() => {
    if (mri && mri?.firstElements) {
      const isCommerces = mri?.firstElements?.find((el: any) => el.label === "Commerces").value
      const alreadyExists = (mri?.eligibility?.find((el: any) => el.fieldName === 'commercialAreaExceeds25Percent') && mri?.eligibility?.find((el: any) => el.fieldName === 'commercialActivity')) || false
      if (isCommerces && !alreadyExists) {
        setAnswers((prevAnswers: any) => [
          ...prevAnswers,
          {
            fieldName: "commercialAreaExceeds25Percent",
            title:
              "La zone d'activité commerciale est-elle supérieure à 25% de la superfice de la copropriété ?",
            options: ["Oui", "Non"],
            selectedOption: "",
          },
          {
            fieldName: "commercialActivity",

            title:
              "Les commerçants exercent-ils une de ces activités: Boulangerie, pizzeria, pharmacie... ?",
            options: ["Oui", "Non"],
            selectedOption: "",
          },

        ])
      }
      if (!isCommerces && alreadyExists) {
        removeCommercialQuestions()
      }
    }
  }, [mri?.firstElements])

  const handleAnswer = (selectedOption: string, answerIndex: number) => {
    const payload = {
      ...answers[answerIndex],
      selectedOption: selectedOption,
    };

    const newAnswers = [...answers];

    newAnswers[answerIndex] = payload;

    setAnswers([...newAnswers]);

    handleOnClick("eligibility", newAnswers);
  };

  const renderQuestion = (question: any, answerIndex: number) => {
    return (
      <div key={question.fieldName} className={`${!isSide ? "" : "mg-b20"} question-mri mg-b20`}>
        <p
          className={` lh-22  cccbs2r1r1 mg-b10 ${!isSide ? "text-dark font-18" : "text-dark-light font-14"}`}
          style={{ textAlign: "left" }}
        >
          {question.title}
        </p>
        <div className={`d-flex ${!isSide ? "flex-hor-center cccbs2r1r2" : "w-100"} cccbs2r1r2 question-mri`}>
          {question.options.map((option: string, index: number) => {
            return (
              <RadioButtonChoice
                key={index + Math.random()}
                large
                radio
                text={option}
                selected={question.selectedOption == option}
                handleOnClick={() => handleAnswer(option, answerIndex)}
                last={option === question.options[question.options.length - 1]}
                isSide={isSide === true ? true : false}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const areAllQuestionsAnswered = () => {
    return answers.every((question: any) => question.selectedOption !== "");
  };

  return (
    <div className={`${!isSide ? "max-w1300 mg-0-auto ccc-body-step-2" : ""} h-90`}>
      <div className=" text-center border-box radius-15 cccbs2r1 overflow-mri-form">
        <div className={`${!isSide ? "mg-b50" : ""}`}>
          <div className="d-flex flex-center flex-direction-col">
            <p className="font-24 text-dark font-w700 mg-b20"> Environnement</p>
            <div className={`block column ${!isSide ? "w-70" : "w-100"}`}>
              {answers.map((question: any, index: number) =>
                renderQuestion(question, index)
              )}
            </div>
          </div>
        </div>
      </div>
      {
            <div className="d-flex flex-hor-end mg-t10">
              <button
                className="submitButton w-30"
                disabled={!areAllQuestionsAnswered()}
                onClick={() => dispatch(handleNext())}
              >
                Étape suivante
              </button>
            </div>
        }
    </div>
  );
};

export default Eligibility;
