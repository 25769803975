import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { handleBack, handleNext, selectDossierChoices } from "features/dossierSimuSlice";
import RadioButtonChoice from "features/components/radioButton/radioButton";

type Props = {
  handleOnClick: (
    fieldName: string,
    value: boolean | string | any
  ) => void;
  isSide?: boolean;
};

const CoOwnerShipOne: React.FC<Props> = ({ handleOnClick, isSide = false }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const mri = useAppSelector(selectDossierChoices);
  const [selectedValue, setSelectedValue] = useState<string | null>(
    mri && mri?.constructionDate ? mri?.constructionDate : ""
  );

  const handleAnswer = (
    part: number,
    fieldName: string,
    value: boolean | string | any
  ) => {
    setSelectedValue(value);
    setSelectedValue((prevValue) => {
      handleOnClick(fieldName, prevValue);
      return value;
    });
  };

  return (
    <div className={`${!isSide ? " ccc-body-step-2" : ""} w-60`}>
      <div className="w-100 text-center border-box radius-15 cccbs2r1">
      {
        <div className="mg-b50">
              <>
                <div>
                  <p className="font-18 lh-22 text-dark cccbs2r1r1">
                    Quand la copropriété{" "}
                    <span className="font-w700">a-t-elle été construite ?</span>
                  </p>
                </div>
              </>
          
        </div>
        }
        <div className={`d-flex ${!isSide ? "flex-hor-center cccbs2r1r2" : "w-100"}`}>
          <RadioButtonChoice
            radio
            text="Moins de 10 ans"
            selected={selectedValue === "Moins de 10 ans"}
            handleOnClick={() =>
              handleAnswer(2, "constructionDate", "Moins de 10 ans")
            }
            last={false}
            isSide={isSide === true ? true : false}
          />
          <RadioButtonChoice
            radio
            text="Plus de 10 ans"
            selected={selectedValue === "Plus de 10 ans"}
            handleOnClick={() =>
              handleAnswer(2, "constructionDate", "Plus de 10 ans")
            }
            last={true}
            isSide={isSide === true ? true : false}
          />
        </div>
      </div>
      {
          <div className="d-flex flex-hor-end mg-t10">
          <button
            className="submitButton w-30"
            disabled={!selectedValue}
            onClick={() => dispatch(handleNext())}
          >
            Étape suivante
          </button>
        </div>
      }
    </div>
  );
};

export default CoOwnerShipOne;
