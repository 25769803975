import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { CreateAssureCoproDto, CreateCoproDto } from './assureTypes'
import { createAssuresCopro, createNewCopro } from './assureAPI'


const initialState: any = {
  assureCoproprieteId: undefined,
  coproprieteId: undefined,
  empruntId: undefined,
  assuresEmprunt: [],
}

export const createAssuresCoproAction = createAsyncThunk(
  'assures/createAssuresCopro',
  async (createAssureCoproDto: CreateAssureCoproDto) => {
    const response = await createAssuresCopro(createAssureCoproDto)
    return response
  }
)

export const createNewCoproAction = createAsyncThunk(
  'assures/createNewCoproAction',
  async (createCoproDto: CreateCoproDto) => {
    const response = await createNewCopro(createCoproDto)
    return response
  }
)

export const assuresSlice = createSlice({
  name: 'assures',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addAssureEmprunt: (state, action) => {
      state.assuresEmprunt = [...state.assuresEmprunt, action.payload];
    },
    setEmpruntId: (state, action) => {
      state.empruntId = action.payload;
    },
    setCoproId: (state, action) => {
      state.coproprieteId = action.payload;
    },
    setAssureCoproId: (state, action) => {
      state.assureCoproprieteId = action.payload;
    },
    setAssuresEmprunt: (state, action) => {
      state.assuresEmprunt = action.payload
    },
    resetAssureStore(state) {
      state = initialState;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: builder => {
    builder
      .addCase(createAssuresCoproAction.pending, state => {
        state.loading = true;
      })
      .addCase(createAssuresCoproAction.fulfilled, (state, action) => {
        state.loading = false;
        state.assureCoproprieteId = action.payload.id;
      })
      .addCase(createAssuresCoproAction.rejected, state => {
        state.loading = false;
      })
      .addCase(createNewCoproAction.pending, state => {
        state.loading = true;
      })
      .addCase(createNewCoproAction.fulfilled, (state, action) => {
        state.loading = false;
        state.coproprieteId = action.payload.id;
        state.assureCoproprieteId = action.payload?.assureCopropriete?.id;
      })
      .addCase(createNewCoproAction.rejected, state => {
        state.loading = false;
      });
  },
})

export const authActions = assuresSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
export const { addAssureEmprunt, setAssuresEmprunt, setEmpruntId, setCoproId, setAssureCoproId, resetAssureStore } = authActions
export const selectAssuresCopro = (state: RootState) => state.assuresSlice.assureCoproprieteId
export const selectNewCopro = (state: RootState) => state.assuresSlice.coproprieteId
export const selectAssuresEmprunts = (state: RootState) => state.assuresSlice.assuresEmprunt
export const selectEmpruntId = (state: RootState) => state.assuresSlice.empruntId 

export default assuresSlice.reducer
