import { useAppDispatch, useAppSelector } from "app/hooks";
import { resetDossierSimu, resetDossierStore, selectDossierStepActive, selectDossierType} from "features/dossierSimuSlice";
import { useEffect } from "react";
import { beginSimulationAction, resetMainStore } from "pages/mainStep/mainSlice";
import StepOne from "features/MRI/stepOne";
import StepTwo from "features/components/choiceGarantie/stepTwo";
import Information from "features/commonSteps/information";
import SimulationTerminee from "features/commonSteps/simulationTerminee";
import { resetAssureStore } from "features/assure/assureSlice";
import { resetConfirmationStore } from "features/confirmation/confirmationSlice";
import { resetContratStore } from "features/contrat/contratSlice";
import { resetEmprunteurStore } from "features/Emprunteur/emprunteurSlice";
import { resetLocataireSlice } from "features/locataires/locatairesSlice";
import { resetPiecesStore } from "features/pieces/piecesSlice";


const MRISimu: React.FC = () => {

    const activeStep = useAppSelector(selectDossierStepActive);
    const dispatch = useAppDispatch();
    const { courtierId, simulationId } = useAppSelector(
        (state) => state.mainSlice
      ); 
    const dossierType = useAppSelector(selectDossierType);

    //Ce useEffet permet de reset le store si jamais l'ancienne simulation n'etait pas une simulation MRI
    useEffect(() => {
      if (dossierType != "mri")
      {
        dispatch(resetDossierSimu());
        dispatch(resetMainStore());
        dispatch(resetDossierStore());
        dispatch(resetAssureStore());
        dispatch(resetConfirmationStore());
        dispatch(resetContratStore());
        dispatch(resetEmprunteurStore());
        dispatch(resetLocataireSlice());
        dispatch(resetPiecesStore());
        localStorage.clear();
        const natureCode = "mri";
        if (courtierId)
            dispatch(beginSimulationAction({natureCode, courtierId}));
      }
    }, [courtierId])

    return (
        <>
        {(() => {
            switch (activeStep) {
                case 0:
                    return <StepOne></StepOne>
                case 1:
                    return (
                      <div className="max-w1300 mg-0-auto ccc-body-step-2 h-90 d-flex flex-hor-center flex-ver-center">
                        <div className="w-100 text-center border-box radius-15 cccbs2r1 overflow-mri-garantie">
                          <StepTwo/>
                        </div>
                      </div>
                    )
                case 2:
                  return (
                    <div className="max-w1300 mg-0-auto ccc-body-step-2 h-90 d-flex flex-hor-center flex-ver-center">
                        <div className="w-100 text-center border-box radius-15 cccbs2r1 overflow-mri-garantie">
                          <Information type="MRI"/>
                        </div>
                    </div>
                  )
                case 3:
                  return (
                    <div className="max-w1300 mg-0-auto ccc-body-step-2 h-90 d-flex flex-hor-center flex-ver-center">
                        <div className="w-100 text-center border-box radius-15 cccbs2r1 overflow-mri-garantie">
                            <SimulationTerminee />
                        </div>
                    </div>
                  )
            }
        })()}
    </>
    );

}

export default MRISimu