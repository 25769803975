import { useEffect, useMemo } from "react";
import clock from "assets/image/stepTwo/clock.svg";
import { handleJustify } from "helpers/handleJustify";
import { useNavigate } from "react-router-dom";
import { handleNextStep } from "helpers/handleNextStep";
import { fetchDocumentsAction } from "../piecesSlice";
import LocataireDetail from "../locataireDetail";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { Spin } from "antd";
import { Document, LocataireDoc } from "pages/mainStep/type";

const PiecesStepOne = ({ onShowEdit, showEdit }: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { routeList, simulationId, dossierLocationId } = useAppSelector(
    (state) => state.mainSlice
  );
  const { listLocataires, loading } = useAppSelector(
    (state) => state.piecesSlice
  );

  useEffect(() => {
    if (!showEdit) {
      const data = {
        simulationId,
        params: {
          simulationId,
          dossierLocationId,
        },
      };

      dispatch(fetchDocumentsAction(data));
    }
  }, [simulationId, dossierLocationId, showEdit]);

  const handleSubmit = () => {
    navigate(handleNextStep(routeList));
  };

  useEffect(() => {
    handleJustify("piecesStepOne");
  }, [window.innerHeight]);

  useEffect(() => {
    window.addEventListener("resize", () => handleJustify("piecesStepOne"));

    return () =>
      window.removeEventListener("resize", () =>
        handleJustify("piecesStepOne")
      );
  }, []);

  const disabled = useMemo(() => {
    const unfinishList = listLocataires?.filter((x: LocataireDoc) => {
      const requiredList = x?.documents?.filter((y: Document) => y?.isRequired);
      const doneUpload = x?.documents?.filter(
        (y: Document) => y?.documentStatus === y?.nombreDeDocuments
      );

      if (requiredList?.length !== doneUpload?.length) {
        return false;
      } else {
        return true;
      }
    });
    if (listLocataires?.length !== unfinishList?.length) {
      return true;
    } else {
      return false;
    }
  }, listLocataires);

  return (
    <div id="piecesStepOne">
      <h2 className="font-24 lh-32 mg-b25 psor1">Pièces justificatives</h2>
      <p className="font-16 lh-25 mg-b37 psor2">
        Complétez le dossier avec les pièces administratives requises pour
        établir votre contrat
      </p>
      {loading ? (
        <Spin size="large" className="mg-b20"></Spin>
      ) : (
        listLocataires?.map((x: LocataireDoc, index: number) => (
          <LocataireDetail data={x} key={index} onShowEdit={onShowEdit} />
        ))
      )}

      <div className="w-100 d-flex flex-direction-col flex-ver-center psor3">
        <button
          className="submitButton"
          onClick={handleSubmit}
          disabled={loading || disabled}
        >
          Suivant
        </button>
        <div className="w-100 d-flex cursor flex-ver-center flex-hor-center footerButton">
          <img src={clock} alt="loading" className="mg-r5 pad-10"></img>
          <p className="font-14 lh17">Terminer plus tard</p>
        </div>
      </div>
    </div>
  );
};

export default PiecesStepOne;
