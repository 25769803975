import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import PlusIcon from "assets/image/common/plus-circle.svg";
import TrashIcon from "assets/image/common/trash-dark-thin.svg";
import BuildingForm from "./buildingForm";
import { Building, BuildingState, Field } from "./types";
import { handleNext, selectDossierChoices } from "features/dossierSimuSlice";
import Tabs from "features/components/tabs/tabs";

type Props = {
  handleOnClick: (
    fieldName: string,
    value: boolean | string | any
  ) => void;
  setBuilding: (number: any) => void;
  handleAnswer: (
    buildingId: number,
    fieldName: string,
    selectedOption: string
  ) => void;
  buildings: BuildingState;
  isSide: boolean
};

const generateBuildingTabs = (numBuildings: number) => {
  const tabs = [];

  for (let i = 1; i <= numBuildings; i++) {
    tabs.push({
      label: `Bâtiment ${i}`,
      value: i,
      haveNumbers: false,
    });
  }

  return tabs;
};

const BuildingTabs: React.FC<Props> = ({
  handleOnClick,
  setBuilding,
  handleAnswer,
  buildings,
  isSide
}) => {
  const mri = useAppSelector(selectDossierChoices);
  const numberBuildings = mri?.numberBuildings || 1;
  const buildingTabs = generateBuildingTabs(numberBuildings || 1);
  const [activeBuilding, setActiveBuilding] = useState<number>(1);
  const MAX_BUILDINGS = 4;
  const dispatch = useAppDispatch();

  const removeBuilding = () => {
    handleOnClick("numberBuildings", numberBuildings - 1);
    setBuilding(numberBuildings - 1);
    setActiveBuilding(numberBuildings - 1);
  };

  const addBuilding = () => {
    handleOnClick("numberBuildings", numberBuildings + 1);
    setBuilding(numberBuildings + 1);

    setTimeout(() => {
      setActiveBuilding(numberBuildings + 1);
    }, 50);
  };

  const areAllFieldsFilled = () => {
    return buildings.buildings.every((building: Building) =>
      building.fields.every(
        (field: Field) =>
          field.selectedOption !== "" &&
          building.floors !== "" &&
          building.basements !== ""
      )
    );
  };

  return (
    <div className={`max-w1300 mg-0-auto ccc-body-step-2 ${isSide ? "mg-t20" : ""} h-90 d-flex flex-direction-col flex-hor-center`}>
      <div className="w-100 text-center border-box radius-15 cccbs2r1 overflow-mri-form">
        <div className={`${!isSide ? "mg-b20" : ""}`}>
          <div className="d-flex">
            <div className="mg-b20 mg-t20 w-100">
              <p
                className="font-18 lh-22 text-dark font-w700 cccbs2r1r1"
                style={{ marginRight: 70 }}
              >
                Les bâtiments de la copropriété
              </p>
            </div>
          </div>
          <div>
            {
              !isSide ? (
                <div className="mg-xl">
                  <Tabs
                    tabs={buildingTabs}
                    active={activeBuilding}
                    setActive={(value: any) => setActiveBuilding(value)}
                  >
                    {numberBuildings < MAX_BUILDINGS ? (
                      <div
                        className="d-flex flex-hor-between center align-center cursor"
                        onClick={addBuilding}
                      >
                        <img
                          style={{ width: 25, marginRight: 10 }}
                          src={PlusIcon}
                          alt="ajout"
                        />
                        <p className="font-14 text-gray-blue">
                          Ajouter
                        </p>
                      </div>
                    ) : null}
                  </Tabs>
                </div>
              ) : null
            }
          </div>
        </div>
       
        <div className={`cccbs2r1r2  ${!isSide ? "mg-xl" : ""}`}>
        
          {
            !isSide ? (
              <div
                style={
                  !(numberBuildings > 1 && activeBuilding !== 1)
                    ? { marginTop: isSide ? 17 : 0 }
                    : {}
                }
              >
                <div>
                  {buildingTabs.map((tab, index: number) => (
                    <div key={Math.random() + tab.value}>
                      {index + 1 === activeBuilding && (
                        <BuildingForm
                          handleOnClick={handleOnClick}
                          buildingIndex={index}
                          handleAnswer={handleAnswer}
                          isSide={isSide}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>

            ) : (
              <div
                style={
                  !(numberBuildings > 1 && activeBuilding !== 1)
                    ? { marginTop: isSide ? 17 : 0 }
                    : {}
                }
              >
                {numberBuildings < MAX_BUILDINGS ? (
                  <div
                    className="d-flex align-center cursor"
                    onClick={addBuilding}
                  >
                    <img
                      style={{ width: 25, marginRight: 10 }}
                      src={PlusIcon}
                      alt="ajout"
                    />
                    <p className="font-14 text-gray-blue">
                      Ajouter un bâtiment
                    </p>
                  </div>
                ) : null}
                <div>
                  {buildingTabs.map((tab, index: number) => (
                    <div className="block column" key={Math.random() + tab.value}>
                      <p
                        className="font-16 lh-22 text-dark font-w700 cccbs2r1r1 mg-b20"
                        style={{ marginRight: 70, textAlign: 'left' }}
                      >
                        {`Bâtiment ${index + 1}`}
                      </p>
                      {
                        <BuildingForm
                          handleOnClick={handleOnClick}
                          buildingIndex={index}
                          handleAnswer={handleAnswer}
                          isSide={isSide}
                        />
                      }
                    </div>
                  ))}
                </div>
              </div>
            )
          }
           <div className="d-flex flex-hor-center mg-t20 mg-b20">
            {numberBuildings > 1 && activeBuilding !== 1 ? (
              <div
                className="d-flex flex-hor-between center align-center cursor"
                onClick={removeBuilding}
              >
                <img
                  style={{ width: 15, marginRight: 10 }}
                  src={TrashIcon}
                  alt="suppresion batiment"
                />
                <p className="font-14 text-gray-blue">Supprimer un bâtiment</p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {
            <div className="d-flex flex-hor-end mg-t10">
              <button
                className="submitButton w-30"
                disabled={!areAllFieldsFilled() || !numberBuildings}
                onClick={() => dispatch(handleNext())}
              >
                Étape suivante
              </button>
            </div>
        }
    </div>
  );
};

export default BuildingTabs;
