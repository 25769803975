export const handleNextStep = (routeList: any) => {
  const link = window.location.pathname;
  const current = routeList?.find((x: any) => x?.list?.includes(link));
  const routeListKey: any = routeList?.map((x: any) => x?.key);
  const indexTarget = routeListKey?.indexOf(current?.key);
  if (current) {
    if (current?.list) {
      const indexOfRoute = current?.list?.indexOf(link);
      if (current?.list?.[indexOfRoute + 1]) {
        return current?.list?.[indexOfRoute + 1];
      } else {
        if (routeList?.[indexTarget + 1]) {
          const nextTarget: any = routeList?.[indexTarget + 1];
          return nextTarget?.list?.[0];
        }
      }
    }
  }
};
